import { Dispatch, SetStateAction, useState } from "react";
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select, Autocomplete } from "@mui/material";
import { formatPhoneNumber, capitalizeFirstLetterEachWord } from "../../utils/function";
import { ratings, sources, ethnicities, relationshipTypes } from "../../utils/constant";
import { IUser } from "../../types/user";
import { IRegistrant, IRegistrantStatus, IRelationship } from "../../types/registrant";
import { useSelector } from "react-redux";
import { selectSales, selectOnline } from "../../features/projectSetting/projectSettingSlice";

const RegistrantInfo = (props: ChildProps) => {
  const [random, setRandom] = useState<number>(Math.floor(Math.random() * 1000000 + 1));
  const {
    email,
    setEmail,
    submitted,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    rating,
    setRating,
    ethnicity,
    setEthnicity,
    postalCode,
    setPostalCode,
    source,
    setSource,
    salesRep,
    onlineSalesRep,
    handleSalesRep,
    workingWithRealtor,
    setWorkingWithRealtor,
    realtorName,
    setRealtorName,
    realtorEmail,
    setRealtorEmail,
    brokerage,
    setBrokerage,
    tags,
    setTags,
    projectTags,
    users,
    notes,
    setNotes,
    relationships,
    handleRelationship,
    registrants,
    loading,
    phoneRequired,
  } = props;

  const projectSales = useSelector(selectSales);
  const projectOnline = useSelector(selectOnline);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          required={relationships ? false : true}
          fullWidth
          label="Email"
          variant="outlined"
          type="email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={submitted}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required={!lastName}
          fullWidth
          label="First Name"
          variant="outlined"
          name="fname"
          autoComplete="given-name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          disabled={submitted}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required={!firstName}
          fullWidth
          label="Last Name"
          variant="outlined"
          name="lname"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          disabled={submitted}
        />
      </Grid>
      {relationships && relationships.length && registrants
        ? relationships.map((relationship: IRelationship, index: number) => {
            return (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth disabled={submitted}>
                    <InputLabel>Relationship Type</InputLabel>
                    <Select label="Relationship Type" value={relationship.type} onChange={(e) => handleRelationship(e, index)} name="type">
                      {relationshipTypes.map((relationshipType: string, index: number) => {
                        return (
                          <MenuItem key={index} value={relationshipType}>
                            {capitalizeFirstLetterEachWord(relationshipType)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    key={random}
                    id={"search"}
                    disableClearable
                    noOptionsText={loading ? `Loading...` : "No Options"}
                    freeSolo={false}
                    options={registrants && registrants.length ? registrants : []}
                    getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
                    onChange={(e, value) => handleRelationship(e, index, value, "select")}
                    value={relationship.registrant!}
                    onInputChange={(e, value, reason) => {
                      handleRelationship(e, index, value, "search");
                    }}
                    renderInput={(params) => <TextField required={false} {...params} size="medium" label={"Search By Name or Email"} />}
                  />
                </Grid>
              </>
            );
          })
        : null}
      <Grid item xs={12}>
        <TextField
          required={phoneRequired ? true : false}
          fullWidth
          label="Phone Number"
          variant="outlined"
          name="tel"
          type="tel"
          autoComplete="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))}
          disabled={submitted}
        />
      </Grid>
      {setPostalCode ? (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Postal Code"
            variant="outlined"
            name="fname"
            autoComplete="given-name"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            disabled={submitted}
          />
        </Grid>
      ) : null}
      {setEthnicity ? (
        <Grid item xs={12}>
          <FormControl required fullWidth disabled={submitted}>
            <InputLabel>{"Suspected Ethnicity"}</InputLabel>
            <Select label={"Suspected Ethnicity"} value={ethnicity} onChange={(e) => setEthnicity(e.target.value)} required>
              {ethnicities.map((ethnicity: string, index: number) => {
                return (
                  <MenuItem key={index} value={ethnicity}>
                    {ethnicity}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      {rating && setRating ? (
        <Grid item xs={12}>
          <FormControl required fullWidth disabled={submitted}>
            <InputLabel>{"Rating"}</InputLabel>
            <Select label={"Rating"} value={rating} onChange={(e) => setRating(e.target.value)} required>
              {ratings.map((rating: string, index: number) => {
                return (
                  <MenuItem key={index} value={rating}>
                    {rating}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      {source && setSource ? (
        <Grid item xs={12}>
          <FormControl required fullWidth disabled={submitted}>
            <InputLabel>{"Source"}</InputLabel>
            <Select label={"Source"} value={source} onChange={(e) => setSource(e.target.value)} required>
              {sources.map((source: string, index: number) => {
                return (
                  <MenuItem key={index} value={source}>
                    {capitalizeFirstLetterEachWord(source)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      {handleSalesRep && users ? (
        <Grid item xs={12}>
          <FormControl fullWidth disabled={submitted}>
            <InputLabel>{"Sales"}</InputLabel>
            <Select label={"Sales"} value={salesRep?._id} onChange={(e) => handleSalesRep(e.target.value, "sales")}>
              {projectSales.map((user: IUser, index: number) => {
                return (
                  <MenuItem key={index} value={user._id}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                );
              })}
              <MenuItem value={''}>
                None
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      {handleSalesRep && users ? (
        <Grid item xs={12}>
          <FormControl fullWidth disabled={submitted}>
            <InputLabel>{"Online"}</InputLabel>
            <Select label={"Online"} value={onlineSalesRep?._id} onChange={(e) => handleSalesRep(e.target.value, "online")}>
              {projectOnline.map((user: IUser, index: number) => {
                return (
                  <MenuItem key={index} value={user._id}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                );
              })}
              <MenuItem value={''}>
                None
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      {tags && setTags && projectTags?.length ? (
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={projectTags}
            freeSolo={false}
            getOptionLabel={(option: any) => option}
            isOptionEqualToValue={(option, value) => option === value}
            value={tags}
            onChange={(e, value) => setTags(value)}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Tags" />}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <FormControl required fullWidth disabled={submitted}>
          <InputLabel>{"Are you working with a realtor?"}</InputLabel>
          <Select
            label={"Are you working with a realtor?"}
            value={workingWithRealtor}
            onChange={(e) => setWorkingWithRealtor(e.target.value)}
            required
          >
            <MenuItem value="hasRealtor">Yes</MenuItem>
            <MenuItem value="noRealtor">No</MenuItem>
            <MenuItem value="isRealtor">I am a Realtor</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {workingWithRealtor === "hasRealtor" ? (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Realtor Name"
              variant="outlined"
              value={realtorName}
              onChange={(e) => setRealtorName(e.target.value)}
              disabled={submitted}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Realtor Email"
              type="email"
              variant="outlined"
              value={realtorEmail}
              onChange={(e) => setRealtorEmail(e.target.value)}
              disabled={submitted}
            />
          </Grid>
        </>
      ) : null}
      {workingWithRealtor === "hasRealtor" || workingWithRealtor === "isRealtor" ? (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Brokerage"
              variant="outlined"
              value={brokerage}
              onChange={(e) => setBrokerage(e.target.value)}
              disabled={submitted}
            />
          </Grid>
        </>
      ) : null}
      {setNotes ? (
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Notes"
            variant="outlined"
            name="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            disabled={submitted}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

interface ChildProps {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  rating?: string;
  setRating?: Dispatch<SetStateAction<string>>;
  ethnicity?: string;
  setEthnicity?: Dispatch<SetStateAction<string>>;
  postalCode?: string;
  setPostalCode?: Dispatch<SetStateAction<string>>;
  source?: string;
  setSource?: Dispatch<SetStateAction<string>>;
  salesRep?: IUser | null;
  onlineSalesRep?: IUser | null;
  handleSalesRep?: any;
  statuses?: IRegistrantStatus[];
  users?: IUser[];
  workingWithRealtor: string;
  setWorkingWithRealtor: Dispatch<SetStateAction<string>>;
  realtorName: string;
  setRealtorName: Dispatch<SetStateAction<string>>;
  realtorEmail: string;
  setRealtorEmail: Dispatch<SetStateAction<string>>;
  brokerage: string;
  setBrokerage: Dispatch<SetStateAction<string>>;
  scheduleWithRealtor?: boolean;
  setScheduleWithRealtor?: Dispatch<SetStateAction<boolean>>;
  meetingWithRealtor?: boolean;
  setMeetingWithRealtor?: Dispatch<SetStateAction<boolean>>;
  tags?: string[];
  setTags?: Dispatch<SetStateAction<string[]>>;
  projectTags?: string[];
  submitted: boolean;
  notes?: string;
  setNotes?: Dispatch<SetStateAction<string>>;
  relationships?: IRelationship[];
  handleRelationship?: any;
  registrants?: IRegistrant[];
  loading?: boolean;
  phoneRequired?: boolean;
}

export default RegistrantInfo;
