import { useState, Dispatch, SetStateAction, useEffect, useContext } from "react";
import {
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  SelectChangeEvent,
  Box,
  Fade,
  CircularProgress,
} from "@mui/material";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PhoneIcon from "@mui/icons-material/Phone";
import TextsmsIcon from "@mui/icons-material/Textsms";
import EmailIcon from "@mui/icons-material/Email";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";

import { IUser } from "../../../types/user";
import { IRegistrant } from "../../../types/registrant";
import { selectTemplates } from "../../../features/emailTemplate/emailTemplateSlice";
import { useTemplatesQuery } from "../../../features/emailTemplate/emailTemplateHooks";
import ShowEmailView from "./ShowEmailView";
import Notes from "../Notes";
import Texts from "../Texts";
import { IEmailTemplate } from "../../../types/process";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { selectProcess, selectTexts, setTexts } from "../../../features/registrants/registrantsSlice";
import MediaRecord from "../../common/MediaRecord";
import { IStepSchedule } from "../../../types/stepSchedule";
import { IText } from "../../../types/callRail";
import { RegistrantNotificationContext } from "../../../context/RegistrantNotificationContext";
import { showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { handleModal } from "../../../features/modal/modalSlice";

const EditStepView = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const texts = useSelector(selectTexts);
  const { registrantsDispatch } = useContext(RegistrantNotificationContext);
  const {
    name,
    setName,
    emailTemplate,
    setEmailTemplate,
    setDateCompleted,
    dateCompleted,
    dueDate,
    setDueDate,
    stepTime,
    handleTime,
    user,
    userCompleted,
    handleUser,
    users,
    handleSingleStep,
    handleProcess,
    registrant,
    step,
    handleCompleteStep,
    stepSchedules,
    setStepSchedules,
    setModalView,
  } = props;
  const [getEmails] = useTemplatesQuery(1, 100, "_ID_DESC", false, true, registrant.project._id);
  const templates = useSelector(selectTemplates);
  const [showStep, setShowStep] = useState("");
  const [textMessages, setTextMessages] = useState<IText | null>(null);

  useQuery(TEXTMESSAGES, {
    variables: { filter: { primaryPhone: registrant.primaryPhone } },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      let textMessageObject: IText | null = null;
      if (data.textMessageMany.length) {
        textMessageObject = {
          messages: data.textMessageMany.map((text: any) => {
            return {
              content: text.text,
              direction: text.type,
              created_at: text.createdAt,
              registrant: registrant,
              user: user,
            };
          }),
          lastMessage: data.textMessageMany[data.textMessageMany.length - 1].createdAt,
          name: registrant.project.name,
          phoneNumber: registrant.primaryPhone,
        };
      }
      setTextMessages(textMessageObject);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting appointments"));
    },
  });

  const [callRegistrant, { loading }] = useLazyQuery(CALLREGISTRANT, {
    onCompleted: (data) => {
      if (registrant) {
        registrantsDispatch({
          type: "DELETE",
          payload: {
            _id: registrant._id,
          },
        });
      }
      storeDispatch(showSuccessSnackbar("Calling Registrant..."));
      handleProcess("", null, false);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const handleCallRegistrant = () => {
    if (!registrant.primaryPhone) return storeDispatch(showErrorSnackbar("Registrant does not have a primary phone"));
    let formattedPhone = registrant.primaryPhone.replace(/\D/g, "");
    if (formattedPhone.length === 10 || formattedPhone.length === 11) {
      callRegistrant({ variables: { project: registrant.project._id, customerPhone: parseInt(formattedPhone, 10) } }).then((res) => {
        if (res.data) {
          setModalView("callPopup");
          storeDispatch(handleModal(true));
        }
      });
    } else return storeDispatch(showErrorSnackbar("Invalid Phone Number"));
  };

  const handleTexts = (data: any) => {
    storeDispatch(setTexts([data]));
  };

  useEffect(() => {
    getEmails();
    // eslint-disable-next-line
  }, []);

  const stepDisplay = () => {
    if (showStep === "email") {
      return (
        <ShowEmailView
          handleProcess={handleProcess}
          dateCompleted={dateCompleted}
          step={step}
          handleCompleteStep={handleCompleteStep}
          registrant={registrant}
          emailTemplate={emailTemplate}
          setShowStep={setShowStep}
          stepSchedules={stepSchedules}
          setStepSchedules={setStepSchedules}
        />
      );
    } else if (showStep === "record") {
      return (
        <Box>
          <Box sx={{ display: "flex" }}>
            <ArrowBackIcon onClick={() => setShowStep("")} sx={{ mr: 1, cursor: "pointer" }} />
            <Typography variant="h2" component="div" gutterBottom>
              <strong>Record a Video</strong>
            </Typography>
          </Box>
          <MediaRecord title={`${registrant.firstName} ${registrant.lastName}`} />
        </Box>
      );
    } else {
      return (
        <Box>
          <Box sx={{ display: "flex" }}>
            <ArrowBackIcon onClick={() => setShowStep("")} sx={{ mr: 1, cursor: "pointer" }} />
            <Typography variant="h2" component="div" gutterBottom>
              <strong>Text Messages</strong>
            </Typography>
          </Box>
          <Texts
            data={textMessages ? textMessages : texts[0]}
            handleProcess={handleProcess}
            dateCompleted={dateCompleted}
            step={step}
            handleCompleteStep={handleCompleteStep}
            setShowStep={setShowStep}
            handleTexts={handleTexts}
            registrant={registrant}
          />
        </Box>
      );
    }
  };

  return showStep ? (
    stepDisplay()
  ) : (
    <Fade in={true} timeout={1000}>
      {loading ? (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
            <CircularProgress sx={{ m: "0 auto" }} />
            <Box sx={{ mt: 2 }}>Calling Registrant...</Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: "flex" }}>
            <ArrowBackIcon onClick={() => handleProcess("", null, false)} sx={{ mr: 1, cursor: "pointer" }} />
            <Typography variant="h2" component="div" gutterBottom>
              <strong>Edit {name}</strong>
            </Typography>
          </Box>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2, mt: 2 }}>
            <TextField
              required
              fullWidth
              name="name"
              label="Step Name"
              type="text"
              variant="outlined"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Email Template</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={emailTemplate ? emailTemplate.name : ""}
              label="Email Template"
              onChange={(e: SelectChangeEvent<string>) => setEmailTemplate(e.target.value)}
              required
            >
              {templates.map((template: any, index: number) => {
                return (
                  <MenuItem key={index} value={template.name}>
                    {template.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {!dateCompleted ? (
            <>
              {dueDate ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Due Date"
                    value={dueDate}
                    minDate={dueDate}
                    onChange={(newValue) => {
                      setDueDate(newValue);
                    }}
                    renderInput={(params) => <TextField sx={{ mb: 2, mt: 2 }} {...params} />}
                  />
                </LocalizationProvider>
              ) : (
                <div>
                  <FormControl variant="outlined" sx={{ mb: 2, mt: 2 }}>
                    <TextField
                      sx={{ mr: 2 }}
                      required
                      name="days"
                      label={`Days until ${name}`}
                      type="number"
                      value={
                        stepTime
                          ? Math.floor((stepTime as any) / 24)
                              .toFixed(0)
                              .toString()
                          : ""
                      }
                      placeholder={`Days until ${name}`}
                      onChange={(e: any) => handleTime(e)}
                    />
                  </FormControl>
                  <FormControl variant="outlined" sx={{ mb: 2, mt: 2 }}>
                    <TextField
                      sx={{ mr: 2 }}
                      required
                      name="hours"
                      label={`Hours until ${name}`}
                      type="number"
                      value={stepTime}
                      placeholder={`Hours until ${name}`}
                      onChange={(e: any) => handleTime(e)}
                    />
                  </FormControl>
                </div>
              )}
            </>
          ) : null}
          {(user?.type === "Manager" && dateCompleted) || userCompleted?._id === user?._id ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Date Completed"
                  value={dateCompleted}
                  minDate={dateCompleted}
                  onChange={(newValue) => {
                    setDateCompleted(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <FormControl sx={{ my: 2 }} fullWidth required>
                <InputLabel>User Completed</InputLabel>
                <Select
                  name={"user"}
                  label={"User Completed"}
                  value={userCompleted?.fullName}
                  onChange={(e) => handleUser(e.target.value, "edit")}
                  required
                >
                  {users.map((user: IUser, index: number) => (
                    <MenuItem key={index} value={user.fullName}>
                      {user.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : null}
          <Notes registrant={registrant} step={step} />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", jsutifyContent: "space-between", mr: 2 }}>
              <Button onClick={() => handleSingleStep()} color="success" variant="contained">
                Save Changes
              </Button>
              {!dateCompleted ? (
                <>
                  <Button sx={{ ml: 2 }} endIcon={<EmailIcon />} onClick={() => setShowStep("email")} color="primary" variant="contained">
                    Email
                  </Button>
                  <Button sx={{ ml: 2 }} endIcon={<PhoneIcon />} onClick={() => handleCallRegistrant()} color="primary" variant="contained">
                    Call
                  </Button>
                  <Button sx={{ ml: 2 }} endIcon={<TextsmsIcon />} onClick={() => setShowStep("text")} color="primary" variant="contained">
                    Text
                  </Button>
                  <Button
                    sx={{ ml: 2 }}
                    endIcon={<RadioButtonCheckedIcon />}
                    onClick={() => setShowStep("record")}
                    color="primary"
                    variant="contained"
                  >
                    Record
                  </Button>
                </>
              ) : null}
            </Box>
            <Button onClick={() => handleProcess("", null, false)} color="info" variant="contained">
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Fade>
  );
};

interface ChildProps {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  emailTemplate: IEmailTemplate | null;
  setEmailTemplate: (emailTemplate: string) => void;
  dateCompleted: Date | null;
  setDateCompleted: Dispatch<SetStateAction<Date | null>>;
  dueDate: Date | null;
  setDueDate: Dispatch<SetStateAction<Date | null>>;
  stepTime: string;
  handleTime: any;
  user: IUser | null;
  userCompleted: IUser | null;
  handleUser: (fullName: string, type: string) => void;
  users: IUser[];
  handleSingleStep: () => void;
  handleProcess: (view: string, index: null, modalOpen: boolean) => void;
  handleCompleteStep: any;
  registrant: IRegistrant;
  step: string;
  stepSchedules: IStepSchedule[];
  setStepSchedules: Dispatch<SetStateAction<IStepSchedule[] | []>>;
  setModalView: Dispatch<SetStateAction<string>>;
}

const CALLREGISTRANT = gql`
  query callRegistrant($project: MongoID!, $customerPhone: Float!) {
    callRegistrant(project: $project, customerPhone: $customerPhone)
  }
`;

const TEXTMESSAGES = gql`
  query textMessageMany($filter: FilterFindManyTextMessageInput) {
    textMessageMany(filter: $filter, limit: 10000) {
      _id
      type
      text
      project {
        _id
      }
      user {
        fullName
      }
      registrant {
        project {
          _id
        }
        primaryPhone
        fullName
      }
      primaryPhone
      createdAt
    }
  }
`;

export default EditStepView;
