import { SetStateAction, Dispatch, useMemo } from "react";
import { Box, Button } from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";

import { ITemplates, setTemplates } from "../../../features/emailTemplate/emailTemplateSlice";
import { useSelectTemplates } from "../../../features/emailTemplate/emailTemplateHooks";
import { convertAllDates } from "../../../utils/function";
import { ITemplate } from "./EmailTemplates";
import StandardTable from "../../tables/StandardTable";
import { IAttachment, IEmailTemplate } from "../../../types/process";
import { useAppDispatch } from "../../../app/hooks";

const TextTemplates = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { setTemplate, loading, handleGlobalFilterValue, count, pageNumber, setPageNumber, value, updateEmailTemplate, setAttachments } = props;
  const templates = useSelectTemplates();

  const columns = useMemo(() => {
    return [
      {
        Header: "Template Name",
        accessor: (rowData: ITemplates) => {
          return (
            <strong style={{ cursor: "pointer" }} onClick={() => {
              setTemplate(rowData)
              setAttachments(rowData.attachments)
            }}>
              {rowData.name}
            </strong>
          );
        },
      },
      {
        Header: "Subject",
        accessor: (rowData: ITemplates) => {
          return rowData.subject;
        },
      },
      {
        Header: "Project",
        accessor: (rowData: ITemplates) => {
          return rowData.project ? rowData.project.name : "";
        },
      },
      {
        Header: "Step Template",
        accessor: (rowData: ITemplates) => {
          return rowData.step ? "Yes" : "No";
        },
      },
      {
        Header: "Created Date",
        accessor: (rowData: ITemplates) => {
          return convertAllDates(rowData.createdAt, "PPpp");
        },
      },
      {
        Header: "Last Updated",
        accessor: (rowData: ITemplates) => {
          return convertAllDates(rowData.updatedAt, "PPpp");
        },
      },
      {
        Header: value === 3 ? "Unarchive" : "Archive",
        accessor: (rowData: ITemplates) => {
          if (value === 3) {
            return (
              <UnarchiveIcon
                onClick={() => {
                  updateEmailTemplate({
                    variables: {
                      _id: rowData._id,
                      record: {
                        archived: false,
                      },
                    },
                  });
                  storeDispatch(
                    setTemplates({
                      items: templates.filter((template: IEmailTemplate) => template._id !== rowData._id),
                      count: templates.length - 1,
                    })
                  );
                }}
                sx={{ cursor: "pointer" }}
              />
            );
          } else {
            return (
              <ArchiveIcon
                onClick={() => {
                  updateEmailTemplate({
                    variables: {
                      _id: rowData._id,
                      record: {
                        archived: true,
                      },
                    },
                  });
                  storeDispatch(
                    setTemplates({
                      items: templates.filter((template: IEmailTemplate) => template._id !== rowData._id),
                      count: templates.length - 1,
                    })
                  );
                }}
                sx={{ cursor: "pointer" }}
              />
            );
          }
        },
      },
    ];
  }, [value]);

  return (
    <Box>
      <StandardTable data={templates} columns={columns} loading={loading} handleGlobalFilterValue={handleGlobalFilterValue} count={count} />
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
          {"<"}
        </Button>
        <span>{pageNumber}</span>
        <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
          {">"}
        </Button>
      </Box>
    </Box>
  );
};

interface ChildProps {
  setTemplate: Dispatch<SetStateAction<ITemplate | null>>;
  setAttachments: Dispatch<SetStateAction<IAttachment[] | []>>;
  loading: boolean;
  handleGlobalFilterValue: any;
  count: number;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  value: number;
  updateEmailTemplate: any;
}

export default TextTemplates;
