import { useState, useEffect, useMemo } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import * as xlsx from "xlsx";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { gql, useMutation } from "@apollo/client";
import { Typography, Box, FormControl, InputLabel, MenuItem, Select, TextField, Autocomplete, Button } from "@mui/material";
import { IUser } from "../../types/user";
import { FlexBetween } from "../../commonStyles";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../app/hooks";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { selectTemplates } from "../../features/emailTemplate/emailTemplateSlice";
import { IProjectAccess } from "../../types/user";
import { handleModal } from "../../features/modal/modalSlice";
import { convertAllDates } from "../../utils/function";
import { useNavigate } from "react-router";
import { IProject } from "../../types/project";
import { ISchedule } from "../../types/schedule";
import { useTemplatesQuery } from "../../features/emailTemplate/emailTemplateHooks";
import { selectUsers } from "../../features/projectSetting/projectSettingSlice";

const MassMail = (props: ChildProps) => {
  const { id, selectedRegistrants, project, schedule } = props;
  const navigate = useNavigate();
  const storeDispatch = useAppDispatch();
  const [getEmails] = useTemplatesQuery(1, 100, "_ID_DESC");
  const auth = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const templates = useSelector(selectTemplates);
  const [senderName, setSenderName] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [emailTemplate, setEmailTemplate] = useState<string>("");
  const [usersToNotify, setUsersToNotify] = useState([
    {
      fullName: "RDS",
      email: "info@rdsre.ca",
    },
    {
      fullName: "Charles Jaque",
      email: "charlesj@rdsbrokerage.com",
    },
    {
      fullName: "Matthew Lavender",
      email: "matthewl@rdsbrokerage.com",
    },
    {
      fullName: "Brian Shew",
      email: "brians@rdsbrokerage.com",
    },
    {
      fullName: "Lorraine Athaide",
      email: "lorrainea@rdsbrokerage.com",
    },
  ]);
  const [mailDate, setMailDate] = useState<Date | null>(null);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const [customRegistrants, setCustomRegistrants] = useState<ICustomRegistrant[]>([]);

  useEffect(() => {
    getEmails();
    // eslint-disable-next-line
  }, []);

  const [saveMailSchedule] = useMutation(SAVEMAILSCHEDULE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("Scheduled Mail Saved!"));
      storeDispatch(handleModal(false));
      navigate(`/dashboard`);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const handleScheduleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!senderName) return storeDispatch(showErrorSnackbar("No Sender Found"));
    if (!mailDate) return storeDispatch(showErrorSnackbar("Date for Mass Mail is required"));
    if (!selectedProject && !project) return storeDispatch(showErrorSnackbar("No Project Found"));
    if (id === "custom" && !customRegistrants.length) return storeDispatch(showErrorSnackbar("No Excel File Found"));

    if (id === "custom") {
      if (!customRegistrants.every((custom: any) => custom.email))
        return storeDispatch(showErrorSnackbar("There are missing emails in the uploaded excel file or the columns are incorrect"));
    }

    let salesRep = users.find((sale: IUser) => sale.email === user);

    let allRegistrants = selectedRegistrants;

    saveMailSchedule({
      variables: {
        record: {
          project: selectedProject ? selectedProject._id : project?._id,
          emailTemplate,
          user: salesRep ? salesRep?._id : null,
          usersToNotify: usersToNotify.map((user) => user.email),
          mailDate,
          senderName,
          senderEmail: user,
          count: id === "custom" ? customRegistrants.length : selectedRegistrants.length,
          type: id === "custom" ? "custom" : id === "purchasers" ? "purchaser" : "registrant",
          registrants: id === "custom" ? [] : id === "purchasers" ? [] : allRegistrants,
          purchasers: id === "purchasers" ? allRegistrants : [],
          custom: id === "custom" ? customRegistrants : [],
        },
      },
    });
  };

  const handleUserChange = (e: any) => {
    if (project?.email! === e.target.value) {
      setSenderName(project?.name!);
      setUser(e.target.value);
    } else {
      if (!project) {
        let selectedProject = auth?.projectAccess.find((projectAccess: IProjectAccess) => projectAccess.project.email === e.target.value);
        if (selectedProject) {
          setSenderName(selectedProject.project.name);
          setUser(e.target.value);
          setSelectedProject(selectedProject.project);
        }
      } else {
        let user = users.find((user: IUser) => user.email === e.target.value);
        if (user) {
          setSenderName(user.fullName);
          setUser(user.email);
        } else {
          let projects = auth?.projectAccess.find((projectAccess: IProjectAccess) => projectAccess.project.email === e.target.value);
          if (projects) {
            setSenderName(projects?.project.name!);
          } else setSenderName("RDS");
          setUser(e.target.value);
        }
      }
    }
  };

  const handleUsersToNotify = (e: any, value: any) => {
    setUsersToNotify(value);
  };

  const handleProject = (e: any) => {
    let selectedProject = auth?.projectAccess.find((projectAccess: IProjectAccess) => projectAccess.project._id === e);
    if (selectedProject) {
      setSelectedProject(selectedProject.project);
    }
  };

  const readUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const data = event.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        let customData = [...json].map((data: any) => {
          return {
            firstName: data.firstName ? data.firstName : "",
            email: data.email,
          };
        });
        setCustomRegistrants(customData);
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  };

  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Send Mass Mail</strong>
      </Typography>
      <Typography sx={{ mt: 2 }} variant="h3" component="div" gutterBottom>
        <strong>
          Selected:{" "}
          <Box sx={{ display: "inline", color: "success.main" }}>
            {selectedRegistrants && selectedRegistrants.length ? selectedRegistrants.length : customRegistrants.length}
          </Box>
        </strong>
      </Typography>
      {id === "custom" ? (
        <Box>
          <Box sx={{ my: 1 }}>You can upload a custom excel. It will require two columns (email and firstName). Email is mandatory.</Box>
          <input style={{ display: "none" }} id="upload-excel" name="upload-excel" onChange={(e) => readUploadFile(e)} type="file" />
          <label htmlFor="upload-excel">
            <Button color="primary" variant="contained" component="span">
              Upload Excel
            </Button>
          </label>
        </Box>
      ) : null}
      <form onSubmit={handleScheduleSubmit}>
        {!schedule && !project ? (
          <Box sx={{ mt: 2 }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-project">Project</InputLabel>
              <Select
                label="Project"
                sx={{ width: "100%" }}
                labelId="demo-simple-select-project"
                id="demo-simple-project"
                value={selectedProject ? selectedProject._id : ""}
                onChange={(e: any) => {
                  handleProject(e.target.value);
                }}
                required
              >
                {auth?.projectAccess.map((projectAccess: IProjectAccess, index: number) => {
                  return (
                    <MenuItem key={index} value={projectAccess.project._id}>
                      {projectAccess.project.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        ) : null}
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Sender</InputLabel>
            <Select required label={"Sales Rep"} value={user} onChange={(e) => handleUserChange(e)}>
              {auth?.projectAccess.length
                ? [...auth?.projectAccess].map((projectAccess: IProjectAccess) => {
                    return (
                      <MenuItem value={projectAccess.project.email}>
                        {projectAccess.project.name} ({projectAccess.project.email})
                      </MenuItem>
                    );
                  })
                : null}
              {auth?.type !== "Developer" && auth?.type !== "Marketing"
                ? [...users].map((user, j) => (
                    <MenuItem key={j} value={user.email}>
                      {user.fullName} ({user.email})
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Box>
        {user ? (
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth variant="outlined">
              <TextField
                required
                fullWidth
                name="senderName"
                label="Sender Name"
                type="text"
                variant="outlined"
                value={senderName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSenderName(e.target.value)}
              />
            </FormControl>
          </Box>
        ) : null}
        <Box sx={{ mt: 2 }}>
          <Autocomplete
            multiple
            id="users-to-notify"
            options={users}
            freeSolo={false}
            getOptionLabel={(option: any) => option.fullName}
            isOptionEqualToValue={(option, value) => option === value}
            value={usersToNotify}
            onChange={(e, value) => handleUsersToNotify(e, value)}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Users To Notify" />}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Mail Date"
              value={mailDate}
              minDate={mailDate}
              disablePast
              onChange={(newValue) => {
                setMailDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Autocomplete
            id="email-templates"
            options={templates}
            freeSolo={false}
            getOptionLabel={(option: any) => `${option.name} (${convertAllDates(option.createdAt, "PP")})`}
            onChange={(e, value) => setEmailTemplate(value?._id!)}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Email Templates" />}
          />
        </Box>
        <FlexBetween sx={{ mt: 2 }}>
          <Button type="submit" color="success" variant="contained">
            Schedule
          </Button>
          <Button onClick={() => storeDispatch(handleModal(false))} color="info" variant="contained">
            Cancel
          </Button>
        </FlexBetween>
      </form>
    </div>
  );
};

interface ChildProps {
  id: string;
  selectedRegistrants: string[];
  project: IProject | null;
  schedule?: ISchedule | null;
}

interface ICustomRegistrant {
  firstName?: string;
  email: string;
}

const SAVEMAILSCHEDULE = gql`
  mutation mailScheduleCreateOne($record: CreateOneMailScheduleInput!) {
    mailScheduleCreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;

export default MassMail;
