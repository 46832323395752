import { useState, useEffect, useReducer } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box, TextField, Button, Grid } from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import AppointmentsTable from "./AppointmentsTable";
import BookAnAppointmentDialog from "../registrant/process/BookAnAppointmentDialog";
import { GlobalModal } from "../../features/modal/Modal";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import MassMail from "../common/MassMail";
import { handleModal } from "../../features/modal/modalSlice";
import { IAppointment } from "../../types/project";

export const appointmentsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload.value];
    case "COPY":
      return [...state, action.payload];
    case "UPDATE":
      return state.map((state: any, index: number) => {
        if (state._id === action.payload._id) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case "UPDATEALL":
      return action.payload;
    case "DELETE":
      return state.filter((state: any, index: number) => state._id !== action.payload._id);
    default:
      throw new Error();
  }
};

const Other = () => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [appointmentsState, appointmentsDispatch] = useReducer(appointmentsReducer, []);
  const [dateStart, setDateStart] = useState<Date | number | null | string>(new Date().setHours(0, 0, 0, 0));
  const [dateEnd, setDateEnd] = useState<Date | number | null | string>(new Date().setHours(23, 59, 59, 59));
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [selectedRegistrants, setSelectedRegistrants] = useState<string[]>([]);
  const [dialogType, setDialogType] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [bookAppointmentOpen, setBookAppointmentOpen] = useState<boolean>(false);

  const [getAppointments, { loading }] = useLazyQuery(GETAPPOINTMENTS, {
    onCompleted: (data) => {
      let appointments = [...data.appointmentMany].sort((a: any, b: any) => {
        return new Date(a.date).valueOf() - new Date(b.date).valueOf();
      });
      setSelectedRegistrants([]);
      appointmentsDispatch({
        type: "UPDATEALL",
        payload: appointments,
      });
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting appointments"));
    },
  });

  useEffect(() => {
    if (dateStart && dateEnd && dateStart.toString() !== "Invalid Date" && dateEnd.toString() !== "Invalid Date") {
      getAppointments({
        variables: {
          filter: {
            dateGreaterThanEqual: new Date(dateStart!),
            dateLessThanEqual: new Date(dateEnd!),
            schedule: null,
            search: searchFilter,
            projects: user?.projectAccess.map((access: any) => access.project._id),
            typeNot: "lease",
          },
        },
      });
    }
  }, [dateStart, dateEnd, getAppointments, searchFilter]);

  return (
    <Box sx={{ p: 2 }}>
      <BookAnAppointmentDialog open={bookAppointmentOpen} setOpen={setBookAppointmentOpen} appointmentsDispatch={appointmentsDispatch} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={"Start Date (YYYY/MM/DD)"}
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={"End Date (YYYY/MM/DD)"}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          {appointmentsState.length && selectedRegistrants.length && user?.type !== "Developer" ? (
            <Button
              sx={{ mr: 1, mb: { xs: 2, md: 0 }, width: { xs: "100%", md: "max-content" } }}
              onClick={() => storeDispatch(handleModal(true))}
              color="primary"
              variant="contained"
            >
              Mass Mail
            </Button>
          ) : null}
          <Button
            sx={{ ml: { xs: 0, md: 2 }, width: { xs: "100%", md: "max-content" } }}
            variant="contained"
            onClick={() => {
              setBookAppointmentOpen(true);
            }}
          >
            Book Appointment
          </Button>
        </Grid>
      </Grid>
      {appointmentsState.length && user?.type !== "Developer" ? (
        <GlobalModal>
          <MassMail id={"registrant"} selectedRegistrants={selectedRegistrants} project={null} schedule={null} />
        </GlobalModal>
      ) : null}
      {appointmentsState.length ? (
        <Grid sx={{ my: 1 }} spacing={2} container>
          <Grid item xs={6} md={2.5}>
            Appointments Total: <strong>{appointmentsState.length}</strong>
          </Grid>
          <Grid item xs={6} md={2.5}>
            Appointments Confirmed: <strong>{appointmentsState.filter((appointment: IAppointment) => appointment.confirmed).length}</strong>
          </Grid>
          <Grid item xs={6} md={2.5}>
            Appointments Cancelled: <strong>{appointmentsState.filter((appointment: IAppointment) => appointment.cancelled).length}</strong>
          </Grid>
          <Grid item xs={6} md={2.5}>
            Attended Appointments: <strong>{appointmentsState.filter((appointment: IAppointment) => appointment.cameIn).length}</strong>
          </Grid>
          <Grid item xs={6} md={2}>
            Guests Total:{" "}
            <strong>
              {appointmentsState.reduce(function (r: any, a: any) {
                return r + a.purchaserInfo.numberOfGuests;
              }, 0)}
            </strong>
          </Grid>
        </Grid>
      ) : null}
      <AppointmentsTable
        appointments={appointmentsState}
        loading={loading}
        appointmentsDispatch={appointmentsDispatch}
        schedule={null}
        setSchedule={null}
        schedules={[]}
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        dialogOpen={dialogOpen}
        dialogType={dialogType}
        setDialogOpen={setDialogOpen}
        setDialogType={setDialogType}
        setSearchFilter={setSearchFilter}
        selectedRegistrants={selectedRegistrants}
        setSelectedRegistrants={setSelectedRegistrants}
      />
    </Box>
  );
};

const GETAPPOINTMENTS = gql`
  query appointmentMany($filter: FilterFindManyAppointmentInput) {
    appointmentMany(filter: $filter, limit: 100000) {
      _id
      project {
        _id
        salesOffice
        name
      }
      user {
        fullName
        email
        realtor {
          _id
          fullName
          directPhone
        }
      }
      date
      location
      purchaserInfo {
        firstName
        lastName
        email
        primaryPhone
        numberOfGuests
      }
      questions {
        questionId {
          _id
          name
          question
          type
          choices {
            choice
            followUp {
              _id
            }
          }
        }
        answer
      }
      status
      type
      notes
      confirmed
      cancelled
      cameIn
      noResponse
      salesNotes
      salesRep {
        _id
        fullName
      }
      registrant {
        _id
        email
        rating
        firstName
        lastName
        primaryPhone
        realtorType
        source
        project {
          _id
        }
        statuses {
          name
          questions {
            questionId {
              _id
              question
            }
            answer
          }
          createdAt
        }
        tags
      }
      createdAt
    }
  }
`;

export default Other;
