import { Dispatch, SetStateAction, useEffect } from "react";
import {
  Button,
  TextField,
  Switch,
  FormControl,
  FormControlLabel,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Autocomplete,
} from "@mui/material";
import { useSelector } from "react-redux";

import { FlexBetween } from "../../../commonStyles";
import { useAppDispatch } from "../../../app/hooks";
import { handleModal } from "../../../features/modal/modalSlice";
import { selectTemplates } from "../../../features/emailTemplate/emailTemplateSlice";
import { useTemplatesQuery } from "../../../features/emailTemplate/emailTemplateHooks";
import { IEmailTemplate, IStep, IStepTemplate } from "../../../types/process";

const CreateProcessSteps = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const templates = useSelector(selectTemplates);
  const {
    stepId,
    handleStepSubmit,
    name,
    setName,
    type,
    setType,
    emailTemplate,
    setEmailTemplate,
    defaultStep,
    setDefaultStep,
    time,
    setTime,
    create,
    subSteps,
    setSubSteps,
    steps,
  } = props;
  const [getEmails] = useTemplatesQuery(1, 100, "_ID_DESC");

  useEffect(() => {
    getEmails();
    // eslint-disable-next-line
  }, []);

  const handleTime = (e: any) => {
    if (e.target.name === "hours") {
      setTime(e.target.value);
    } else if (e.target.name === "days") {
      setTime(e.target.value ? (e.target.value * 24).toString() : "");
    }
  };

  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>{stepId ? "Edit Step" : "Add Step"}</strong>
      </Typography>
      {stepId ? (
        <p>
          <strong>
            Please note these changes will affect steps for all projects. If you would like to edit a step for a specific project. Please
            create a new one.
          </strong>
        </p>
      ) : null}
      {steps ? (
        <p>
          <strong>Please note that substeps are always due 1 day after the previous step.</strong>
        </p>
      ) : null}
      <div>
        <form onSubmit={handleStepSubmit}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2, mt: 2 }}>
            <TextField
              required
              fullWidth
              name="name"
              label="Step Name"
              type="text"
              variant="outlined"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Question Type"
              onChange={(e: SelectChangeEvent<string>) => setType(e.target.value)}
              required
            >
              <MenuItem value={"regular"}>Regular</MenuItem>
              <MenuItem value={"automated"}>Automated</MenuItem>
            </Select>
          </FormControl>
          {steps ? (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Autocomplete
                id="sub-step"
                multiple
                options={steps}
                freeSolo={false}
                disabled={false}
                getOptionLabel={(option: any) => option.name}
                value={subSteps}
                onChange={(e: any, value: any) => setSubSteps(value)}
                renderInput={(params: any) => <TextField {...params} variant="outlined" label="Sub Steps" />}
              />
            </FormControl>
          ) : null}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Autocomplete
              id="tags-standard"
              options={templates.map((template: IEmailTemplate) => template.name)}
              freeSolo={false}
              disabled={false}
              getOptionLabel={(option: any) => option}
              value={emailTemplate ? emailTemplate.name : ""}
              onChange={(e: any, value: any) => setEmailTemplate(value)}
              renderInput={(params: any) => <TextField {...params} variant="outlined" label="Email Template" />}
            />
          </FormControl>
          {!stepId && !create ? (
            <>
              <FormControl variant="outlined" sx={{ mb: 2, mt: 2 }}>
                <TextField
                  sx={{ mr: 2 }}
                  required
                  name="days"
                  label="Days After Previously Completed Step"
                  type="number"
                  value={
                    time
                      ? Math.floor((time as any) / 24)
                          .toFixed(0)
                          .toString()
                      : ""
                  }
                  placeholder={"Days After Previously Completed Step"}
                  onChange={handleTime}
                />
              </FormControl>
              <FormControl variant="outlined" sx={{ mb: 2, mt: 2 }}>
                <TextField
                  sx={{ mr: 2 }}
                  required
                  name="hours"
                  label="Hours After Previously Completed Step"
                  type="number"
                  value={time}
                  placeholder={"Hours After Previously Completed Step"}
                  onChange={handleTime}
                />
              </FormControl>
            </>
          ) : null}
          <FormControlLabel
            checked={defaultStep}
            control={<Switch onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDefaultStep(!defaultStep)} color="secondary" />}
            label="Default"
          />
          <FlexBetween sx={{ mt: 2 }}>
            <Button color="success" variant="contained" type="submit">
              {stepId ? "Update" : "Create"}
            </Button>
            <Button onClick={() => storeDispatch(handleModal(false))} color="info" variant="contained">
              Cancel
            </Button>
          </FlexBetween>
        </form>
      </div>
    </div>
  );
};

interface ChildProps {
  stepId?: string;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  emailTemplate: IEmailTemplate | null;
  setEmailTemplate: (emailTemplate: string) => void;
  handleStepSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  defaultStep: boolean;
  setDefaultStep: Dispatch<SetStateAction<boolean>>;
  time?: string;
  setTime: Dispatch<SetStateAction<string>>;
  create?: boolean;
  steps?: IStepTemplate[];
  subSteps: IStepTemplate[];
  setSubSteps: Dispatch<SetStateAction<IStepTemplate[]>>;
}

export default CreateProcessSteps;
