import { ITemplate } from "./EmailTemplates";
import {
  Box,
  Button,
  Select,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useRef, useState, useEffect, useCallback } from "react";
import { EditorRef, EmailEditor } from "react-email-editor";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { TestEmailDialog, AttachmentsDialog, FormatHtmlDialog, ImageUploadDialog } from "../../common/textEditor/MenuDialogs";
import { customFonts } from "./HtmlEditorConfig";
import { UnsavedPromptDialog } from "../../common/textEditor/MenuDialogs";
import customJS from "./EditorCustomJS";
import { download } from "../../../utils/function";

const EditHtmlTemplate = ({
  htmlTemplate,
  setHtmlTemplate,
  htmlTemplates,
  setHtmlTemplates,
  refetchHtmlTemplates,
}: EditHTMLTemplateProps) => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const emailEditorRef = useRef<EditorRef | null>(null);
  const img = new Image();

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [autosave, setAutosave] = useState(false);
  const [showTestEmailDialog, setShowTestEmailDialog] = useState(false);
  const [showAttachmentsDialog, setShowAttachmentsDialog] = useState(false);
  const [showImageUploadDialog, setShowImageDialog] = useState(false);
  const [attachments, setAttachments] = useState(htmlTemplate?.attachments ? htmlTemplate.attachments : []);
  const [formatHtml, setFormatHtml] = useState<boolean>(false);

  if (!htmlTemplate?.project) setHtmlTemplate({ ...(htmlTemplate as ITemplate), project: user?.projectAccess[0].project });

  const [getFolders, { data, loading }] = useLazyQuery(GETFOLDERS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getFolders({ variables: { type: "project" } });
  }, [getFolders]);

  /* Functions */

  const [deleteAttachment] = useMutation(DELETEATTACHMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("Attachment Deleted!"));
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [createHtmlTemplate] = useMutation(CREATEHTMLTEMPLATE, {
    onCompleted: (data) => {
      const createdTemplate = data.emailTemplateCreateOne.record;
      setHtmlTemplates([data.emailTemplateCreateOne.record, ...htmlTemplates]);
      setHtmlTemplate(createdTemplate);
      setAttachments(createdTemplate.attachments);
      setUnsavedChanges(false);
      setAutosave(false);
      storeDispatch(showSuccessSnackbar("Html Template Successfully Created!"));
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [saveHtmlTemplate] = useMutation(SAVEHTMLTEMPLATE, {
    onCompleted: (data) => {
      const updatedTemplate: ITemplate = data.emailTemplateUpdateById.record;
      let updatedTemplates = htmlTemplates.map((htmlTemplate: ITemplate) => {
        if (htmlTemplate._id === updatedTemplate._id) {
          return updatedTemplate;
        } else return htmlTemplate;
      });
      setHtmlTemplate(updatedTemplate);
      setHtmlTemplates(updatedTemplates);
      setAttachments(updatedTemplate?.attachments!);
      setUnsavedChanges(false);
      setAutosave(false);
      storeDispatch(showSuccessSnackbar("Html Template Successfully Saved!"));
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [uploadAttachments] = useLazyQuery(UPLOADATTACHMENTS, {
    onError: (e) => console.log(e.message),
  });

  // const [refetchEmailTemplate] = useGetEmailTemplate((data: any) => {
  //   const gottenHtmlTemplate = data.emailTemplateById;
  //   setHtmlTemplate(gottenHtmlTemplate);
  //   setFormAttachments(gottenHtmlTemplate.attachments);
  //   setFormAttachmentObjs(Array.apply(null, Array(gottenHtmlTemplate.attachments.length)));
  //   emailEditorRef.current?.editor?.loadDesign(JSON.parse(gottenHtmlTemplate.htmlTemplateJson));
  // });

  const downloadHtmlHandler = () => {
    emailEditorRef.current?.editor?.exportHtml((data) => {
      const { html } = data;
      download(html, htmlTemplate?.name ? `${htmlTemplate.name}.html` : "Untitled.html", "text/html");
    });
  };

  const saveTemplateHandler = useCallback(() => {
    return new Promise((resolve, reject) => {
      emailEditorRef.current?.editor?.exportHtml(async (data) => {
        const { design, html: designHtml } = data;
        if (!design) {
          storeDispatch(showErrorSnackbar("Error in saving design."));
          return reject();
        }
        if (!htmlTemplate?.name || !htmlTemplate?.subject || !htmlTemplate?.project) return reject();

        let attachmentsToUpload = attachments.filter((obj: any) => obj !== null && obj !== undefined && obj.file);

        const attachmentsToUpdateOrCreate = attachments!.map((formAttachment) => {
          return { name: formAttachment.name };
        });

        const stringifiedDesign = JSON.stringify(design);
        if (htmlTemplate?._id) {
          await saveHtmlTemplate({
            variables: {
              id: htmlTemplate._id,
              name: htmlTemplate.name,
              subject: htmlTemplate.subject,
              html: designHtml,
              htmlTemplateJson: stringifiedDesign,
              attachments: attachmentsToUpdateOrCreate,
              project: htmlTemplate.project._id,
              step: htmlTemplate.step,
            },
          });
          await uploadAttachments({
            variables: {
              emailTemplateId: htmlTemplate._id,
              files: attachmentsToUpload.map((file: any) => file.file),
            },
          });
          // await refetchEmailTemplate({ variables: { id: htmlTemplate._id } });
          return resolve(htmlTemplate._id);
        }
        if (!htmlTemplate?._id) {
          const response = await createHtmlTemplate({
            variables: {
              name: htmlTemplate.name,
              subject: htmlTemplate.subject,
              html: designHtml,
              htmlTemplateJson: stringifiedDesign,
              attachments: attachmentsToUpdateOrCreate,
              project: htmlTemplate.project._id,
              step: htmlTemplate.step,
            },
          });
          const newTemplateId = response.data.emailTemplateCreateOne.record._id;
          await uploadAttachments({
            variables: { emailTemplateId: newTemplateId, files: attachmentsToUpload.map((file: any) => file.file) },
          });
          // await refetchEmailTemplate({ variables: { id: newTemplateId } });
          return resolve(newTemplateId);
        }
      });
    });
  }, [
    createHtmlTemplate,
    saveHtmlTemplate,
    storeDispatch,
    htmlTemplate?._id,
    htmlTemplate?.name,
    htmlTemplate?.project,
    htmlTemplate?.subject,
    htmlTemplate?.step,
    attachments,
    uploadAttachments,
  ]);

  const onEditorLoad = () => {
    if (htmlTemplate?.htmlTemplateJson) {
      const nonStringVer = JSON.parse(htmlTemplate.htmlTemplateJson);
      emailEditorRef.current?.editor?.loadDesign(nonStringVer);
      emailEditorRef.current?.editor?.setBodyValues({
        backgroundColor: "#fff",
        contentWidth: "600px",
      });
    }
    if (htmlTemplate?._id.length === 0 && !htmlTemplate?.htmlTemplateJson) {
      emailEditorRef.current?.editor?.exportHtml((data) => {
        const { html, design } = data;
        const newTemplate = {
          ...htmlTemplate,
          html: html,
          htmlTemplateJson: JSON.stringify(design),
        };
        setHtmlTemplate(newTemplate);
      });
      (emailEditorRef.current?.editor as any).setBodyValues({ backgroundColor: "#ffffff", contentWidth: "600px" });
    }

    // (emailEditorRef.current?.editor as any).frame.iframe.onmouseout = () => {
    //   const exportHtmlButton = document.querySelector("#export-html-button");
    //   const tmp = document.createElement("input");
    //   exportHtmlButton?.appendChild(tmp);
    //   tmp.focus();
    //   exportHtmlButton?.removeChild(tmp);
    //   emailEditorRef?.current?.editor?.exportHtml((data) => {
    //     if (!htmlTemplate?.htmlTemplateJson) {
    //       return;
    //     }
    //     const { html, design } = data;
    //     const stringVer = JSON.stringify(design);
    //     if (htmlTemplate.htmlTemplateJson !== stringVer) {
    //       const newHtmlTemplate = {
    //         ...htmlTemplate,
    //         html: html,
    //         htmlTemplateJson: JSON.stringify(design),
    //       };
    //       console.log(newHtmlTemplate, 'new2');
    //       setHtmlTemplate(newHtmlTemplate);
    //       setUnsavedChanges(true);
    //     }
    //   });
    // };
  };

  const handleCloseHtmlTemplate = () => {
    if (unsavedChanges) {
      setShowWarningDialog(true);
      return;
    }
    setHtmlTemplate(null);
    refetchHtmlTemplates({
      user: user?._id,
      page: 0,
    });
  };

  const deleteFile = (id: number, title: string, numIndex: number) => {
    if (id) {
      deleteAttachment({ variables: { _id: htmlTemplate?._id, attachmentId: id, deleteFile: true } }).then((res: any) => {
        if (res.data.deleteEmailAttachment) {
          setAttachments(res.data.deleteEmailAttachment.attachments);
          setHtmlTemplate(res.data.deleteEmailAttachment);
        }
      });
    } else {
      let removedAttachments = attachments.filter((id: any, index: number) => numIndex !== index);
      setAttachments(removedAttachments);
    }
  };

  return (
    <>
      <UnsavedPromptDialog
        unsavedChanges={unsavedChanges}
        showWarningDialog={showWarningDialog}
        setShowWarningDialog={setShowWarningDialog}
        onLeave={() => {
          setHtmlTemplate(null);
          setUnsavedChanges(false);
          setShowWarningDialog(false);
          refetchHtmlTemplates({
            user: user?._id,
            page: 0,
          });
        }}
      />
      <TestEmailDialog
        showTestEmailDialog={showTestEmailDialog}
        setShowTestEmailDialog={setShowTestEmailDialog}
        saveTemplateHandler={saveTemplateHandler}
        htmlTemplate={htmlTemplate}
      />
      {showImageUploadDialog && (
        <ImageUploadDialog type={"image"} setOpen={setShowImageDialog} img={img} imgQuery={{ getFolders, data, loading }} />
      )}
      {showAttachmentsDialog && (
        <AttachmentsDialog
          attachmentsDialogOpen={showAttachmentsDialog}
          setAttachmentsDialogOpen={setShowAttachmentsDialog}
          attachments={attachments}
          setAttachments={setAttachments}
          setUnsavedChanges={setUnsavedChanges}
          deleteFile={deleteFile}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "flex-start", alignItems: "center", mb: 2 }}>
        {formatHtml && <FormatHtmlDialog formatHtml={formatHtml} setFormatHtml={setFormatHtml} />}
        <IconButton onClick={handleCloseHtmlTemplate} sx={{ mr: 1 }}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h2">
          <strong>{htmlTemplate?._id ? "Edit" : "Create"} HTML Template</strong>
        </Typography>
      </Box>
      <Box component="form" onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              sx={{ my: 1 }}
              label="Name"
              fullWidth
              required
              value={htmlTemplate?.name}
              onChange={(e) => {
                setHtmlTemplate({ ...(htmlTemplate as ITemplate), name: e.target.value as string });
                setTimeout(() => setUnsavedChanges(true), 100);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              sx={{ my: 1 }}
              label="Subject"
              fullWidth
              required
              value={htmlTemplate?.subject}
              onChange={(e) => {
                setHtmlTemplate({ ...(htmlTemplate as ITemplate), subject: e.target.value as string });
                setTimeout(() => setUnsavedChanges(true), 100);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel>Project</InputLabel>
              <Select
                name={"project"}
                label={"Project"}
                value={htmlTemplate?.project._id || user?.projectAccess[0].project._id}
                required
                onChange={(e) => {
                  let selectedProject = user?.projectAccess.find((projects: any) => projects.project._id === e.target.value);
                  setHtmlTemplate({ ...(htmlTemplate as ITemplate), project: selectedProject ? selectedProject.project : null });
                  setUnsavedChanges(true);
                }}
              >
                {user?.projectAccess.map((project, index: number) => (
                  <MenuItem key={index} value={project.project._id}>
                    {project.project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {user?.type !== "Marketing" ? (
          <FormControlLabel
            id={"step"}
            control={
              <Checkbox
                checked={htmlTemplate?.step}
                onChange={(e) => setHtmlTemplate({ ...(htmlTemplate as ITemplate), step: !htmlTemplate?.step })}
              />
            }
            label={"Step Template"}
          />
        ) : null}
        <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <Button color="success" type="submit" variant="contained" onClick={() => saveTemplateHandler()} sx={{ mr: 2 }}>
            {htmlTemplate?._id ? "Save" : "Create"} Template
          </Button>
          <Button sx={{ mr: 2 }} color="info" variant="contained" onClick={downloadHtmlHandler}>
            Download HTML
          </Button>
          <Button sx={{ mr: 2 }} color="primary" variant="contained" onClick={() => setShowImageDialog(true)}>
            Upload Image
          </Button>
          <Button sx={{ mr: 2 }} color="primary" variant="contained" onClick={() => setShowTestEmailDialog(true)}>
            Send Test Email
          </Button>
          <Button sx={{ mr: 2 }} color="primary" variant="contained" onClick={() => setShowAttachmentsDialog(true)}>
            Attachments
          </Button>
          {!unsavedChanges && (
            <Box sx={{ display: "flex", justifyContent: "center", ml: 2 }}>
              <Typography sx={{ mr: 1, color: "lightgreen" }}>
                <em>Saved</em>
              </Typography>
            </Box>
          )}
          {unsavedChanges && (
            <Box sx={{ display: "flex", justifyContent: "center", ml: 2 }}>
              <Typography sx={{ mr: 1, color: "red" }}>
                <em>Unsaved</em>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ border: "1px solid grey" }}>
        <EmailEditor
          ref={emailEditorRef}
          onReady={onEditorLoad}
          options={
            {
              projectId: 261602,
              editor: { autoSelectOnDrop: true },
              fonts: {
                showDefaultFonts: true,
                customFonts: customFonts,
              },
              appearance: { theme: "light", panels: { tools: { collapsible: false } } },
              tools: {
                image: { properties: { containerPadding: { value: "0px" } } },
                html: { properties: { containerPadding: { value: "0px" } } },
              },
              safeHtml: true,
              customJS: [customJS],
            } as any
          }
          style={{ overflow: "auto" }}
        />
      </Box>
    </>
  );
};

interface EditHTMLTemplateProps {
  htmlTemplate: ITemplate | null;
  htmlTemplates: ITemplate[];
  setHtmlTemplate: (newTemplate: ITemplate | null) => void;
  setHtmlTemplates: any;
  refetchHtmlTemplates: (args: any) => void;
}

const SAVEHTMLTEMPLATE = gql`
  mutation saveHtmlTemplate(
    $id: MongoID!
    $name: String
    $subject: String
    $html: String
    $htmlTemplateJson: String
    $attachments: [UpdateByIdEmailTemplateAttachmentsInput]
    $project: MongoID
    $step: Boolean
  ) {
    emailTemplateUpdateById(
      _id: $id
      record: {
        name: $name
        subject: $subject
        html: $html
        htmlTemplateJson: $htmlTemplateJson
        attachments: $attachments
        project: $project
        step: $step
      }
    ) {
      record {
        _id
        name
        subject
        html
        htmlTemplateJson
        attachments {
          _id
          getUrl
          name
        }
        project {
          _id
        }
        step
      }
    }
  }
`;
const CREATEHTMLTEMPLATE = gql`
  mutation createHtmlTemplate(
    $name: String!
    $subject: String!
    $html: String!
    $htmlTemplateJson: String
    $attachments: [EmailTemplateAttachmentsInput]
    $project: MongoID!
    $step: Boolean!
  ) {
    emailTemplateCreateOne(
      record: {
        name: $name
        subject: $subject
        html: $html
        htmlTemplateJson: $htmlTemplateJson
        attachments: $attachments
        project: $project
        step: $step
      }
    ) {
      record {
        _id
        name
        subject
        html
        htmlTemplateJson
        attachments {
          _id
          getUrl
          name
        }
        project {
          _id
        }
        step
      }
    }
  }
`;
const UPLOADATTACHMENTS = gql`
  query uploadAttachments($emailTemplateId: MongoID!, $files: [Upload]) {
    emailTemplateUploadAttachments(id: $emailTemplateId, files: $files) {
      name
    }
  }
`;

const GETFOLDERS = gql`
  query getFolders($type: String!) {
    getFolders(type: $type)
  }
`;

const DELETEATTACHMENT = gql`
  mutation deleteEmailAttachment($_id: MongoID!, $attachmentId: MongoID!, $deleteFile: Boolean!) {
    deleteEmailAttachment(_id: $_id, attachmentId: $attachmentId, deleteFile: $deleteFile) {
      name
      subject
      project {
        _id
        name
        tagLine
        developerName
      }
      step
      archived
      _id
      createdAt
      updatedAt
      html
      attachments {
        _id
        name
        getUrl
      }
    }
  }
`;

export default EditHtmlTemplate;
