import { useState, useReducer } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box, TextField, Grid, Typography, FormControlLabel, Checkbox, Button, Divider, CircularProgress } from "@mui/material";

import { IProject } from "../../../../types/project";
import { FlexBetween, FlexEnd, SettingContainer } from "../../../../commonStyles";
import { ratings, realtorTypes, sources, ethnicities } from "../../../../utils/constant";
import ReportOptions from "./ReportOptions";
import { useSelector } from "react-redux";
import { selectProjectQuestions } from "../../../../features/projectSetting/projectSettingSlice";
import { IQuestion } from "../../../../types/question";
import { GlobalModal } from "../../../../features/modal/Modal";
import { useAppDispatch } from "../../../../app/hooks";
import { handleModal } from "../../../../features/modal/modalSlice";
import StandardTable from "../../../tables/StandardTable";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../../features/snackbar/snackbarSlice";
import { downloadCustomReportPdf, downloadCustomReportExcel } from "../../../../utils/function";
import { selectUser } from "../../../../features/auth/authSlice";

const callActivities = [
  "numberOfCallsMade",
  "appointmentsBookedByTeam",
  "appointmentsBookedByRsvpLink",
  "appointmentsScheduledForPeriod",
  "appointmentsAttended",
  "numberOfSalesOfficeVisits",
  "numberOfTextsSent",
  "numberOfTextsReceived",
];

export const rowReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload];
    case "COPY":
      return [...state, action.payload];
    case "UPDATE":
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return action.payload.value.toString();
        } else return state;
      });
    case "UPDATEALL":
      return action.payload;
    case "DELETE":
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

export const tableReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload];
    case "COPY":
      return [...state, action.payload];
    case "UPDATE":
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return action.payload.value;
        } else return state;
      });
    case "UPDATEALL":
      return action.payload;
    case "DELETE":
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

const Report = ({ project }: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const projectQuestions = useSelector(selectProjectQuestions);
  const user = useSelector(selectUser);
  const [dateStart, setDateStart] = useState<any>(new Date().setHours(0, 0, 0, 0));
  const [dateEnd, setDateEnd] = useState<any>(new Date().setHours(23, 59, 59, 59));

  const [allLeads, setAllLeads] = useState<boolean>(false);
  const [newLeads, setNewLeads] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [realtorTypesCheck, setRealtorTypesCheck] = useState<string[]>([]);
  const [ratingsCheck, setRatingsCheck] = useState<string[]>([]);
  const [sourcesCheck, setSourcesCheck] = useState<string[]>([]);
  const [ethnicitiesCheck, setEthnicitiesCheck] = useState<string[]>([]);
  const [callActivityCheck, setCallActivityCheck] = useState<string[]>([]);
  const [questionsCheck, setQuestionsCheck] = useState<string[]>([]);
  const [salesQuestionsCheck, setSalesQuestionsCheck] = useState<string[]>([]);
  const [headerValues, setHeaderValues] = useState<string>("");
  const [rowsState, rowsDispatch] = useReducer(rowReducer, []);
  const [tablesState, tablesDispatch] = useReducer(tableReducer, []);
  const [tableIndex, setTableIndex] = useState<number | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);

  const [getRegistrants] = useLazyQuery(GETREGISTRANTS, {
    onCompleted: (data) => {},
    onError: (e) => console.log(e),
  });

  const [getCameIns] = useLazyQuery(GETREGISTRANTS, {
    onCompleted: (data) => {},
    onError: (e) => console.log(e),
  });

  const [getCallRailStats] = useLazyQuery(GETCALLRAILSTATS, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getTextMessages] = useLazyQuery(GETTEXTMESSAGES, {
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getAppointments] = useLazyQuery(GETAPPOINTMENTS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {},
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const handleRealtorTypes = (type: string) => {
    if (type === "all") {
      if (realtorTypes.every((v) => realtorTypesCheck.includes(v))) {
        setRealtorTypesCheck([]);
      } else {
        setRealtorTypesCheck(realtorTypes);
      }
    } else {
      if (realtorTypesCheck.includes(type)) {
        setRealtorTypesCheck(realtorTypesCheck.filter((realtorType: string) => type !== realtorType));
      } else {
        setRealtorTypesCheck([...realtorTypesCheck, type]);
      }
    }
  };

  const handleRating = (type: string) => {
    if (type === "all") {
      if (ratings.every((v) => ratingsCheck.includes(v))) {
        setRatingsCheck([]);
      } else {
        setRatingsCheck(ratings);
      }
    } else {
      if (ratingsCheck.includes(type)) {
        setRatingsCheck(ratingsCheck.filter((rating: string) => type !== rating));
      } else {
        setRatingsCheck([...ratingsCheck, type]);
      }
    }
  };

  const handleSource = (type: string) => {
    if (type === "all") {
      if (sources.every((v) => sourcesCheck.includes(v))) {
        setSourcesCheck([]);
      } else {
        setSourcesCheck(sources);
      }
    } else {
      if (sourcesCheck.includes(type)) {
        setSourcesCheck(sourcesCheck.filter((source: string) => type !== source));
      } else {
        setSourcesCheck([...sourcesCheck, type]);
      }
    }
  };

  const handleEthnicity = (type: string) => {
    if (type === "all") {
      if (ethnicities.every((v) => ethnicitiesCheck.includes(v))) {
        setEthnicitiesCheck([]);
      } else {
        setEthnicitiesCheck(ethnicities);
      }
    } else {
      if (ethnicitiesCheck.includes(type)) {
        setEthnicitiesCheck(ethnicitiesCheck.filter((ethnicity: string) => type !== ethnicity));
      } else {
        setEthnicitiesCheck([...ethnicitiesCheck, type]);
      }
    }
  };

  const handleCallActivity = (type: string) => {
    if (type === "all") {
      if (callActivities.every((v) => callActivityCheck.includes(v))) {
        setCallActivityCheck([]);
      } else {
        setCallActivityCheck(callActivities);
      }
    } else {
      if (callActivityCheck.includes(type)) {
        setCallActivityCheck(callActivityCheck.filter((source: string) => type !== source));
      } else {
        setCallActivityCheck([...callActivityCheck, type]);
      }
    }
  };

  const handleQuestions = (type: string, userType: string) => {
    if (userType === "registrant") {
      if (questionsCheck.includes(type)) {
        setQuestionsCheck(questionsCheck.filter((question: string) => type !== question));
      } else {
        setQuestionsCheck([...questionsCheck, type]);
      }
    } else {
      if (salesQuestionsCheck.includes(type)) {
        setSalesQuestionsCheck(salesQuestionsCheck.filter((question: string) => type !== question));
      } else {
        setSalesQuestionsCheck([...salesQuestionsCheck, type]);
      }
    }
  };

  const handleTable = (index: number | null) => {
    setTableIndex(index);
    storeDispatch(handleModal(true));
  };

  const handleValues = (value: string, type: string, index: number) => {
    if (type === "header") {
      setHeaderValues(value);
    } else if (type === "row") {
      rowsDispatch({
        type: "UPDATE",
        payload: { index, value },
      });
    }
  };

  const editTable = (table: any, index: number) => {
    setHeaderValues(table.header);
    rowsDispatch({
      type: "UPDATEALL",
      payload: table.rows,
    });
    setTableIndex(index);
    storeDispatch(handleModal(true));
  };

  const deleteTable = (table: any, index: number) => {
    tablesDispatch({
      type: "DELETE",
      payload: {
        index: index,
      },
    });
  };

  const submitTable = (e: any) => {
    e.preventDefault();
    if (!rowsState.length) return storeDispatch(showErrorSnackbar("No row values"));
    if (!headerValues) return storeDispatch(showErrorSnackbar("No header values"));
    if (new Set(headerValues.split(",")).size !== headerValues.split(",").length)
      return storeDispatch(showErrorSnackbar("Duplicate Headers"));
    if (!headerValues.split(",").every((header: string) => header)) return storeDispatch(showErrorSnackbar("Missing Header Values"));
    let validation = rowsState.every((row: string) => {
      let headers = headerValues.split(",").length;
      if (row.split(",").length === headers) {
        return true;
      } else return false;
    });
    if (validation) {
      if (tableIndex !== null) {
        tablesDispatch({
          type: "UPDATE",
          payload: {
            index: tableIndex,
            value: [
              {
                header: headerValues,
                rows: rowsState,
              },
            ],
          },
        });
      } else {
        tablesDispatch({
          type: "ADD",
          payload: [
            {
              header: headerValues,
              rows: rowsState,
            },
          ],
        });
        storeDispatch(showSuccessSnackbar("Custom Table Added!"));
      }
    } else return storeDispatch(showErrorSnackbar("Row Value Counts does not match Header Value Counts."));

    rowsDispatch({
      type: "UPDATEALL",
      payload: [],
    });
    setHeaderValues("");
    setTableIndex(null);
    storeDispatch(handleModal(false));
  };

  const customColumns = (index: number) => {
    let table = tablesState[index].map((table: any) => {
      let headers = table.header.split(",");

      return headers.map((header: string, numIndex: number) => {
        return [
          {
            Header: header,
            accessor: (rowData: any) => {
              return rowData[numIndex].index;
            },
          },
        ];
      });
    });

    return table[0].flat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const generateReport = async (type: string) => {
    if (!dateStart) return storeDispatch(showErrorSnackbar("Date Start is required"));
    if (!dateEnd) return storeDispatch(showErrorSnackbar("Date End is required"));
    setDownloading(true);
    let data = await getRegistrants({
      variables: {
        filter: {
          allRegistrantByDate: [new Date(dateStart), new Date(dateEnd)],
          project: project?._id,
        },
      },
    });

    let callActivity: any = {};

    if (callActivityCheck.includes("numberOfCallsMade")) {
      let data = await getCallRailStats({
        variables: {
          user: user?._id,
          project: project?._id,
          dateStart,
          dateEnd,
          userFilter: "All",
        },
      });

      if (data) {
        let total = data.data.callRailStats.projects.find((call: any) => call.name === "Total");
        callActivity.numberOfCallsMade = total.outboundTotal;
      }
    }

    if (callActivityCheck.includes("numberOfTextsSent")) {
      let data = await getTextMessages({
        variables: {
          filter: {
            project: project?._id,
            dateGreaterThanEqual: new Date(dateStart),
            dateLessThanEqual: new Date(dateEnd),
          },
        },
      });

      if (data) {
        callActivity.numberOfTextsSent = data.data.textMessageMany.reduce(
          (acc: any, cur: any) => (cur.type === "outgoing" ? ++acc : acc),
          0
        );
        callActivity.numberOfTextsReceived = data.data.textMessageMany.reduce(
          (acc: any, cur: any) => (cur.type === "inbound" ? ++acc : acc),
          0
        );
      }
    }

    if (callActivityCheck.includes("numberOfSalesOfficeVisits")) {
      let data = await getCameIns({
        variables: {
          filter: {
            allCameIns: [new Date(dateStart), new Date(dateEnd)],
            project: project?._id,
          },
        },
      });

      if (data) {
        callActivity.numberOfSalesOfficeVisits = data.data.registrantMany.length;
      }
    }

    if (callActivityCheck.includes("appointmentsBookedByTeam") || callActivityCheck.includes("appointmentsBookedByRsvpLink")) {
      let data = await getAppointments({
        variables: {
          filter: {
            project: project?._id,
            createdAtDates: [new Date(dateStart), new Date(dateEnd)],
          },
        },
      });

      if (data) {
        if (callActivityCheck.includes("appointmentsBookedByTeam")) {
          callActivity.appointmentsBookedByTeam = data.data.appointmentMany.reduce(
            (acc: any, cur: any) => (cur.user !== null ? ++acc : acc),
            0
          );
        }
        if (callActivityCheck.includes("appointmentsBookedByRsvpLink")) {
          callActivity.appointmentsBookedByRsvpLink = data.data.appointmentMany.reduce(
            (acc: any, cur: any) => (cur.user === null ? ++acc : acc),
            0
          );
        }
      }
    }

    if (callActivityCheck.includes("appointmentsScheduledForPeriod") || callActivityCheck.includes("appointmentsAttended")) {
      let data = await getAppointments({
        variables: {
          filter: {
            project: project?._id,
            dateGreaterThanEqual: new Date(dateStart!),
            dateLessThanEqual: new Date(dateEnd!),
          },
        },
      });

      if (data) {
        if (callActivityCheck.includes("appointmentsScheduledForPeriod")) {
          callActivity.appointmentsScheduledForPeriod = data.data.appointmentMany.length;
        }
        if (callActivityCheck.includes("appointmentsAttended")) {
          callActivity.appointmentsAttended = data.data.appointmentMany.reduce(
            (acc: any, cur: any) => (cur.cameIn === true ? ++acc : acc),
            0
          );
        }
      }
    }

    if (data && data.data) {
      if (type === 'pdf') {
        downloadCustomReportPdf(
          data.data.registrantMany,
          project!,
          new Date(dateStart),
          new Date(dateEnd),
          allLeads,
          newLeads,
          connected,
          realtorTypesCheck,
          ratingsCheck,
          sourcesCheck,
          ethnicitiesCheck,
          callActivity,
          questionsCheck,
          salesQuestionsCheck,
          projectQuestions,
          tablesState
        );
      } else {
        downloadCustomReportExcel(
          data.data.registrantMany,
          project!,
          new Date(dateStart),
          new Date(dateEnd),
          allLeads,
          newLeads,
          connected,
          realtorTypesCheck,
          ratingsCheck,
          sourcesCheck,
          ethnicitiesCheck,
          callActivity,
          questionsCheck,
          salesQuestionsCheck,
          projectQuestions,
          tablesState
        );
      }
    }
    setDownloading(false);
  };

  return (
    <SettingContainer>
      <GlobalModal>
        <form onSubmit={submitTable}>
          <Typography variant="h2">
            <strong>Custom</strong>
          </Typography>
          <Box sx={{ mt: 2 }}>
            <TextField
              title={"Header Values"}
              name={"header"}
              fullWidth
              value={headerValues}
              label={"Header Values (Ex. 1,2,3,4,5)"}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValues(e.target.value, "header", 0)}
            />
          </Box>
          {rowsState.map((row: string[], index: number) => {
            return (
              <Box sx={{ mt: 2 }} key={index}>
                <TextField
                  title={`Row ${index + 1} Values`}
                  name={"row"}
                  fullWidth
                  value={row}
                  label={`Row ${index + 1} Values (Ex. 12,34, ,63,88)`}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValues(e.target.value, "row", index)}
                />
              </Box>
            );
          })}
          <FlexEnd sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                rowsDispatch({
                  type: "ADD",
                  payload: [],
                })
              }
            >
              Add Row
            </Button>
            <Button sx={{ ml: 1 }} variant="contained" color="success" type="submit">
              {tableIndex !== null ? "Edit Table" : "Create Table"}
            </Button>
          </FlexEnd>
        </form>
      </GlobalModal>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={"Start Date (YYYY/MM/DD)"}
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={"End Date (YYYY/MM/DD)"}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      {downloading ? (
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
            <CircularProgress sx={{ m: "0 auto" }} />
            <Box sx={{ mt: 2 }}>Generating Report...</Box>
          </Box>
        </Box>
      ) : (
        <Container>
          <FlexBetween>
            <Typography variant="h2">
              <strong>Custom Report Generator</strong>
            </Typography>
            <Box>
              <Button variant="contained" color="primary" onClick={() => handleTable(null)}>
                Add Custom Table
              </Button>
            </Box>
          </FlexBetween>
          <Box>
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={<Checkbox checked={allLeads} onChange={() => setAllLeads(!allLeads)} />}
              label={"All Leads"}
              labelPlacement="start"
              sx={{ ml: 0 }}
            />
          </Box>
          <Box>
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={<Checkbox checked={newLeads} onChange={() => setNewLeads(!newLeads)} />}
              label={"New Leads"}
              labelPlacement="start"
              sx={{ ml: 0 }}
            />
          </Box>
          <Box>
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={<Checkbox checked={connected} onChange={() => setConnected(!connected)} />}
              label={"Connected"}
              labelPlacement="start"
              sx={{ ml: 0 }}
            />
          </Box>
          <Divider />
          <ReportOptions
            title="Realtor Types (Check All)"
            data={realtorTypesCheck}
            constants={realtorTypes}
            handleCheckbox={handleRealtorTypes}
          />
          <Divider />
          <ReportOptions title="Ratings (Check All)" data={ratingsCheck} constants={ratings} handleCheckbox={handleRating} />
          <Divider />
          <ReportOptions title="Sources (Check All)" data={sourcesCheck} constants={sources} handleCheckbox={handleSource} />
          <Divider />
          <ReportOptions title="Ethnicity (Check All)" data={ethnicitiesCheck} constants={ethnicities} handleCheckbox={handleEthnicity} />
          <Divider />
          <ReportOptions
            title="Call Activity (Check All)"
            data={callActivityCheck}
            constants={callActivities}
            handleCheckbox={handleCallActivity}
          />
          <Divider />
          <Typography sx={{ mt: 1 }} variant="h2">
            <strong>Registrant Questions</strong>
          </Typography>
          <Grid container spacing={2}>
            {projectQuestions.map((question: IQuestion, index: number) => {
              return (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <FormControlLabel
                    onClick={(e) => e.stopPropagation()}
                    control={
                      <Checkbox
                        checked={questionsCheck.includes(question.question)}
                        onChange={() => handleQuestions(question.question, "registrant")}
                      />
                    }
                    label={question.question}
                    labelPlacement="start"
                    sx={{ ml: 0 }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Typography sx={{ mt: 1 }} variant="h2">
            <strong>Sales Questions</strong>
          </Typography>
          <Grid container spacing={2}>
            {projectQuestions.map((question: IQuestion, index: number) => {
              return (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <FormControlLabel
                    onClick={(e) => e.stopPropagation()}
                    control={
                      <Checkbox
                        checked={salesQuestionsCheck.includes(question.question)}
                        onChange={() => handleQuestions(question.question, "sales")}
                      />
                    }
                    label={question.question}
                    labelPlacement="start"
                    sx={{ ml: 0 }}
                  />
                </Grid>
              );
            })}
          </Grid>
          {tablesState.map((tables: any, index: number) => {
            return (
              <Box sx={{ mb: 2 }} key={index}>
                <StandardTable
                  columns={customColumns(index)}
                  title={`Custom Table ${index + 1}`}
                  data={tables[0].rows.map((rowValue: string) =>
                    rowValue.split(",").map((value: string, index: number) => {
                      return {
                        index: value,
                      };
                    })
                  )}
                />
                <FlexEnd sx={{ my: 2 }}>
                  <Button sx={{ mr: 1 }} variant="contained" color="error" onClick={() => deleteTable(tables[0], index)}>
                    Delete Table
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => editTable(tables[0], index)}>
                    Edit Table
                  </Button>
                </FlexEnd>
                <Divider />
              </Box>
            );
          })}
          <FlexEnd>
            <Button sx={{ mr: 1 }} variant="contained" color="success" onClick={() => generateReport('pdf')}>
              Generate Report Pdf
            </Button>
            <Button sx={{ mr: 1 }} variant="contained" color="success" onClick={() => generateReport('excel')}>
              Generate Report Excel
            </Button>
          </FlexEnd>
        </Container>
      )}
    </SettingContainer>
  );
};

const Container = styled(Box)`
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
`;

interface ChildProps {
  project?: IProject;
}

const GETREGISTRANTS = gql`
  query registrantMany($filter: FilterFindManyRegistrantInput) {
    registrantMany(filter: $filter, limit: 1000000) {
      rating
      realtorType
      source
      ethnicity
      connections {
        date
        user {
          _id
          fullName
        }
      }
      createdAt
      salesQuestions {
        questionId {
          name
          question
          choices {
            choice
            followUp {
              _id
            }
          }
        }
        answer
      }
      questions {
        questionId {
          name
          question
          choices {
            choice
            followUp {
              _id
            }
          }
        }
        answer
      }
      statuses {
        name
        createdAt
      }
    }
  }
`;

const GETCALLRAILSTATS = gql`
  query callRailStats($user: MongoID, $project: MongoID, $dateStart: Date!, $dateEnd: Date!, $userFilter: String) {
    callRailStats(user: $user, project: $project, dateStart: $dateStart, dateEnd: $dateEnd, userFilter: $userFilter) {
      projects {
        id
        name
        outboundTotal
      }
    }
  }
`;

const GETTEXTMESSAGES = gql`
  query textMessageMany($filter: FilterFindManyTextMessageInput) {
    textMessageMany(filter: $filter, limit: 100000) {
      _id
      type
    }
  }
`;

const GETAPPOINTMENTS = gql`
  query appointmentMany($filter: FilterFindManyAppointmentInput) {
    appointmentMany(filter: $filter, limit: 100000) {
      _id
      purchaserInfo {
        email
      }
      schedule {
        _id
      }
      date
      user {
        _id
      }
      cameIn
      registrant {
        email
        firstName
        lastName
        primaryPhone
        email
        statuses {
          name
          createdAt
        }
      }
      createdAt
    }
  }
`;

export default Report;
