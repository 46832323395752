import React, { useCallback, useEffect, useRef } from "react";
import { SettingContainer } from "../../commonStyles";
import { Autocomplete, TextField, Typography, Grid, Button, Box } from "@mui/material";
import StandardTable from "../tables/StandardTable";
import { useState } from "react";
import { useProjectsQuery } from "../../features/project/projectHooks";
import { useAppSelector } from "../../app/hooks";
import { IAppointment, IProject } from "../../types/project";
import { useUsersQuery, useUsersCountQuery } from "../../features/user/userHooks";
import { selectUsers } from "../../features/user/userSlice";
import { selectProject, selectProjects } from "../../features/project/projectSlice";
import { gql, useLazyQuery } from "@apollo/client";
import EditAnAppointmentDialog from "../registrant/process/EditAnAppointmentDialog";
import { IUser } from "../../types/user";
import { normalToCamel, downloadPdf, downloadExcel, convertAllDates, timeZoneDate, camelToNormal } from "../../utils/function";

import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { selectUser } from "../../features/auth/authSlice";

const Appointments = () => {
  const curProject = useAppSelector(selectProject);
  const projects = useAppSelector(selectProjects);
  const user = useAppSelector(selectUser);
  const users = useAppSelector(selectUsers);

  const [formUser, setFormUser] = useState<string>("Any");
  const [formProject, setFormProject] = useState<string>("Any");
  const [projectObj, setProjectObj] = useState<IProject | undefined>(undefined);
  const [userObj, setUserObj] = useState<IUser | undefined>(undefined);
  const [usersCount, setUsersCount] = useState<number>();
  const [appointments, setAppointments] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [appointmentsCount, setAppointmentsCount] = useState<number>(0);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [original, setOriginal] = useState<IAppointment>();
  const [startDate, setStartDate] = useState<Date | null | undefined | number>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [endDate, setEndDate] = useState<Date | null | undefined>(new Date(new Date().setHours(23, 59, 59, 59)));
  const [dateQuickFilter, setDateQuickFilter] = useState<string>("None");
  const [searchFilter, setSearchFilter] = useState<string | undefined>("");
  const [searchUserFilter, setSearchUserFilter] = useState<(string | undefined)[] | undefined>(undefined);
  const [initialEdit, setInitialEdit] = useState<boolean>(true);

  const searchRef = useRef<any>(null);

  let projectOptions = ["Any"];
  projectOptions = projectOptions.concat(projects.map((project) => project.name));
  let userOptions = ["Any"];
  userOptions = userOptions.concat(
    users
      .filter((user) => {
        if (formProject === "Any") return user.projectAccess.length > 0;
        for (let projectAccess of user.projectAccess) {
          if (projectAccess.project.name === formProject) {
            return true;
          }
        }
        return false;
      })
      .map((user) => `${user.firstName} ${user.lastName} (${user.type})`)
  );
  const dateQuickFilterOptions = ["None", "Today", "Last Weekend", "This Weekend", "Last 7 Days", "Next 7 Days", "This Week", "This Month"];

  const { loading: projectsLoading } = useProjectsQuery(
    user!.projectAccess!.map((access: any) => access.project._id),
    () => {
      if (curProject) {
        setFormProject(curProject.name);
        setProjectObj(curProject);
      } else {
        setFormProject("Any");
        setProjectObj(undefined);
      }
    }
  );

  const { loading: usersCountLoading } = useUsersCountQuery("Manager", "Sales", (data) => setUsersCount(data.userCount));
  const { loading: usersLoading } = useUsersQuery(
    1,
    usersCount,
    () => {
      setFormUser("Any");
      setUserObj(undefined);
    },
    "Manager",
    "Sales"
  );

  const getAppointmentsOnCompleted = useCallback((data: any) => {
    const appointmentsCopy = data.appointmentPagination.items.slice();
    appointmentsCopy.sort((appointment1: IAppointment, appointment2: IAppointment) => {
      const appointment1Date = new Date(appointment1.date);
      const appointment2Date = new Date(appointment2.date);
      return appointment1Date.getTime() - appointment2Date.getTime();
    });
    setAppointments(appointmentsCopy);
    setAppointmentsCount(data.appointmentPagination.count);
  }, []);

  const [getAppointments, { loading: appointmentsLoading, refetch: getAppointmentsRefetch }] = useLazyQuery(GETAPPOINTMENTS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      salesRepId: userObj?._id,
      projects: projectObj ? [projectObj?._id] : user?.projectAccess.map((project: any) => project.project._id),
      startDate: startDate,
      endDate: endDate,
      perPage: 15,
      page: pageNumber + 1,
      search: searchUserFilter && [searchFilter].concat(searchUserFilter),
    },
    onCompleted: (data) => {
      getAppointmentsOnCompleted(data);
    },
    onError: (err) => {
      console.log(err.message);
    },
  });

  const changeUserObj = (newName: string) => {
    const split = newName.split(" ");
    const formUserFirstName = split[0];
    const formUserLastName = split[1];
    const userObj = users.find((user) => user.firstName === formUserFirstName && user.lastName === formUserLastName);
    setUserObj(userObj);
  };

  const changeProjectObj = (newName: string) => {
    const projectObj = projects.find((project) => project.name === newName);
    setProjectObj(projectObj);
  };

  const appointmentsCols = React.useMemo(() => {
    const editAppointment = (original: any) => {
      setOriginal(original);
      setEditDialogOpen(true);
      setInitialEdit(false);
    };

    return [
      {
        Header: "Project",
        accessor: "project",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => {
                editAppointment(original);
              }}
            >
              {value.name}
            </Typography>
          );
        },
      },
      {
        Header: "User",
        accessor: "user",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          if (!value) return <Typography sx={{ cursor: "pointer" }}>None</Typography>;
          return (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => {
                editAppointment(original);
              }}
            >
              {value.firstName} {value.lastName}
            </Typography>
          );
        },
      },
      {
        Header: "Specialist",
        accessor: "salesRep",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          if (!value) return <Typography sx={{ cursor: "pointer" }}>None</Typography>;
          return (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => {
                editAppointment(original);
              }}
            >
              {value.firstName} {value.lastName}
            </Typography>
          );
        },
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => {
                editAppointment(original);
              }}
            >
              <Box>{convertAllDates(timeZoneDate(value), "PPpp")}</Box>
            </Typography>
          );
        },
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => {
                editAppointment(original);
              }}
            >
              {value}
            </Typography>
          );
        },
      },
      {
        Header: "Client",
        accessor: "purchaserInfo",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => {
                editAppointment(original);
              }}
            >
              {value.firstName} {value.lastName} ({value.email})
            </Typography>
          );
        },
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return (
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => {
                editAppointment(original);
              }}
            >
              {camelToNormal(value)}
            </Typography>
          );
        },
      },
    ];
  }, []);

  const handleGlobalFilterValue = (value: string) => {
    setSearchFilter(value);

    const valueRegex = new RegExp(value, "i");
    const filteredUsers = users
      .filter((user) => {
        return (
          user.firstName.match(valueRegex) || user.lastName.match(valueRegex) || `${user.firstName} ${user.lastName}`.match(valueRegex)
        );
      })
      .map((user) => user._id);
    setSearchUserFilter(filteredUsers);
  };

  const download = (type: string) => {
    let headers = appointmentsCols
      .filter((column: any) => typeof column.Header === "string")
      .map((column: any) => {
        return {
          label: column.Header,
          id: normalToCamel(column.Header),
        };
      });

    let data = appointments.map((appointment) => {
      return {
        project: appointment.project.name,
        user: appointment.user ? `${appointment.user.firstName} ${appointment.user.lastName}` : "None",
        specialist: appointment.salesRep ? `${appointment.salesRep.firstName} ${appointment.salesRep.lastName}` : "None",
        date: `${convertAllDates(timeZoneDate(new Date(appointment.date)), "PP")}`,
        location: appointment.location,
        client: `${appointment.purchaserInfo.firstName} ${appointment.purchaserInfo.lastName}`,
        status: appointment.status,
        type: appointment.type,
      };
    });

    let columnWidths = appointments.map(() => {
      if (type === "excel") {
        return {
          project: 15,
          user: 15,
          specialist: 15,
          date: 15,
          location: 30,
          client: 15,
          status: 15,
          type: 15,
        };
      } else
        return {
          project: 200,
          user: 200,
          specialist: 200,
          date: 200,
          location: 400,
          client: 200,
          status: 200,
          type: 200,
        };
    });

    let sheetTitle = ["Appointments"];

    if (type === "excel") {
      downloadExcel([data], [headers], [], [columnWidths], sheetTitle, "Appointments");
    } else {
      downloadPdf([data], [headers], [], columnWidths, sheetTitle, "Appointments");
    }
  };

  useEffect(() => {
    const get = async () => {
      if (!initialEdit && editDialogOpen === false) {
        const res = await getAppointmentsRefetch({
          salesRepId: userObj?._id,
          projects: projectObj ? [projectObj?._id] : user?.projectAccess.map((project: any) => project.project._id),
          startDate: startDate,
          endDate: endDate,
          perPage: 15,
          page: pageNumber + 1,
          search: searchUserFilter && [searchFilter].concat(searchUserFilter),
        });
        getAppointmentsOnCompleted(res.data);
      }
    };
    get();
  }, [
    getAppointmentsRefetch,
    editDialogOpen,
    initialEdit,
    getAppointmentsOnCompleted,
    endDate,
    pageNumber,
    projectObj?._id,
    searchFilter,
    searchUserFilter,
    startDate,
    userObj?._id,
  ]);

  useEffect(() => {
    const tryToLoad = () => {
      if (!searchRef.current) return false;

      const keyWithOnClick = Object.keys(searchRef.current).find((key) => key.match(new RegExp("__reactProps")));
      if (keyWithOnClick) {
        searchRef.current[keyWithOnClick].onClick(null);
        return true;
      }
      return false;
    };

    const tryToLoadPolling = () => {
      setTimeout(() => {
        const didLoad = tryToLoad();
        if (!didLoad) {
          tryToLoadPolling();
        }
      }, 200);
    };

    tryToLoadPolling();
    getAppointments();
  }, [getAppointments]);

  useEffect(() => {
    setPageNumber(0);
  }, [formUser, formProject, startDate, endDate, searchFilter, searchUserFilter]);

  if (projectsLoading || usersCountLoading || usersLoading) return <>Loading</>;

  return (
    <SettingContainer>
      {editDialogOpen && <EditAnAppointmentDialog open={editDialogOpen} setOpen={setEditDialogOpen} appointment={original} />}
      <Typography sx={{ mb: 2 }} variant="h2">
        <strong>Search</strong>
      </Typography>
      <Box sx={{ mb: 2 }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={projectOptions}
                value={formProject}
                renderInput={(params) => {
                  return <TextField label="Project" {...params}></TextField>;
                }}
                onChange={(e) => {
                  setFormProject((e.target as HTMLInputElement).innerText);
                  changeProjectObj((e.target as HTMLInputElement).innerText);
                }}
              />
            </Grid>
            {formProject && (
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  options={userOptions}
                  value={formUser}
                  renderInput={(params) => {
                    return <TextField label="Specialist" {...params}></TextField>;
                  }}
                  onChange={(e) => {
                    setFormUser((e.target as HTMLInputElement).innerText);
                    changeUserObj((e.target as HTMLInputElement).innerText);
                  }}
                />
              </Grid>
            )}
            <Box sx={{ width: "100%" }}></Box>
            <Grid item xs={12} sm={5}>
              <Autocomplete
                options={dateQuickFilterOptions}
                value={dateQuickFilter}
                renderInput={(params) => {
                  return <TextField label="Date Quick Filter" {...params}></TextField>;
                }}
                onChange={(e) => {
                  const option = (e.target as HTMLInputElement).innerText;
                  setDateQuickFilter(option);
                  const today = new Date();
                  let newStartDate, newEndDate;
                  if (option === "Today") {
                    newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                    newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                  } else if (option === "Last Weekend") {
                    newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 2);
                    newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1);
                  } else if (option === "This Weekend") {
                    newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7 - today.getDay() - 2);
                    newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7 - today.getDay() + 1);
                  } else if (option === "Last 7 Days") {
                    newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
                    newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                  } else if (option === "Next 7 Days") {
                    newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                    newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
                  } else if (option === "This Week") {
                    newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
                    newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7 - today.getDay());
                  } else if (option === "This Month") {
                    newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
                    newEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
                  }
                  setStartDate(newStartDate);
                  setEndDate(newEndDate);
                }}
              />
            </Grid>
            <Box sx={{ width: "100%" }} />
            <Grid item xs={12} sm={2.5}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newDate) => setStartDate(newDate)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <FilterAltOffIcon sx={{ ml: 1 }} onClick={() => setStartDate(undefined)} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newDate) => setEndDate(newDate)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <FilterAltOffIcon sx={{ ml: 1 }} onClick={() => setEndDate(undefined)} />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Typography sx={{ mb: 2 }}>Number of Appointments: {appointmentsCount}</Typography>
      <StandardTable
        data={appointments}
        loading={appointmentsLoading}
        columns={appointmentsCols}
        handleGlobalFilterValue={handleGlobalFilterValue}
        count={appointmentsCount}
        download={download}
      />
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Button
          disabled={pageNumber === 0}
          onClick={() => {
            setPageNumber((pageNumber) => pageNumber - 1);
          }}
        >
          {"<"}
        </Button>
        <span>{pageNumber + 1}</span>
        <Button
          disabled={pageNumber + 1 === Math.ceil(appointmentsCount / 15) || !appointmentsCount}
          onClick={() => {
            setPageNumber((pageNumber) => pageNumber + 1);
          }}
        >
          {">"}
        </Button>
      </Box>
    </SettingContainer>
  );
};

const GETAPPOINTMENTS = gql`
  query getFilteredAppointments(
    $page: Int
    $perPage: Int
    $salesRepId: MongoID
    $projects: [MongoID!]
    $startDate: String
    $endDate: String
    $search: [String]
  ) {
    appointmentPagination(
      page: $page
      perPage: $perPage
      filter: {
        salesRep: $salesRepId
        projects: $projects
        dateGreaterThanEqual: $startDate
        dateLessThanEqual: $endDate
        search: $search
      }
    ) {
      count
      items {
        _id
        project {
          name
        }
        user {
          firstName
          lastName
        }
        date
        location
        purchaserInfo {
          firstName
          lastName
          email
          primaryPhone
          numberOfGuests
        }
        status
        type
        confirmed
        cancelled
        salesRep {
          _id
          firstName
          lastName
          fullName
          email
        }
        virtual
      }
    }
  }
`;

export default Appointments;
