import {
  Box,
  Button,
  Card,
  CardActions,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Typography,
  TextField,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
  Select,
  CircularProgress,
  MenuItem,
  Tooltip,
  Paper,
} from "@mui/material";
import { ArrowBack, AddCircleOutline, Edit, FileCopy, DeleteForever, Close } from "@mui/icons-material";
import { ITemplate } from "./EmailTemplates";
import { useEffect, useState } from "react";
import EditHtmlTemplate from "./EditHtmlTemplates";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useAppDispatch } from "../../../app/hooks";
import { showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";

const HtmlTemplates = ({ viewHtmlTemplates, setViewHtmlTemplates }: HtmlTemplatesProps) => {
  const [htmlTemplate, setHtmlTemplate] = useState<ITemplate | null>(null);
  const [htmlTemplates, setHtmlTemplates] = useState<ITemplate[]>([]);
  const [filterName, setFilterName] = useState<string>("");
  const [filterProject, setFilterProject] = useState("All");
  const [deleteTemplate, setDeleteTemplate] = useState(false);
  const [deleteTemplateId, setDeleteTemplateId] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [count, setCount] = useState<number>(0);

  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();

  const [getEmailTemplates, { refetch: refetchHtmlTemplates, loading }] = useLazyQuery(GETEMAILTEMPLATES, {
    onCompleted: (data) => {
      setHtmlTemplates(data.emailTemplatePagination.items);
      setCount(data.emailTemplatePagination.count);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [deleteHtmlTemplate] = useMutation(DELETEHTMLTEMPLATE, {
    onCompleted: (data) => {
      const deletedId = data.emailTemplateRemoveById._id;
      setHtmlTemplates((htmlTemplates) => htmlTemplates.filter((htmlTemplate) => htmlTemplate._id !== deletedId));
      storeDispatch(showSuccessSnackbar("Template deleted!"));
    },
  });

  const [createHtmlTemplate] = useMutation(CREATEHTMLTEMPLATE, {
    onCompleted: (data) => {
      setHtmlTemplates((htmlTemplates) => [data.emailTemplateCreateOne.record, ...htmlTemplates]);
      storeDispatch(showSuccessSnackbar("Html Template Successfully Duplicated!"));
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const duplicateHtmlTemplate = (htmlTemplate: ITemplate) => {
    createHtmlTemplate({
      variables: {
        name: htmlTemplate?.name + " (copy)",
        subject: htmlTemplate?.subject,
        html: htmlTemplate.html,
        htmlTemplateJson: htmlTemplate.htmlTemplateJson,
        attachments: [],
        project: htmlTemplate.project._id,
        step: false,
      },
    });
  };

  const createHtmlTemplateHandler = () => {
    setHtmlTemplate({ _id: "", name: "", subject: "", project: "", html: "<p></p>", htmlTemplateJson: "", step: false });
  };

  useEffect(() => {
    let userProjects = user?.projectAccess.map((project: any) => project.project._id);
    getEmailTemplates({
      variables: {
        filter: {
          projects: filterProject && filterProject !== "All" ? [filterProject] : userProjects,
          search: filterName,
          filterHtmlTemplates: "html",
        },
        page: pageNumber,
        perPage: 14,
        sort: "_ID_DESC",
      },
    });
  }, [getEmailTemplates, user, pageNumber, filterName, filterProject]);

  return (
    <>
      <Dialog open={deleteTemplate}>
        <DialogTitle>
          <Typography sx={{ mr: 3, fontSize: 20 }}>Are you sure you want to delete this template?</Typography>
          <IconButton aria-label="close" onClick={() => setDeleteTemplate(false)} sx={{ position: "absolute", top: 2, right: 2 }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogActions sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              deleteHtmlTemplate({ variables: { id: deleteTemplateId } });
              setDeleteTemplate(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {!htmlTemplate && (
        <Box>
          <Grid
            container
            spacing={2}
            sx={{ mb: 2 }}
            component="form"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ my: 1 }}
                label="Filter Name"
                fullWidth
                value={filterName}
                onChange={(e) => {
                  setPageNumber(1);
                  setFilterName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{ my: 1 }}>
                <InputLabel>Project</InputLabel>
                <Select
                  name={"filterProject"}
                  label={"Project"}
                  value={filterProject || "None"}
                  required
                  onChange={(e) => {
                    let selectedProject = user?.projectAccess.find((projects: any) => projects.project._id === e.target.value);
                    setFilterProject(selectedProject ? selectedProject.project._id : "All");
                  }}
                >
                  <MenuItem key={0} value="All">
                    All
                  </MenuItem>
                  {user?.projectAccess.map((project, index: number) => (
                    <MenuItem key={index + 1} value={project.project._id}>
                      {project.project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {loading && (
            <Box sx={{ textAlign: "center" }}>
              <Box>
                <CircularProgress />
              </Box>
              <Box>
                <Typography variant="body1">Loading...</Typography>
              </Box>
            </Box>
          )}
          {!loading && (
            <Box>
              <Grid container spacing={2}>
                <Grid sx={{ minHeight: "150px" }} item xs={12} sm={6} md={4}>
                  <Button
                    sx={{
                      border: "1px dotted black",
                      textTransform: "none",
                      width: "100%",
                      height: "100%",
                    }}
                    onClick={createHtmlTemplateHandler}
                  >
                    <Stack spacing={2}>
                      <Typography>New HTML Template</Typography>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <AddCircleOutline />
                      </Box>
                    </Stack>
                  </Button>
                </Grid>
                {htmlTemplates.map((htmlTemplate, index) => {
                  const templateHtml = htmlTemplate.html;

                  if (
                    !htmlTemplate?.name.toLowerCase().includes(filterName.toLowerCase()) ||
                    (filterProject !== "All" && htmlTemplate?.project._id !== filterProject)
                  )
                    return <Grid sx={{ display: "none" }} item xs={12} sm={6} md={4} key={index} />;

                  return (
                    <Grid item xs={12} sm={6} md={4} key={index} sx={{ height: "500px" }}>
                      <Paper elevation={18} sx={{ height: "100%" }}>
                        <Card sx={{ width: "100%", height: "100%", position: "relative", borderRadius: "8px" }}>
                          <iframe
                            title={`previewCard${index}`}
                            style={{ width: "100%", margin: 0, padding: 0, height: "100%", display: "block", border: "0px" }}
                            srcDoc={templateHtml}
                            loading="lazy"
                          />
                          <CardActions
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              width: "100%",
                              backgroundColor: "primary.main",
                              color: "#fff",
                              py: 1,
                              margin: 0,
                              position: "absolute",
                              bottom: 0,
                            }}
                          >
                            <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-start" }}>
                              <Typography sx={{ ml: 2 }} variant="h3">
                                {htmlTemplate.name}
                              </Typography>
                            </Box>
                            <Tooltip title="Edit Template">
                              <IconButton sx={{ color: "#fff" }} onClick={() => setHtmlTemplate(htmlTemplates[index])}>
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Copy Template">
                              <IconButton sx={{ color: "#fff" }} onClick={() => duplicateHtmlTemplate(htmlTemplate)}>
                                <FileCopy />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Template">
                              <IconButton
                                sx={{ color: "orange" }}
                                onClick={() => {
                                  setDeleteTemplate(true);
                                  setDeleteTemplateId(htmlTemplate._id);
                                }}
                              >
                                <DeleteForever />
                              </IconButton>
                            </Tooltip>
                          </CardActions>
                        </Card>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
                  {"<"}
                </Button>
                <span>{pageNumber}</span>
                <Button disabled={pageNumber === Math.ceil(count / 14)} onClick={() => setPageNumber(pageNumber + 1)}>
                  {">"}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {htmlTemplate && (
        <EditHtmlTemplate
          htmlTemplate={htmlTemplate}
          setHtmlTemplate={setHtmlTemplate}
          htmlTemplates={htmlTemplates}
          setHtmlTemplates={setHtmlTemplates}
          refetchHtmlTemplates={refetchHtmlTemplates}
        />
      )}
    </>
  );
};

interface HtmlTemplatesProps {
  viewHtmlTemplates: boolean;
  setViewHtmlTemplates: (newView: boolean) => void;
}

const GETEMAILTEMPLATES = gql`
  query emailTemplatePagination(
    $filter: FilterFindManyEmailTemplateInput
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyEmailTemplateInput
  ) {
    emailTemplatePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        name
        subject
        html
        htmlTemplateJson
        attachments {
          _id
          name
          getUrl
        }
        project {
          _id
          name
          tagLine
          developerName
        }
        createdAt
        step
      }
      count
    }
  }
`;

const CREATEHTMLTEMPLATE = gql`
  mutation createHtmlTemplate(
    $name: String!
    $subject: String!
    $html: String!
    $htmlTemplateJson: String
    $attachments: [EmailTemplateAttachmentsInput]
    $project: MongoID!
    $step: Boolean!
  ) {
    emailTemplateCreateOne(
      record: {
        name: $name
        subject: $subject
        html: $html
        htmlTemplateJson: $htmlTemplateJson
        attachments: $attachments
        project: $project
        step: $step
      }
    ) {
      record {
        name
        subject
        html
        htmlTemplateJson
        attachments {
          _id
          name
          getUrl
        }
        project {
          _id
        }
        _id
        step
      }
    }
  }
`;

const DELETEHTMLTEMPLATE = gql`
  mutation deleteHtmlTemplate($id: MongoID!) {
    emailTemplateRemoveById(_id: $id) {
      _id
    }
  }
`;

export default HtmlTemplates;
