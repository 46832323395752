import { useState, useMemo, useEffect } from "react";
import { Checkbox, Typography, Box, Button, Divider } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useMutation, useLazyQuery, gql, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import { IRegistrant } from "../../types/registrant";
import { FlexBetween, Container } from "../../commonStyles";
import StandardTable from "../tables/StandardTable";
import { IUser } from "../../types/user";
import { IEmailTemplate, IProcess, IProcessTemplate, IStep, IStepProcess, IStepTemplate } from "../../types/process";
import { IStepSchedule } from "../../types/stepSchedule";
import {
  addtoRegistrantProcess,
  selectProcess,
  setProcess,
  deleteStep,
  updateStep,
  terminateRegistrantProcess,
  selectActiveProcesses,
  setActiveProcesses,
  setRegistrant,
} from "../../features/registrants/registrantsSlice";
import { IConnection } from "../../types/registrant";
import { camelToNormal, convertAllDates } from "../../utils/function";
import { selectTemplates } from "../../features/emailTemplate/emailTemplateSlice";
import { GlobalModal } from "../../features/modal/Modal";
import { useAppDispatch } from "../../app/hooks";
import { handleModal } from "../../features/modal/modalSlice";
import { showSuccessSnackbar, showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { selectProjectProcesses } from "../../features/projectSetting/projectSettingSlice";
import { selectUser } from "../../features/auth/authSlice";
import { selectUsers, setUsers } from "../../features/user/userSlice";

import DraggableList from "../common/DraggableList";
import CreateProcessSteps from "../settings/process/CreateProcessSteps";
import HistoryProcessView from "./process/HistoryProcessView";
import EditStepView from "./process/EditStepView";
import ProcessView from "./process/ProcessView";

const Processes = (props: ChildProps) => {
  const { registrant } = props;
  const process = useSelector(selectProcess);
  const activeProcesses = useSelector(selectActiveProcesses);
  const processes = useSelector(selectProjectProcesses);
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const templates = useSelector(selectTemplates);
  const storeDispatch = useAppDispatch();

  const [selectedProcess, setSelectedProcess] = useState<IProcess | null>(null);
  const [selectedProcessTemplate, setSelectedProcessTemplate] = useState<IProcessTemplate | null>(null);
  const [selectedStep, setSelectedStep] = useState<string | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>(process?.users ? process?.users : []);
  const [modalView, setModalView] = useState<string>("");
  // Step States
  const [emailTemplate, setEmailTemplate] = useState<IEmailTemplate | null>(null);
  const [name, setName] = useState<string>("");
  const [defaultStep, setDefaultStep] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [time, setTime] = useState<string>("24");
  const [subSteps, setSubSteps] = useState<IStepTemplate[]>([]);
  const [stepIndex, setStepIndex] = useState<number | null>(null);
  // StepSchedule
  const [stepSchedules, setStepSchedules] = useState<IStepSchedule[]>([]);
  // Selected Step State
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [dateCompleted, setDateCompleted] = useState<Date | null>(null);
  const [userCompleted, setUserCompleted] = useState<IUser | null>(null);
  const [stepTime, setStepTime] = useState(""); // Time Between Step for that step

  // Queries

  useEffect(() => {
    if (process) {
      setSelectedUsers(process.users);
    }
  }, [process]);

  useQuery(GETSCHEDULEDSTEPS, {
    variables: { filter: { registrant: registrant._id, project: registrant.project._id } },
    onCompleted: (data) => {
      setStepSchedules([...data.stepScheduleMany].reverse());
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [getUsers] = useLazyQuery(GETUSERS, {
    onCompleted: (data) => {
      storeDispatch(setUsers(data.userPagination));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const [createProcess] = useMutation(CREATEPROCESS, {
    onCompleted: (data) => {
      storeDispatch(setProcess(data.createProcess));
      storeDispatch(setActiveProcesses([data.createProcess]));
      storeDispatch(addtoRegistrantProcess(data.createProcess));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Process Created!"));
      setSelectedProcess(null);
      setModalView("");
    },
  });

  const [updateProcess] = useMutation(UPDATEPROCESS, {
    onCompleted: (data) => {
      storeDispatch(setProcess(data.processUpdateById.record));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Process Updated!"));
      setSelectedProcess(null);
      setModalView("");
    },
  });

  // Rearranging, update multiple steps
  const [updateProcessSteps] = useMutation(UPDATESTEPS, {
    onCompleted: (data) => {
      storeDispatch(setProcess(data.updateSteps));
    },
  });

  // edit, update a single step
  const [updateSingleStep] = useMutation(UPDATESTEP, {
    onCompleted: (data) => {
      storeDispatch(updateStep(data.stepUpdateById.record));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Step Updated!"));
      setSelectedStep("");
      setModalView("");
    },
  });

  const [addProcessStep] = useMutation(ADDSTEP, {
    onCompleted: (data) => {
      storeDispatch(setProcess(data.addStep));
      storeDispatch(handleModal(false));
      setEmailTemplate(null);
      setName("");
      setSelectedStep("");
      setStepIndex(null);
      setDefaultStep(false);
      setType("");
      storeDispatch(showSuccessSnackbar("Step Added!"));
      setSelectedProcess(null);
      setModalView("");
    },
  });

  const [reorderSteps] = useMutation(REORDERSTEPS, {
    onCompleted: (data) => {
      storeDispatch(setProcess(data.reorderSteps));
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Steps have been reordered!"));
      setSelectedProcess(null);
      setModalView("");
    },
  });

  // Complete Step Only
  const [updateRegistrant] = useMutation(UPDATEREGISTRANT, {
    onCompleted: async (data) => {
      storeDispatch(setRegistrant(data.registrantUpdateById.record));
      storeDispatch(showSuccessSnackbar("Registrant Updated!"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.message));
    },
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Completed",
        accessor: (rowData: IStepProcess, index: number) => {
          if (index === 0) {
            return <strong>{index + 1}</strong>;
          } else {
            return (
              <Box>
                <strong>{index + 1}</strong>
                <Checkbox onChange={(e) => handleCompleteStep(rowData, rowData.process, true)} checked={rowData.completed ? true : false} />
              </Box>
            );
          }
        },
      },
      {
        Header: "Name",
        accessor: (rowData: IStepProcess, index: number) => {
          let stepSchedule: any = stepSchedules.find(
            (stepSchedule: IStepSchedule) => stepSchedule.step && stepSchedule.step._id === rowData._id
          );
          return (
            <Box sx={{ cursor: "pointer" }} onClick={() => handleEditStep(rowData, index)}>
              <Box>
                <strong>{rowData.name}</strong>
              </Box>
              {stepSchedule ? (
                <Box sx={{ fontSize: "11px" }}>
                  <span>Scheduled Email: {convertAllDates(stepSchedule.mailDate, "PPpp")}</span>
                </Box>
              ) : null}
            </Box>
          );
        },
      },
      {
        Header: "Automated",
        accessor: (rowData: IStepProcess, index: number) =>
          rowData.type === "automated" ? <CheckIcon sx={{ color: "success.main" }} /> : <CloseIcon sx={{ color: "error.main" }} />,
      },
      {
        Header: "Date Due",
        accessor: (rowData: IStepProcess) => {
          let color = "#000";
          if (
            rowData.dueDate &&
            new Date().setHours(23, 59, 59, 59).valueOf() > new Date(rowData.dueDate).valueOf() &&
            !rowData.completed
          ) {
            color = "error.main";
          }
          if (rowData.dueDate) {
            return (
              <Box sx={{ color: color }}>
                <strong>{convertAllDates(rowData.dueDate, "PP")}</strong>
              </Box>
            );
          } else return "-";
        },
      },
      {
        Header: "Email Template",
        accessor: (rowData: IStepProcess) => {
          if (rowData.emailTemplate) {
            return rowData.emailTemplate.name;
          } else return "-";
        },
      },
      {
        Header: "Completed Date",
        accessor: (rowData: IStepProcess) => {
          let color = "#000";
          if (
            rowData.dueDate &&
            new Date().setHours(23, 59, 59, 59).valueOf() > new Date(rowData.dueDate).valueOf() &&
            !rowData.completed
          ) {
            color = "error.main";
          }
          if (rowData.completed) {
            return <Box sx={{ color: "success.main" }}>{convertAllDates(rowData.completed, "PPpp")}</Box>;
          } else return <Box sx={{ color: color }}>Not Completed</Box>;
        },
      },
      {
        Header: "Completed By",
        accessor: (rowData: IStepProcess) => {
          if (rowData.user) {
            return rowData.user.fullName;
          } else return "-";
        },
      },
      // {
      //   Header: "Duplicate",
      //   accessor: (rowData: IStepProcess, index: number) => {
      //     if (index === 0 || (index !== 0 && !rowData.completed && rowData.process?.steps[index - 1].completed)) {
      //       if (rowData.subSteps.length) {
      //         return (
      //           <Button color="primary" variant="contained" onClick={() => duplicateStep(rowData, index)}>
      //             Duplicate
      //           </Button>
      //         );
      //       } else return "";
      //     } else return "";
      //   },
      // },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrant, process, stepSchedules]);

  // Functions

  // const duplicateStep = (step: any, index: number) => {
  //   addProcessStep({
  //     variables: {
  //       record: {
  //         emailTemplate: step.subSteps[0].emailTemplate ? step.subSteps[0].emailTemplate._id : null,
  //         name: step.subSteps[0].name,
  //         default: defaultStep,
  //         type: step.subSteps[0].type,
  //         project: registrant.project._id,
  //         registrant: registrant._id,
  //         subSteps: step.subSteps.map((subStep: IStepTemplate) => subStep._id),
  //       },
  //       processId: step.process?._id,
  //       time,
  //       duplicate: index,
  //     },
  //   });
  // };

  const handleTime = (e: any) => {
    if (e.target.name === "hours") {
      setStepTime(e.target.value);
    } else if (e.target.name === "days") {
      setStepTime(e.target.value ? (e.target.value * 24).toString() : "");
    }
  };

  const handleUser = (fullName: string, type: string) => {
    let user = users.find((user: IUser) => fullName === user.fullName);
    if (user) {
      if (type === "edit") {
        setUserCompleted(user);
      } else {
        setSelectedUsers([...selectedUsers, user]);
      }
    } else return storeDispatch(showErrorSnackbar("No User Found"));
  };

  const handleCompleteStep = async (data: any, process: IProcess | null = null, checkbox: boolean = false) => {
    if (data.completed && user?._id !== data.user._id) {
      if (user?.type !== "Manager") return storeDispatch(showErrorSnackbar("Cannot Uncheck."));
    }

    if (process) {
      let nextStepIndex: number = (await process.steps.findIndex((step: any) => step._id === data._id)) + 1;
      let previousStepIndex: number = (await process.steps.findIndex((step: any) => step._id === data._id)) - 1;

      if (process.steps[nextStepIndex] && process.steps[nextStepIndex].completed)
        return storeDispatch(showErrorSnackbar("Please uncheck the next step first"));

      if (process.steps[previousStepIndex] && !process.steps[previousStepIndex].completed)
        return storeDispatch(showErrorSnackbar("Previous Step is incomplete"));

      updateProcessSteps({
        variables: {
          registrantId: registrant._id,
          processId: process ? process._id : data.process,
          stepId: data._id,
          userId: user?._id,
          checkbox,
        },
      });
    }
  };

  const handleEditStep = (data: IStepProcess, index: number) => {
    if (data.completed && data.user) {
      getUsers({
        variables: {
          page: 1,
          perPage: 25,
          sort: "_ID_DESC",
          filter: { OR: [{ type: "Sales" }, { type: "Manager" }, { type: "Admin" }], locked: false },
        },
      });
    }
    storeDispatch(setProcess(data.process));
    setSelectedStep(data._id);
    setStepIndex(index);
    setName(data.name);
    setType(data.type);
    setEmailTemplate(data.emailTemplate!);
    setDueDate(data.dueDate);
    setUserCompleted(data.user);
    setDateCompleted(data.completed);
    setStepTime(data.process ? data.process?.timeBetweenSteps[index] : "");
    setSelectedProcess(data.process);
    setSubSteps(data.subSteps);
    handleProcess("editStep", null, true);
  };

  const handleProcess = async (view: string, index: number | null, modalOpen: boolean, historyProcess = null) => {
    if (index !== null) {
      storeDispatch(setProcess(activeProcesses[index]));
    }
    if (view === "newProcess" || view === "editProcess") {
      if (!historyProcess) {
        setSelectedProcess(activeProcesses.length ? activeProcesses[index!] : process);
      } else {
        setSelectedProcess(historyProcess);
      }
      await getUsers({
        variables: {
          page: 1,
          perPage: 100,
          sort: "_ID_DESC",
          filter: { OR: [{ type: "Sales" }, { type: "Manager" }, { type: "Admin" }], locked: false },
        },
      });
    }
    setModalView(view);
    if (view !== "editStep") {
      storeDispatch(handleModal(modalOpen));
    }
  };

  const handleChangeProcess = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let processUsers = selectedUsers.map((user: IUser) => user._id);
    if (modalView === "editProcess") {
      updateProcess({ variables: { _id: process?._id, record: { users: processUsers } } });
    } else if (selectedProcessTemplate) {
      // Turn current process to false and add longterm process
      createProcess({
        variables: {
          registrantId: registrant._id,
          processTemplateId: selectedProcessTemplate._id,
          terminate: process?._id,
          userIds: processUsers,
        },
      });
      if (process) {
        storeDispatch(terminateRegistrantProcess());
      }
    } else {
      updateProcess({ variables: { _id: process?._id, record: { currentProcess: false } } });
      setProcess(null);
      // Turn current process to false without adding longterm process
    }
  };

  const handleAddStepSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    addProcessStep({
      variables: {
        record: {
          emailTemplate: emailTemplate ? emailTemplate._id : null,
          name,
          default: defaultStep,
          type,
          project: registrant.project._id,
          registrant: registrant._id,
          subSteps: subSteps.map((subStep: IStepTemplate) => subStep._id),
        },
        processId: process?._id,
        time,
      },
    });
    storeDispatch(handleModal(false));
  };

  const handleSingleStep = () => {
    updateSingleStep({
      variables: {
        _id: selectedStep,
        record: {
          name,
          completed: dateCompleted,
          emailTemplate: emailTemplate ? emailTemplate._id : null,
          dueDate: dueDate,
          user: userCompleted?._id,
        },
      },
    });
  };

  const handleEmailTemplate = (emailTemplate: string) => {
    let selectedEmailTemplate = templates.find((template: IEmailTemplate) => emailTemplate === template.name);

    if (selectedEmailTemplate) {
      setEmailTemplate(selectedEmailTemplate);
    }
  };

  const handleView = () => {
    switch (modalView) {
      case "deleteProcess":
        return (
          <Box>
            <Typography variant="h2">
              <strong>Delete Process?</strong>
            </Typography>
            <p>Would you like to delete this process?</p>
            <FlexBetween>
              <Button
                color="info"
                variant="contained"
                onClick={() => {
                  setModalView("");
                  storeDispatch(handleModal(false));
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  updateProcess({ variables: { _id: process?._id, record: { currentProcess: false } } });
                  setProcess(null);
                }}
                color="error"
                variant="contained"
              >
                Delete
              </Button>
            </FlexBetween>
          </Box>
        );
      case "historyProcess":
        return <HistoryProcessView registrant={registrant} historyColumns={columns.slice(1)} handleProcess={handleProcess} />;
      case "newProcess":
      case "editProcess":
        return (
          <form onSubmit={handleChangeProcess}>
            <ProcessView
              view={modalView}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              processes={processes}
              selectedProcessTemplate={selectedProcessTemplate}
              setSelectedProcessTemplate={setSelectedProcessTemplate}
              user={user}
              userCompleted={userCompleted}
              handleUser={handleUser}
              users={users}
              handleProcess={handleProcess}
            />
          </form>
        );
      case "callPopup":
        return (
          <Box>
            <Typography variant="h2">
              <strong>Registrant Conversation</strong>
            </Typography>
            <p>
              Please wait to see if the registrant answers the call before selecting from the options below. 
            </p>
            <Box>
              <strong>For On-Site Handover Processes, please duplicate the On-Site Call Introduction step until you are able to connect with the lead before their appointment.</strong>
              <Box>The duplicated step will be due the next day. If you would like to change the due date of the duplicated step, please click on the step to edit.</Box>
            </Box>
            <FlexBetween sx={{ mt: 2 }}>
              <Box>
                <Button
                  sx={{ mr: 2 }}
                  color="info"
                  variant="contained"
                  onClick={() => {
                    setModalView("");
                    storeDispatch(handleModal(false));
                  }}
                >
                  No Answer
                </Button>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    handleCompleteStep(
                      {
                        user: {
                          _id: user?._id,
                        },
                        completed: null,
                        _id: selectedStep,
                      },
                      selectedProcess
                    );
                    setModalView("");
                    storeDispatch(handleModal(false));
                  }}
                >
                  No Answer, Complete Step
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{ mr: 2 }}
                  onClick={async () => {
                    let allConnections = registrant.connections.map((connection: IConnection) => {
                      return {
                        ...connection,
                        user: connection.user ? connection.user._id : null,
                      };
                    });
                    await updateRegistrant({
                      variables: {
                        _id: registrant._id,
                        record: {
                          connections: [
                            ...allConnections,
                            {
                              process: selectedProcess?._id,
                              date: new Date(),
                              user: user?._id,
                            },
                          ],
                        },
                      },
                    });
                    await handleCompleteStep(
                      {
                        user: {
                          _id: user?._id,
                        },
                        completed: null,
                        _id: selectedStep,
                      },
                      selectedProcess
                    );
                    setModalView("");
                    storeDispatch(handleModal(false));
                  }}
                  color="success"
                  variant="contained"
                >
                  Registrant Answered
                </Button>
                <Button
                  onClick={() => {
                    addProcessStep({
                      variables: {
                        record: {
                          emailTemplate: emailTemplate ? emailTemplate._id : null,
                          name: name,
                          default: defaultStep,
                          type: type,
                          project: registrant.project._id,
                          registrant: registrant._id,
                          subSteps: subSteps.map((subStep: IStepTemplate) => subStep._id),
                        },
                        processId: selectedProcess?._id,
                        time,
                        duplicate: stepIndex,
                      },
                    });
                  }}
                  color="error"
                  variant="contained"
                >
                  No Answer, Duplicate Step
                </Button>
              </Box>
            </FlexBetween>
          </Box>
        );
      case "addStep":
        return (
          <div>
            <p>
              <strong>
                This step will be added to the last step in the process with a due date of 2 days after the previous completed step.
              </strong>
            </p>
            <CreateProcessSteps
              stepId={""}
              handleStepSubmit={handleAddStepSubmit}
              name={name}
              setName={setName}
              emailTemplate={emailTemplate}
              setEmailTemplate={handleEmailTemplate}
              type={type}
              setType={setType}
              defaultStep={defaultStep}
              setDefaultStep={setDefaultStep}
              time={time}
              setTime={setTime}
              create={false}
              subSteps={subSteps}
              setSubSteps={setSubSteps}
            />
          </div>
        );
      case "reorderStep":
        if (process) {
          const deleteProcessStep = (step: IStep, index: number) => {
            if (step.completed) return storeDispatch(showErrorSnackbar("Cannot Delete Completed Step!"));
            storeDispatch(deleteStep({ id: step._id, index: index }));
          };

          let editableSteps = process.steps.map((step: IStep, index: number) => {
            return (
              <FlexBetween key={index}>
                <div>
                  {step.name} - {step.dueDate ? convertAllDates(step.dueDate, "PP") : "No Due Date"} -{" "}
                  {step.completed ? convertAllDates(step.completed, "PP") : "Not Completed"}
                </div>
                <div>
                  <DeleteForeverIcon onClick={() => deleteProcessStep(step, index)} sx={{ color: "error.main" }} />
                </div>
              </FlexBetween>
            );
          });

          const handleSave = (order: React.MutableRefObject<number[]>) => {
            let newTime: string[] = [];
            let newOrder = order.current.map((order: number, index: number) => {
              newTime.push(process.timeBetweenSteps[order]);
              return process.steps[order]._id;
            });

            let validateOrder = newOrder.some((id: string, index: number) => {
              let step = process.steps.find((step: IStep) => id === step._id);
              let nextStep = process.steps.find((step: IStep) => newOrder[index + 1] === step._id);

              if (step && !step?.completed && nextStep && nextStep?.completed) {
                return storeDispatch(showErrorSnackbar("Cannot Set Completed after Non Completed Step"));
              } else return false;
            });

            if (validateOrder) return;

            reorderSteps({
              variables: {
                stepIds: newOrder,
                timeBetweenSteps: newTime,
                processId: process?._id,
              },
            });
          };

          return (
            <Box>
              <Typography variant="h2" component="div" gutterBottom>
                <strong>Steps</strong>
              </Typography>
              <p>Drag the steps below to reorder. Please note that rearranging steps will reset the due dates of the step.</p>
              <DraggableList
                items={editableSteps}
                itemProps={process.steps}
                handleSave={handleSave}
                close={() => handleProcess("", null, false)}
              />
            </Box>
          );
        } else return;
      default:
        return;
    }
  };

  return (
    <Container>
      {modalView ? <GlobalModal onClose={() => setModalView("")}>{handleView()}</GlobalModal> : null}
      {activeProcesses.length && activeProcesses.some((process: IProcess) => process.currentProcess) && modalView !== "editStep" ? (
        activeProcesses
          .filter(
            (process: IProcess) =>
              process.currentProcess &&
              ((user?.type !== "Manager" && process.viewType === user?.type) || process.viewType === "All" || user?.type === "Manager")
          )
          .map((process: IProcess, index: number) => {
            return (
              <Box sx={{ mt: index > 0 ? 2 : 0 }} key={index}>
                {index > 0 ? <Divider sx={{ mb: 2, borderBottomWidth: 5 }} /> : null}
                <FlexBetween sx={{ mb: 1 }}>
                  <Typography variant="h2" component="div" gutterBottom>
                    <strong>
                      {camelToNormal(process?.processType!)} - <em>{process?.name}</em>
                    </strong>
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" } }}>
                    <Button color="primary" variant="contained" onClick={() => handleProcess("editProcess", index, true)}>
                      Edit Users in Process
                    </Button>
                  </Box>
                </FlexBetween>
                <div>
                  <StandardTable
                    data={process?.steps!.map((step: any) => {
                      return { ...step, process: process };
                    })}
                    columns={columns}
                  />
                  <FlexBetween sx={{ mt: 2, flexWrap: "wrap" }}>
                    <Box sx={{ mt: 1, display: "flex", flexDirection: { xs: "column", md: "row" } }}>
                      <Button
                        onClick={() => handleProcess("addStep", index, true)}
                        sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, width: { xs: "100%", md: "max-content" } }}
                        color="info"
                        variant="contained"
                      >
                        Add Step
                      </Button>
                      <Button
                        onClick={() => handleProcess("reorderStep", index, true)}
                        sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, width: { xs: "100%", md: "max-content" } }}
                        color="info"
                        variant="contained"
                        disabled={process && process.timeBetweenSteps.some((stepTime: any) => isNaN(stepTime))}
                      >
                        Reorder Step
                      </Button>
                      <Button
                        onClick={() => handleProcess("deleteProcess", index, true)}
                        sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, width: { xs: "100%", md: "max-content" } }}
                        color="error"
                        variant="contained"
                      >
                        Delete Process
                      </Button>
                      {registrant.process.length > 1 ? (
                        <Button
                          onClick={() => handleProcess("historyProcess", index, true)}
                          sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 }, width: { xs: "100%", md: "max-content" } }}
                          color="secondary"
                          variant="contained"
                        >
                          History ({registrant.process.length})
                        </Button>
                      ) : null}
                    </Box>
                    <Button
                      sx={{ mt: 1, height: "max-content" }}
                      onClick={() => handleProcess("newProcess", index, true)}
                      color="success"
                      variant="contained"
                    >
                      Assign New Process
                    </Button>
                  </FlexBetween>
                </div>
              </Box>
            );
          })
      ) : modalView === "editStep" ? (
        <Box>
          <EditStepView
            name={name}
            setName={setName}
            emailTemplate={emailTemplate}
            setEmailTemplate={handleEmailTemplate}
            dateCompleted={dateCompleted}
            setDateCompleted={setDateCompleted}
            dueDate={dueDate}
            setDueDate={setDueDate}
            stepTime={stepTime}
            handleTime={handleTime}
            user={user}
            userCompleted={userCompleted}
            handleUser={handleUser}
            users={users}
            handleSingleStep={handleSingleStep}
            handleCompleteStep={handleCompleteStep}
            handleProcess={handleProcess}
            stepSchedules={stepSchedules}
            setStepSchedules={setStepSchedules}
            registrant={registrant}
            step={selectedStep!}
            setModalView={setModalView}
          />
        </Box>
      ) : (
        <Box>
          There are currently no active processes. Please click{" "}
          <span onClick={() => handleProcess("newProcess", null, true)} style={{ cursor: "pointer" }}>
            <strong>here</strong>
          </span>{" "}
          to add one
          {registrant.process.length ? (
            <Box sx={{ mt: 2 }}>
              <Button onClick={() => handleProcess("historyProcess", null, true)} color="secondary" variant="contained">
                History ({registrant.process.length})
              </Button>
            </Box>
          ) : null}
        </Box>
      )}
    </Container>
  );
};

interface ChildProps {
  registrant: IRegistrant;
}

const CREATEPROCESS = gql`
  mutation createProcess($registrantId: MongoID!, $processTemplateId: MongoID!, $terminate: MongoID, $userIds: [MongoID!]) {
    createProcess(registrantId: $registrantId, processTemplateId: $processTemplateId, terminate: $terminate, userIds: $userIds) {
      _id
      currentProcess
      default
      steps {
        _id
        completed
        default
        dueDate
        type
        subSteps {
          _id
          name
          emailTemplate {
            _id
          }
          type
        }
        emailTemplate {
          name
          _id
        }
        user {
          _id
          fullName
        }
        name
      }
      timeBetweenSteps
      processType
      name
      registrant {
        _id
      }
    }
  }
`;

const UPDATEPROCESS = gql`
  mutation processUpdateById($_id: MongoID!, $record: UpdateByIdProcessInput!) {
    processUpdateById(_id: $_id, record: $record) {
      record {
        _id
        currentProcess
        default
        users {
          _id
          fullName
        }
        steps {
          _id
          completed
          default
          dueDate
          type
          emailTemplate {
            name
            _id
          }
          user {
            _id
            fullName
          }
          subSteps {
            _id
            name
            emailTemplate {
              _id
            }
            type
          }
          name
        }
        timeBetweenSteps
        processType
        viewType
        name
        registrant {
          _id
        }
      }
    }
  }
`;

const UPDATESTEP = gql`
  mutation stepUpdateById($_id: MongoID!, $record: UpdateByIdStepInput!) {
    stepUpdateById(_id: $_id, record: $record) {
      record {
        _id
        completed
        default
        dueDate
        type
        emailTemplate {
          name
          _id
        }
        user {
          _id
          fullName
        }
        name
        registrant {
          _id
        }
      }
    }
  }
`;

const UPDATESTEPS = gql`
  mutation updateSteps($registrantId: MongoID!, $processId: MongoID!, $stepId: MongoID!, $userId: MongoID!, $checkbox: Boolean!) {
    updateSteps(registrantId: $registrantId, processId: $processId, stepId: $stepId, userId: $userId, checkbox: $checkbox) {
      _id
      currentProcess
      viewType
      default
      users {
        _id
        fullName
      }
      steps {
        _id
        completed
        default
        dueDate
        type
        emailTemplate {
          name
          _id
        }
        subSteps {
          _id
          name
          emailTemplate {
            _id
          }
          type
        }
        user {
          _id
          fullName
        }
        name
      }
      timeBetweenSteps
      processType
      name
      registrant {
        _id
      }
    }
  }
`;

const ADDSTEP = gql`
  mutation addStep($record: CreateOneStepInput!, $processId: MongoID!, $time: String!, $duplicate: Int) {
    addStep(record: $record, processId: $processId, time: $time, duplicate: $duplicate) {
      _id
      currentProcess
      viewType
      default
      steps {
        _id
        completed
        default
        dueDate
        subSteps {
          _id
          name
          emailTemplate {
            _id
          }
          type
        }
        type
        emailTemplate {
          name
          _id
        }
        user {
          _id
          fullName
        }
        name
      }
      timeBetweenSteps
      processType
      name
      registrant {
        _id
      }
    }
  }
`;

const REORDERSTEPS = gql`
  mutation reordersteps($stepIds: [MongoID!], $timeBetweenSteps: [String!], $processId: MongoID!) {
    reorderSteps(stepIds: $stepIds, timeBetweenSteps: $timeBetweenSteps, processId: $processId) {
      _id
      currentProcess
      viewType
      default
      steps {
        _id
        completed
        default
        dueDate
        type
        emailTemplate {
          name
          _id
        }
        subSteps {
          _id
          name
          emailTemplate {
            _id
          }
          type
        }
        user {
          _id
          fullName
        }
        name
      }
      timeBetweenSteps
      processType
      name
      registrant {
        _id
      }
    }
  }
`;

const GETUSERS = gql`
  query userPagination($filter: FilterFindManyUserInput, $page: Int!, $perPage: Int!, $sort: SortFindManyUserInput) {
    userPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        firstName
        lastName
        fullName
      }
      count
    }
  }
`;

const GETSCHEDULEDSTEPS = gql`
  query stepScheduleMany($filter: FilterFindManyStepScheduleInput!) {
    stepScheduleMany(filter: $filter, limit: 10000) {
      _id
      mailDate
      step {
        _id
      }
    }
  }
`;

const UPDATEREGISTRANT = gql`
  mutation registrantUpdateById($_id: MongoID!, $record: UpdateByIdRegistrantInput!) {
    registrantUpdateById(_id: $_id, record: $record) {
      record {
        _id
        firstName
        lastName
        email
        primaryPhone
        postalCode
        realtorName
        realtorEmail
        brokerage
        realtorType
        relationships {
          type
          registrant {
            _id
            firstName
            lastName
            email
            relationships {
              type
              registrant {
                _id
              }
            }
            project {
              _id
            }
          }
        }
        project {
          _id
          name
        }
        rating
        ethnicity
        statuses {
          name
          questions {
            questionId {
              _id
              question
            }
            answer
          }
          createdAt
        }
        salesRep {
          _id
          firstName
          lastName
          fullName
        }
        onlineSalesRep {
          _id
          firstName
          lastName
          fullName
        }
        questions {
          answer
          questionId {
            _id
            name
            question
          }
        }
        salesQuestions {
          answer
          questionId {
            _id
            name
            question
          }
        }
        mail {
          _id
        }
        process {
          _id
        }
        textUnsubscribed {
          date
          type
        }
        emailUnsubscribed {
          date
          type
        }
        connections {
          date
          user {
            _id
            fullName
          }
        }
        count {
          createdAt
          reason
          guests
          scheduleWithRealtor
          meetingWithRealtor
        }
        appointments {
          _id
          project {
            _id
            salesOffice
            name
          }
          user {
            fullName
            email
            realtor {
              _id
              fullName
              directPhone
            }
          }
          date
          location
          purchaserInfo {
            firstName
            lastName
            email
            primaryPhone
            numberOfGuests
          }
          questions {
            questionId {
              _id
              name
              question
              type
              choices {
                choice
                followUp {
                  _id
                }
              }
            }
            answer
          }
          status
          type
          notes
          confirmed
          cancelled
          cameIn
          noResponse
          salesNotes
          salesRep {
            _id
            fullName
          }
          registrant {
            _id
            tags
            source
          }
          createdAt
        }
        tags
        createdAt
        source
      }
    }
  }
`;

export default Processes;
