import { useState, useReducer, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { gql, useLazyQuery } from "@apollo/client";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box, TextField, FormControl, Select, InputLabel, MenuItem, Grid, Typography, Button } from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../../features/snackbar/snackbarSlice";
import { IProject } from "../../types/project";
import { IProjectAccess } from "../../types/user";
import { selectUser } from "../../features/auth/authSlice";
import StandardTable from "../tables/StandardTable";
import AppointmentsTable from "./AppointmentsTable";
import { IAppointment } from "../../types/project";
import { downloadExcel, downloadPdf, convertAllDates } from "../../utils/function";

export const appointmentsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload.value];
    case "COPY":
      return [...state, action.payload];
    case "UPDATE":
      return state.map((state: any, index: number) => {
        if (state._id === action.payload._id) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case "UPDATEALL":
      return action.payload;
    case "DELETE":
      return state.filter((state: any, index: number) => state._id !== action.payload._id);
    default:
      throw new Error();
  }
};

const Stats = () => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [appointmentsState, appointmentsDispatch] = useReducer(appointmentsReducer, []);
  const [allAppointments, setAllAppointments] = useState<IAppointment[]>([]);
  const [scheduleArray, setScheduleArray] = useState<any[]>([]);
  const [userArray, setUserArray] = useState<any[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [dateStart, setDateStart] = useState<any>(new Date().setHours(0, 0, 0, 0));
  const [dateEnd, setDateEnd] = useState<any>(new Date().setHours(23, 59, 59, 59));
  const [project, setProject] = useState<IProject | null>(null);
  const [dialogType, setDialogType] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState<number>(0);

  const [getAppointments] = useLazyQuery(GETAPPOINTMENTS, {
    onCompleted: (data) => {
      let appointments = [...data.appointmentPagination.items].sort((a: any, b: any) => {
        return new Date(a.date).valueOf() - new Date(b.date).valueOf();
      });

      let bookedAppointments = appointments.filter((appointment: IAppointment) => {
        return dateStart?.valueOf()! < new Date(appointment.date).valueOf() && new Date(appointment.date).valueOf() < dateEnd?.valueOf()!;
      });
      setCount(data.appointmentPagination.count);
      appointmentsDispatch({
        type: "UPDATEALL",
        payload: bookedAppointments,
      });
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting appointments"));
    },
  });

  const [getAppointmentsStats, { loading }] = useLazyQuery(GETAPPOINTMENTSTATS, {
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      let appointments = [...data.appointmentMany].sort((a: any, b: any) => {
        return new Date(a.date).valueOf() - new Date(b.date).valueOf();
      });

      const uniqueSchedule = [...new Set(appointments.map((item) => (item.schedule ? item.schedule.name : "No Event")))]; // [ 'A', 'B']
      const uniqueSales = [
        ...new Set(
          [
            ...new Set(
              appointments.map((item) => [item.salesRep ? item.salesRep.fullName : "Public", item.user ? item.user.fullName : "Public"])
            ),
          ].flat()
        ),
      ];

      let scheduleArray = uniqueSchedule.map((schedule: string) => {
        let selectedSchedule = [];
        if (schedule === "No Event") {
          selectedSchedule = appointments.filter((appointment: any) => !appointment.schedule);
        } else {
          selectedSchedule = appointments.filter((appointment: any) => appointment.schedule?.name! === schedule);
        }

        return {
          name: schedule,
          totalCreated: selectedSchedule.reduce(function (r, a) {
            return r + +(dateStart.valueOf() < new Date(a.createdAt).valueOf() && new Date(a.createdAt).valueOf() < dateEnd.valueOf());
          }, 0),
          totalBooked: selectedSchedule.reduce(function (r, a) {
            return r + +(dateStart.valueOf() < new Date(a.date).valueOf() && new Date(a.date).valueOf() < dateEnd.valueOf());
          }, 0),
          totalConfirmed: selectedSchedule.reduce(function (r, a) {
            return r + +(a.confirmed && dateStart.valueOf() < new Date(a.date).valueOf() && new Date(a.date).valueOf() < dateEnd.valueOf());
          }, 0),
          totalCancelled: selectedSchedule.reduce(function (r, a) {
            return r + +(a.cancelled && dateStart.valueOf() < new Date(a.date).valueOf() && new Date(a.date).valueOf() < dateEnd.valueOf());
          }, 0),
          totalCameIn: selectedSchedule.reduce(function (r, a) {
            return r + +(a.cameIn && dateStart.valueOf() < new Date(a.date).valueOf() && new Date(a.date).valueOf() < dateEnd.valueOf());
          }, 0),
          totalNoAction: selectedSchedule.reduce(function (r, a) {
            return (
              r +
              +(
                !a.cancelled &&
                !a.confirmed &&
                !a.cameIn &&
                dateStart.valueOf() < new Date(a.date).valueOf() &&
                new Date(a.date).valueOf() < dateEnd.valueOf()
              )
            );
          }, 0),
        };
      });

      let salesArray = uniqueSales.map((schedule: string) => {
        let selectedSchedule = [];
        if (schedule === "Public") {
          selectedSchedule = appointments.filter((appointment: any) => !appointment.user && !appointment.salesRep);
        } else {
          selectedSchedule = appointments.filter(
            (appointment: any) =>
              (appointment.user && appointment.user.fullName === schedule) ||
              (appointment.salesRep && appointment.salesRep.fullName === schedule)
          );
        }

        return {
          name: schedule,
          totalCreated: selectedSchedule.reduce(function (r, a) {
            return (
              r +
              +(
                ((a.user && a.user.fullName === schedule) || (!a.user && schedule === "Public")) &&
                dateStart.valueOf() < new Date(a.createdAt).valueOf() &&
                new Date(a.createdAt).valueOf() < dateEnd.valueOf()
              )
            );
          }, 0),
          totalBooked: selectedSchedule.reduce(function (r, a) {
            return (
              r +
              +(
                a.salesRep &&
                a.salesRep.fullName === schedule &&
                dateStart.valueOf() < new Date(a.date).valueOf() &&
                new Date(a.date).valueOf() < dateEnd.valueOf()
              )
            );
          }, 0),
          totalAttended: selectedSchedule.reduce(function (r, a) {
            return (
              r +
              +(
                a.salesRep &&
                a.salesRep.fullName === schedule &&
                dateStart.valueOf() < new Date(a.date).valueOf() &&
                new Date(a.date).valueOf() < dateEnd.valueOf() &&
                a.cameIn
              )
            );
          }, 0),
        };
      });
      salesArray = salesArray.filter((appointment: any) => appointment.totalCreated || appointment.totalBooked);

      if (scheduleArray.length) {
        let total = {
          name: "Total",
          totalCreated: scheduleArray.reduce((a, b) => a + b.totalCreated, 0),
          totalBooked: scheduleArray.reduce((a, b) => a + b.totalBooked, 0),
          totalConfirmed: scheduleArray.reduce((a, b) => a + b.totalConfirmed, 0),
          totalCancelled: scheduleArray.reduce((a, b) => a + b.totalCancelled, 0),
          totalCameIn: scheduleArray.reduce((a, b) => a + b.totalCameIn, 0),
          totalNoAction: scheduleArray.reduce((a, b) => a + b.totalNoAction, 0),
        };
        scheduleArray = [...scheduleArray, total];
      }

      setAllAppointments(data.appointmentMany);
      setScheduleArray(scheduleArray);
      setUserArray(salesArray);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting appointments"));
    },
  });

  useEffect(() => {
    if (dateStart && dateEnd && dateStart.toString() !== "Invalid Date" && dateEnd.toString() !== "Invalid Date") {
      let filterQuery: any = {
        dates: [new Date(dateStart!), new Date(dateEnd!)],
        projects: user?.projectAccess.map((projectAccess: IProjectAccess) => projectAccess.project._id),
        typeNot: "lease",
      };
      if (project) {
        filterQuery = {
          dates: [new Date(dateStart!), new Date(dateEnd!)],
          project: project._id,
          typeNot: "lease",
        };
      }
      getAppointments({
        variables: {
          filter: filterQuery,
          page: pageNumber,
          perPage: 20,
        },
      });
      getAppointmentsStats({
        variables: {
          filter: filterQuery,
        },
      });
    }
  }, [dateStart, dateEnd, getAppointments, project, user, pageNumber]);

  const handleProject = (e: any) => {
    let selectedProject = user?.projectAccess.find((projectAccess: IProjectAccess) => projectAccess.project._id === e);
    if (selectedProject) {
      setProject(selectedProject.project);
    }
  };

  const scheduleColumn = useMemo(() => {
    return [
      {
        Header: "Schedule",
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal" }}>{rowData.name}</Box>;
        },
      },
      {
        Header: "Appointments Created",
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal" }}>{rowData.totalCreated}</Box>;
        },
      },
      {
        Header: "Scheduled Appointments",
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal" }}>{rowData.totalBooked}</Box>;
        },
      },
      {
        Header: "Appointments Confirmed",
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal" }}>{rowData.totalConfirmed}</Box>;
        },
      },
      {
        Header: "Appointments Cancelled",
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal" }}>{rowData.totalCancelled}</Box>;
        },
      },
      {
        Header: "Attended Appointments",
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal" }}>{rowData.totalCameIn}</Box>;
        },
      },
      {
        Header: "Appointments No Action Taken",
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.name === "Total" ? "bold" : "normal" }}>{rowData.totalNoAction}</Box>;
        },
      },
    ];
  }, []);

  const userColumn = [
    {
      Header: "User",
      accessor: (rowData: any) => {
        return rowData.name;
      },
    },
    {
      Header: "Appointments Created",
      accessor: (rowData: any) => {
        return rowData.totalCreated;
      },
    },
    {
      Header: "Appointments Booked",
      accessor: (rowData: any) => {
        return rowData.totalBooked;
      },
    },
    {
      Header: "Registrants Attended",
      accessor: (rowData: any) => {
        return rowData.totalAttended;
      },
    },
  ];

  const downloadSchedule = (type: string, data: any) => {
    let unitColumns = [
      {
        label: "Schedule",
        id: "name",
      },
      {
        label: "Appointments Created",
        id: "totalCreated",
      },
      {
        label: "Scheduled Appointments",
        id: "totalBooked",
      },
      {
        label: "Appointments Confirmed",
        id: "totalConfirmed",
      },
      {
        label: "Appointments Cancelled",
        id: "totalCancelled",
      },
      {
        label: "Attended Appointments",
        id: "totalCameIn",
      },
      {
        label: "Appointments No Action Taken",
        id: "totalNoAction",
      },
    ];

    let widths = {
      name: 15,
      totalCreated: 15,
      totalBooked: 15,
      totalConfirmed: 15,
      totalCancelled: 15,
      totalCameIn: 15,
      totalNoAction: 15,
    };

    let pdfWidths = {
      name: 200,
      totalCreated: 200,
      totalBooked: 200,
      totalConfirmed: 200,
      totalCancelled: 200,
      totalCameIn: 200,
      totalNoAction: 200,
    };

    let allData = scheduleArray.map((data: any) => {
      return {
        name: data.name,
        totalCreated: data.totalCreated,
        totalBooked: data.totalBooked,
        totalConfirmed: data.totalConfirmed,
        totalCancelled: data.totalCancelled,
        totalCameIn: data.totalCameIn,
        totalNoAction: data.totalNoAction,
      };
    });

    let sheetTitle = `${convertAllDates(dateStart, "PP")} Schedule`;

    if (type === "excel") {
      downloadExcel([allData], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [unitColumns], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const downloadUser = (type: string, data: any) => {
    let unitColumns = [
      {
        label: "Schedule",
        id: "name",
      },
      {
        label: "Appointments Created",
        id: "totalCreated",
      },
      {
        label: "Appointments Booked",
        id: "totalBooked",
      },
    ];

    let widths = {
      name: 15,
      totalCreated: 15,
      totalBooked: 15,
    };

    let pdfWidths = {
      name: 200,
      totalCreated: 200,
      totalBooked: 200,
    };

    let allData = userArray.map((data: any) => {
      return {
        name: data.name,
        totalCreated: data.totalCreated,
        totalBooked: data.totalBooked,
      };
    });

    let sheetTitle = `${convertAllDates(dateStart, "PP")} User Appointments`;

    if (type === "excel") {
      downloadExcel([allData], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [unitColumns], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const handleGlobalFilterValue = () => {};

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={"Start Date (YYYY/MM/DD)"}
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={"End Date (YYYY/MM/DD)"}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-project">Project</InputLabel>
            <Select
              label="Project"
              sx={{ width: "100%" }}
              labelId="demo-simple-select-project"
              id="demo-simple-project"
              value={project ? project._id : ""}
              onChange={(e: any) => {
                handleProject(e.target.value);
              }}
              fullWidth
              required
            >
              {user?.projectAccess.map((projectAccess: IProjectAccess, index: number) => {
                return (
                  <MenuItem key={index} value={projectAccess.project._id}>
                    {projectAccess.project.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} xl={6} sx={{ height: "100%" }}>
          <StandardTable
            user={user}
            count={scheduleArray.length}
            handleGlobalFilterValue={handleGlobalFilterValue}
            download={downloadSchedule}
            loading={loading}
            data={scheduleArray}
            columns={scheduleColumn}
          />
        </Grid>
        <Grid item xs={12} xl={6} sx={{ height: "100%" }}>
          <StandardTable
            user={user}
            count={userArray.length}
            handleGlobalFilterValue={handleGlobalFilterValue}
            download={downloadUser}
            loading={loading}
            data={userArray}
            columns={userColumn}
          />
        </Grid>
      </Grid>
      <Typography sx={{ mt: 4 }} variant="h2">
        <strong>Scheduled Appointments</strong>
      </Typography>
      <Box>
        <AppointmentsTable
          appointments={appointmentsState}
          allAppointments={allAppointments}
          loading={loading}
          appointmentsDispatch={appointmentsDispatch}
          schedule={null}
          setSchedule={null}
          schedules={[]}
          selectedAppointment={selectedAppointment}
          setSelectedAppointment={setSelectedAppointment}
          dialogOpen={dialogOpen}
          dialogType={dialogType}
          setDialogOpen={setDialogOpen}
          setDialogType={setDialogType}
          allowSchedule={true}
          selectedRegistrants={[]}
          setSelectedRegistrants={null}
          count={count}
        />
      </Box>
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
          {"<"}
        </Button>
        <span>{pageNumber}</span>
        <Button disabled={pageNumber === Math.ceil(count / 15)} onClick={() => setPageNumber(pageNumber + 1)}>
          {">"}
        </Button>
      </Box>
    </Box>
  );
};

const GETAPPOINTMENTSTATS = gql`
  query appointmentMany($filter: FilterFindManyAppointmentInput) {
    appointmentMany(filter: $filter, limit: 100000) {
      _id
      project {
        _id
        salesOffice
        name
      }
      user {
        fullName
        email
        realtor {
          _id
          fullName
          directPhone
        }
      }
      date
      location
      purchaserInfo {
        firstName
        lastName
        email
        primaryPhone
        numberOfGuests
      }
      questions {
        questionId {
          _id
          name
          question
          type
          choices {
            choice
            followUp {
              _id
            }
          }
        }
        answer
      }
      status
      type
      notes
      confirmed
      cancelled
      cameIn
      noResponse
      salesNotes
      salesRep {
        _id
        fullName
      }
      schedule {
        _id
        name
      }
      registrant {
        _id
        email
        rating
        project {
          _id
        }
        source
        firstName
        lastName
        primaryPhone
        realtorType
        statuses {
          name
          questions {
            questionId {
              _id
              question
            }
            answer
          }
          createdAt
        }
        tags
      }
      createdAt
    }
  }
`;

const GETAPPOINTMENTS = gql`
  query appointmentPagination($filter: FilterFindManyAppointmentInput, $page: Int, $perPage: Int) {
    appointmentPagination(filter: $filter, page: $page, perPage: $perPage) {
      count
      items {
        _id
        project {
          _id
          salesOffice
          name
        }
        user {
          fullName
          email
          realtor {
            _id
            fullName
            directPhone
          }
        }
        date
        location
        purchaserInfo {
          firstName
          lastName
          email
          primaryPhone
          numberOfGuests
        }
        questions {
          questionId {
            _id
            name
            question
            type
            choices {
              choice
              followUp {
                _id
              }
            }
          }
          answer
        }
        status
        type
        notes
        confirmed
        cancelled
        cameIn
        noResponse
        salesNotes
        salesRep {
          _id
          fullName
        }
        schedule {
          _id
          name
        }
        registrant {
          _id
          email
          rating
          firstName
          lastName
          primaryPhone
          project {
            _id
          }
          source
          realtorType
          statuses {
            name
            questions {
              questionId {
                _id
                question
              }
              answer
            }
            createdAt
          }
          tags
        }
        createdAt
      }
    }
  }
`;

export default Stats;
