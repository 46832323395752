import { useState, useMemo, useEffect, useContext } from "react";
import { Box, Checkbox, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useQuery, useLazyQuery, gql, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

import { useAppDispatch } from "../../../app/hooks";
import { IRegistrant } from "../../../types/registrant";
import { setRegistrants, updateRegistrants } from "../../../features/projectSetting/projectSettingSlice";
import { camelToNormal, convertAllDates } from "../../../utils/function";
import { ratings } from "../../../utils/constant";
import { useTemplatesQuery } from "../../../features/emailTemplate/emailTemplateHooks";
import RegistrantTable from "../../common/RegistrantTable";
import { IUser } from "../../../types/user";
import { SettingContainer } from "../../../commonStyles";
import { showSuccessSnackbar } from "../../../features/snackbar/snackbarSlice";
import { selectUser } from "../../../features/auth/authSlice";
import { RegistrantFilterContext } from "../../../context/RegistrantFilterContext";
import { selectSales, selectOnline } from "../../../features/projectSetting/projectSettingSlice";

const ListTable = () => {
  const { projectid, listid } = useParams();
  const {
    realtorTypeFilter,
    setRealtorTypeFilter,
    statusFilter,
    setStatusFilter,
    ratingFilter,
    setRatingFilter,
    salesRepFilter,
    setSalesRepFilter,
    onlineSalesRepFilter,
    setOnlineSalesRepFilter,
    sourceFilter,
    setSourceFilter,
    emailUnsubscribedFilter,
    setEmailUnsubscribedFilter,
    textUnsubscribedFilter,
    setTextUnsubscribedFilter,
    connectedFilter,
    setConnectedFilter,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    cameInStartDateFilter,
    cameInEndDateFilter,
  } = useContext(RegistrantFilterContext);
  const [getEmails] = useTemplatesQuery(1, 100, "_ID_DESC");
  const storeDispatch = useAppDispatch();
  let navigate = useNavigate();
  const user = useSelector(selectUser);
  const projectSales = useSelector(selectSales);
  const projectOnline = useSelector(selectOnline);

  const [name, setName] = useState<string>("");
  const [selectedRegistrants, setSelectedRegistrants] = useState<string[]>([]);
  const [purchasers, setPurchasers] = useState<any[]>([]);
  const [selectedPurchasers, setSelectedPurchasers] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>(searchParams.get("page") ? parseInt(searchParams.get("page")!, 10) : 1);
  const [perPage, setPerPage] = useState<number>(searchParams.get("row") ? parseInt(searchParams.get("row")!, 10) : 15);

  const checkListId = () => {
    if ((listid && listid.includes("-")) || listid === "purchasers") {
      return true;
    } else return false;
  };

  useEffect(() => {
    getEmails();
    // eslint-disable-next-line
  }, []);

  const [getRegistrants, { loading }] = useLazyQuery(GETREGISTRANTSFROMLIST, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      storeDispatch(setRegistrants(data.getRegistrantsFromList));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [getPurchasers] = useLazyQuery(GETPURCHASERS, {
    onCompleted: (data) => {
      setPurchasers(data.getPurchasers.purchasers);
      setCount(data.getPurchasers.count);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [getChosenPurchasers] = useLazyQuery(GETPURCHASERS, {
    onCompleted: (data) => {
      setSelectedPurchasers(data.getPurchasers.purchasers.map((purchaser: any) => purchaser._id.purchaser._id));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [getAllRegistrants] = useLazyQuery(GETALLREGISTRANTS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setSelectedRegistrants(data.getRegistrantsFromList.registrants.map((registrant: IRegistrant) => registrant._id));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useQuery(GETLIST, {
    skip: checkListId(),
    variables: { _id: listid },
    onCompleted: (data) => {
      setName(data.listById.name);
      setStartDateFilter(data.listById.registrantStart);
      setEndDateFilter(data.listById.registrantEnd);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [updateRegistrant] = useMutation(UPDATEREGISTRANT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("Registrants Updated!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [updateRegistrantProcess] = useMutation(UPDATEREGISTRANTPROCESS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar("Registrants Updated!"));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    if (listid === "purchasers") {
      getPurchasers({
        variables: {
          projects: [projectid],
          page: searchParams ? parseInt(searchParams.get("page")!, 10) : pageNumber,
          perPage: searchParams ? parseInt(searchParams.get("row")!, 10) : perPage,
          search: "",
        },
      });
    } else {
      getRegistrants({
        variables: {
          project: projectid,
          listId: listid,
          page: searchParams ? parseInt(searchParams.get("page")!, 10) : pageNumber,
          perPage: searchParams ? parseInt(searchParams.get("row")!, 10) : perPage,
          search: "",
          filter: {
            realtorType: realtorTypeFilter === "any" ? null : realtorTypeFilter,
            status: statusFilter === "any" ? null : statusFilter,
            rating: ratingFilter === "any" ? null : ratingFilter,
            salesRep: salesRepFilter === "any" ? null : (salesRepFilter as any)._id,
            onlineSalesRep: onlineSalesRepFilter === "any" ? null : (onlineSalesRepFilter as any)._id,
            source: sourceFilter === "any" ? null : sourceFilter,
            emailUnsubscribed: emailUnsubscribedFilter === "any" ? null : emailUnsubscribedFilter,
            textUnsubscribed: textUnsubscribedFilter === "any" ? null : textUnsubscribedFilter,
            connected: connectedFilter === "any" ? null : connectedFilter,
            startDate: startDateFilter,
            endDate: endDateFilter,
            cameInStartDate: cameInStartDateFilter,
            cameInEndDate: cameInEndDateFilter,
            searchStart: searchParams.get("start") ? new Date(parseInt(searchParams.get("start")!, 10)) : null,
            searchEnd: searchParams.get("end") ? new Date(parseInt(searchParams.get("end")!, 10)) : null,
            user: searchParams.get("user") ? searchParams.get("user") : null,
          },
          comments: false,
        },
      });
    }
    setPageNumber(searchParams ? parseInt(searchParams.get("page")!, 10) : pageNumber);
  }, [
    pageNumber,
    perPage,
    projectid,
    listid,
    getRegistrants,
    getPurchasers,
    searchParams,
    realtorTypeFilter,
    statusFilter,
    ratingFilter,
    salesRepFilter,
    onlineSalesRepFilter,
    sourceFilter,
    emailUnsubscribedFilter,
    textUnsubscribedFilter,
    connectedFilter,
    startDateFilter,
    endDateFilter,
    cameInStartDateFilter,
    cameInEndDateFilter,
  ]);

  const columns = useMemo(() => {
    const selectAll = () => {
      if (selectedRegistrants.length) {
        setSelectedRegistrants([]);
      } else {
        getAllRegistrants({
          variables: {
            project: projectid,
            listId: listid,
            page: 1,
            perPage: 100000,
            search: "",
            filter: {
              realtorType: realtorTypeFilter === "any" ? null : realtorTypeFilter,
              status: statusFilter === "any" ? null : statusFilter,
              rating: ratingFilter === "any" ? null : ratingFilter,
              salesRep: salesRepFilter === "any" ? null : (salesRepFilter as any)._id,
              onlineSalesRep: onlineSalesRepFilter === "any" ? null : (onlineSalesRepFilter as any)._id,
              source: sourceFilter === "any" ? null : sourceFilter,
              emailUnsubscribed: emailUnsubscribedFilter === "any" ? null : emailUnsubscribedFilter,
              textUnsubscribed: textUnsubscribedFilter === "any" ? null : textUnsubscribedFilter,
              connected: connectedFilter === "any" ? null : connectedFilter,
              startDate: startDateFilter,
              endDate: endDateFilter,
              cameInStartDate: cameInStartDateFilter,
              cameInEndDate: cameInEndDateFilter,
              searchStart: searchParams.get("start") ? new Date(parseInt(searchParams.get("start")!, 10)) : null,
              searchEnd: searchParams.get("end") ? new Date(parseInt(searchParams.get("end")!, 10)) : null,
              user: searchParams.get("user") ? searchParams.get("user") : null,
            },
          },
        });
      }
    };

    const handleSelectedRegistrants = (id: string) => {
      if (selectedRegistrants.includes(id)) {
        setSelectedRegistrants(selectedRegistrants.filter((regId: string) => regId !== id));
      } else {
        setSelectedRegistrants([...selectedRegistrants, id]);
      }
    };

    const handleRatingChange = async (e: string, name: string, data: IRegistrant) => {
      await updateRegistrant({
        variables: {
          _id: data._id,
          record: { [name]: e },
        },
      });
      storeDispatch(
        updateRegistrants({
          value: e,
          name: name,
          data: data,
        })
      );
    };

    const handleSalesRepChange = async (e: string, name: string, data: IRegistrant) => {
      let selectedUser;
      if (!e) {
        selectedUser = null;
      } else {
        if (name === "salesRep") {
          selectedUser = projectSales.find((user: IUser) => e === user._id);
        } else if (name === "onlineSalesRep") {
          selectedUser = projectOnline.find((user: IUser) => e === user._id);
        }
      }
      if (selectedUser) {
        await updateRegistrantProcess({
          variables: {
            user: selectedUser._id,
            registrant: data._id,
            type: name,
          },
        });
        storeDispatch(
          updateRegistrants({
            value: selectedUser,
            name: name,
            data: data,
          })
        );
      } else {
        await updateRegistrantProcess({
          variables: {
            user: null,
            registrant: data._id,
            type: name,
          },
        });
        storeDispatch(
          updateRegistrants({
            value: null,
            name: name,
            data: data,
          })
        );
      }
    };

    let columns: any[] = [
      {
        id: "selection",
        Header: <Checkbox sx={{ color: "#fff" }} onClick={() => selectAll()} checked={selectedRegistrants.length > 0} />,
        accessor: (rowData: IRegistrant) => {
          return (
            <Box>
              <Checkbox onChange={(e) => handleSelectedRegistrants(rowData._id)} checked={selectedRegistrants.includes(rowData._id)} />
            </Box>
          );
        },
      },
      {
        Header: "First Name",
        accessor: (rowData: IRegistrant) => {
          return (
            <Link
              target="_blank"
              style={{ textDecoration: "none", color: "#000" }}
              to={`/dashboard/project/${projectid}/registrant/${rowData._id}`}
            >
              <strong style={{ cursor: "pointer" }}>{rowData.firstName}</strong>
            </Link>
          );
        },
      },
      {
        Header: "Last Name",
        accessor: (rowData: IRegistrant) => {
          return (
            <Link
              target="_blank"
              style={{ textDecoration: "none", color: "#000" }}
              to={`/dashboard/project/${projectid}/registrant/${rowData._id}`}
            >
              <strong style={{ cursor: "pointer" }}>{rowData.lastName}</strong>
            </Link>
          );
        },
      },
      {
        Header: "Email",
        accessor: (rowData: IRegistrant) => {
          return rowData.email;
        },
      },
      {
        Header: "Phone Number",
        accessor: (rowData: IRegistrant) => {
          return rowData.primaryPhone;
        },
      },
      {
        Header: "Rating",
        accessor: (rowData: IRegistrant) => {
          return (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Rating</InputLabel>
              <Select
                labelId="ratings"
                name="rating"
                value={rowData.rating}
                label="Rating"
                onChange={(e) => handleRatingChange(e.target.value, e.target.name, rowData)}
              >
                {ratings.map((rating: string, index: number) => {
                  return (
                    <MenuItem key={index} value={rating}>
                      {rating}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      },
      {
        Header: "Status",
        accessor: (rowData: IRegistrant) => {
          return (
            <Box>
              <Box>{rowData.statuses[rowData.statuses.length - 1].name}</Box>
              <Box>{convertAllDates(rowData.statuses[rowData.statuses.length - 1].createdAt, "PPpp")}</Box>
            </Box>
          );
        },
      },
      {
        Header: "Onsite",
        accessor: (rowData: IRegistrant) => {
          return (
            <FormControl sx={{ width: "200px" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Onsite</InputLabel>
              <Select
                labelId="onsiteSalesRep"
                name="salesRep"
                value={rowData.salesRep ? rowData.salesRep._id : ""}
                label="Onsite"
                onChange={(e) => handleSalesRepChange(e.target.value!, e.target.name, rowData)}
              >
                {projectSales.map((user: IUser, index: number) => {
                  return (
                    <MenuItem key={index} value={user._id}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  );
                })}
                 <MenuItem value={''}>
                  None
                </MenuItem>
              </Select>
            </FormControl>
          );
        },
      },
      {
        Header: "Online",
        accessor: (rowData: IRegistrant) => {
          return (
            <FormControl sx={{ width: "200px" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Online Rep</InputLabel>
              <Select
                labelId="onlineSalesRep"
                name="onlineSalesRep"
                value={rowData.onlineSalesRep ? rowData.onlineSalesRep._id : ""}
                label="Online Rep"
                onChange={(e) => handleSalesRepChange(e.target.value!, e.target.name, rowData)}
              >
                {projectOnline.map((user: IUser, index: number) => {
                  return (
                    <MenuItem key={index} value={user._id}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  );
                  
                })}
                <MenuItem value={''}>
                  None
                </MenuItem>
              </Select>
            </FormControl>
          );
        },
      },
      {
        Header: "Source",
        accessor: (rowData: IRegistrant) => {
          return rowData.source;
        },
      },
      {
        Header: "Realtor Type",
        accessor: (rowData: IRegistrant) => {
          return camelToNormal(rowData.realtorType);
        },
      },
      {
        Header: "Connected",
        accessor: (rowData: IRegistrant) => {
          return rowData.connections.length ? "Yes" : "No";
        },
      },
      {
        Header: "Email Subscribed",
        accessor: (rowData: IRegistrant) => {
          return rowData.emailUnsubscribed ? "No" : "Yes";
        },
      },
      {
        Header: "Favourited",
        accessor: (rowData: IRegistrant) => {
          return (
            <Box>
              {user?.favourites.find((fav: any) => fav._id === rowData._id) && <StarIcon sx={{ color: "orange" }}></StarIcon>}
              {!user?.favourites.find((fav: any) => fav._id === rowData._id) && <StarBorderIcon></StarBorderIcon>}
            </Box>
          );
        },
      },
      {
        Header: "Date Registered",
        accessor: (rowData: IRegistrant) => {
          return convertAllDates(rowData.createdAt, "PPpp");
        },
      },
      {
        Header: "Upcoming Step",
        accessor: (rowData: IRegistrant) => {
          const currentProcess = rowData.process.find((process) => process?.currentProcess);
          if (!currentProcess) return "N/A";
          let upcomingStep = currentProcess.steps.find((step) => !step.completed);
          let date;
          let name;
          if (!upcomingStep) {
            upcomingStep = currentProcess.steps[currentProcess.steps.length - 1];
            date = upcomingStep.completed;
            name = "Process Completed";
          } else {
            date = upcomingStep.dueDate;
            name = upcomingStep.name;
          }

          let dateColour = "black";
          const oneDay = 1000 * 3600 * 24;
          const twoDays = 2 * oneDay;

          const late = upcomingStep.dueDate && new Date(upcomingStep.dueDate).getTime() - Date.now() < 0;
          const withinOneDay = upcomingStep.dueDate && new Date(upcomingStep.dueDate).getTime() - Date.now() < oneDay;
          const withinTwoDays = upcomingStep.dueDate && new Date(upcomingStep.dueDate).getTime() - Date.now() < twoDays;
          const moreThanTwoDays = !withinTwoDays;
          if (moreThanTwoDays) dateColour = "green";
          if (withinTwoDays) dateColour = "rgb(198, 201, 10)";
          if (withinOneDay) dateColour = "rgb(256, 0, 0, 0.6)";
          if (late) dateColour = "red";

          return (
            <Stack sx={{ width: "150px" }}>
              <Typography>{`${name}`}</Typography>
              <Typography sx={{ color: dateColour, fontSize: "10px" }}>{`[${convertAllDates(date, "PPpp")}]`}</Typography>
            </Stack>
          );
        },
      },
      {
        Header: "Last Completed Step",
        accessor: (rowData: IRegistrant) => {
          const currentProcess = rowData.process.find((process) => process?.currentProcess);
          if (!currentProcess) return "N/A";
          let upcomingStep = currentProcess.steps.find((step) => !step.completed);
          let date;
          let name;
          if (!upcomingStep) {
            upcomingStep = currentProcess.steps[currentProcess.steps.length - 1];
            date = upcomingStep.completed;
            name = "Process Completed";
          } else {
            let lastCompletedStepIndex = currentProcess.steps.length - 1;
            while (currentProcess.steps[lastCompletedStepIndex] && !currentProcess.steps[lastCompletedStepIndex].completed)
              --lastCompletedStepIndex;
            if (lastCompletedStepIndex === -1) return "N/A";
            const lastCompletedStep = currentProcess.steps[lastCompletedStepIndex];
            date = upcomingStep.dueDate;
            name = lastCompletedStep.name;
          }

          return (
            <Stack sx={{ width: "150px" }}>
              <Typography>{`${name}`}</Typography>
              <Typography sx={{ fontSize: "10px" }}>{`[${convertAllDates(date, "PPpp")}]`}</Typography>
            </Stack>
          );
        },
      },
    ];

    if (listid?.includes("be-backs")) {
      columns.push({
        Header: "Be Back",
        accessor: (rowData: IRegistrant) => (rowData.count.length ? rowData.count.at(-1).createdAt : ""),
        Cell: (props: any) => {
          return <Box sx={{ p: 2 }}>{props.value ? convertAllDates(props.value, "PPpp") : ""}</Box>;
        },
      });

      columns.push({
        Header: "Be Back Count",
        accessor: (rowData: IRegistrant) => rowData.count.length,
      });
    }

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRegistrants,
    getAllRegistrants,
    listid,
    storeDispatch,
    navigate,
    projectid,
    projectSales,
    projectOnline,
    realtorTypeFilter,
    statusFilter,
    salesRepFilter,
    onlineSalesRepFilter,
    ratingFilter,
    emailUnsubscribedFilter,
    connectedFilter,
    sourceFilter,
    startDateFilter,
    endDateFilter,
  ]);

  const purchasersColumns = useMemo(() => {
    const selectAll = () => {
      if (selectedPurchasers.length) {
        setSelectedPurchasers([]);
      } else {
        getChosenPurchasers({ variables: { projects: [projectid], page: 1, perPage: 50000, search: "" } });
      }
    };

    const handleSelectedPurchasers = (id: string) => {
      if (selectedPurchasers.includes(id)) {
        setSelectedPurchasers(selectedPurchasers.filter((regId: string) => regId !== id));
      } else {
        setSelectedPurchasers([...selectedPurchasers, id]);
      }
    };

    return [
      {
        id: "selection",
        Header: <Checkbox sx={{ color: "#fff" }} onClick={() => selectAll()} checked={selectedPurchasers.length > 0} />,
        accessor: (rowData: any) => {
          return (
            <Box>
              <Checkbox
                onChange={(e) => handleSelectedPurchasers(rowData._id.purchaser._id)}
                checked={selectedPurchasers.includes(rowData._id.purchaser._id)}
              />
            </Box>
          );
        },
      },
      {
        Header: "Suite",
        accessor: (rowData: any) => {
          return rowData.suite;
        },
      },
      {
        Header: "First Name",
        accessor: (rowData: any) => {
          if (rowData.registrant) {
            return (
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
                to={`/dashboard/project/${projectid}/registrant/${rowData.registrant._id}`}
              >
                <strong style={{ cursor: "pointer" }}>{rowData._id.purchaser.firstName}</strong>
              </Link>
            );
          } else {
            return rowData._id.purchaser.firstName;
          }
        },
      },
      {
        Header: "Last Name",
        accessor: (rowData: any) => {
          if (rowData.registrant) {
            return (
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
                to={`/dashboard/project/${projectid}/registrant/${rowData.registrant._id}`}
              >
                <strong style={{ cursor: "pointer" }}>{rowData._id.purchaser.lastName}</strong>
              </Link>
            );
          } else {
            return rowData._id.purchaser.lastName;
          }
        },
      },
      {
        Header: "Email",
        accessor: (rowData: any) => {
          return rowData._id.purchaser.email;
        },
      },
      {
        Header: "Phone",
        accessor: (rowData: any) => {
          return rowData._id.purchaser.primaryPhone;
        },
      },
      {
        Header: "Total Notes",
        accessor: (rowData: any) => {
          return rowData.registrant ? rowData.registrant.comments.length : 0;
        },
      },
      {
        Header: "Realtor",
        accessor: (rowData: any) => {
          return rowData.realtor && rowData.realtor.fullName ? rowData.realtor.fullName : "";
        },
      },
      {
        Header: "Firm Date",
        accessor: (rowData: any) => {
          return rowData.firmDate ? convertAllDates(rowData.firmDate, "PP") : "";
        },
      },
      {
        Header: "Connected",
        accessor: (rowData: any) => {
          return rowData.registrant ? (rowData.registrant.connected ? "Connected" : "Not Connected") : "";
        },
      },
    ];
  }, [selectedPurchasers, getChosenPurchasers, projectid]);

  // Functions

  const handleGlobalPurchaserValue = (value: string) => {
    setPageNumber(1);
    getPurchasers({ variables: { projects: [projectid], page: pageNumber, perPage: perPage, search: value } });
  };

  const handleGlobalFilterValue = (value: string) => {
    setPageNumber(1);
    getRegistrants({
      variables: {
        project: projectid,
        listId: listid,
        page: 1,
        perPage: searchParams ? parseInt(searchParams.get("row")!, 10) : perPage,
        search: value,
        filter: {
          realtorType: realtorTypeFilter === "any" ? null : realtorTypeFilter,
          status: statusFilter === "any" ? null : statusFilter,
          rating: ratingFilter === "any" ? null : ratingFilter,
          salesRep: salesRepFilter === "any" ? null : (salesRepFilter as any)._id,
          onlineSalesRep: onlineSalesRepFilter === "any" ? null : (onlineSalesRepFilter as any)._id,
          source: sourceFilter === "any" ? null : sourceFilter,
          emailUnsubscribed: emailUnsubscribedFilter === "any" ? null : emailUnsubscribedFilter,
          textUnsubscribed: textUnsubscribedFilter === "any" ? null : textUnsubscribedFilter,
          connected: connectedFilter === "any" ? null : connectedFilter,
        },
        comments: false,
      },
    });
  };

  const handleQueryParams = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setSearchParams({ page: (newPage + 1).toString(), row: perPage.toString() });
    setPageNumber(newPage);
  };

  const handlePerRows = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPerPage(parseInt(event.target.value, 10));
    setSearchParams({ row: event.target.value, page: "1" });
    setPageNumber(1);
  };

  return (
    <div>
      <SettingContainer>
        {listid !== "purchasers" ? (
          <RegistrantTable
            handleGlobalFilterValue={handleGlobalFilterValue}
            selectedRegistrants={selectedRegistrants}
            purchasers={[]}
            columns={columns}
            loading={loading}
            id={listid}
            name={name}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            handleQueryParams={handleQueryParams}
            perPage={perPage}
            handlePerRows={handlePerRows}
            realtorTypeFilter={realtorTypeFilter}
            setRealtorTypeFilter={setRealtorTypeFilter}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            ratingFilter={ratingFilter}
            setRatingFilter={setRatingFilter}
            salesRepFilter={salesRepFilter}
            setSalesRepFilter={setSalesRepFilter}
            onlineSalesRepFilter={onlineSalesRepFilter}
            setOnlineSalesRepFilter={setOnlineSalesRepFilter}
            sourceFilter={sourceFilter}
            setSourceFilter={setSourceFilter}
            emailUnsubscribedFilter={emailUnsubscribedFilter}
            setEmailUnsubscribedFilter={setEmailUnsubscribedFilter}
            textUnsubscribedFilter={textUnsubscribedFilter}
            setTextUnsubscribedFilter={setTextUnsubscribedFilter}
            connectedFilter={connectedFilter}
            setConnectedFilter={setConnectedFilter}
            startDateFilter={startDateFilter}
            endDateFilter={endDateFilter}
            cameInStartDateFilter={cameInStartDateFilter}
            cameInEndDateFilter={cameInEndDateFilter}
          />
        ) : (
          <RegistrantTable
            handleGlobalFilterValue={handleGlobalPurchaserValue}
            purchasers={purchasers}
            selectedRegistrants={selectedPurchasers}
            columns={purchasersColumns}
            loading={loading}
            id={listid}
            name={name}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            getPurchasers={getPurchasers}
            purchaserCount={count}
            handleQueryParams={handleQueryParams}
            perPage={perPage}
            handlePerRows={handlePerRows}
          />
        )}
      </SettingContainer>
    </div>
  );
};

const GETREGISTRANTSFROMLIST = gql`
  query getRegistrantsFromList(
    $project: MongoID
    $listId: MongoID!
    $page: Float!
    $perPage: Float!
    $search: String
    $filter: RegistrantsFromListFilter
    $comments: Boolean
  ) {
    getRegistrantsFromList(
      project: $project
      listId: $listId
      page: $page
      perPage: $perPage
      search: $search
      filter: $filter
      comments: $comments
    ) {
      count
      registrants {
        _id
        firstName
        lastName
        email
        primaryPhone
        rating
        ethnicity
        statuses {
          name
          createdAt
        }
        salesRep {
          _id
          fullName
          firstName
          lastName
        }
        onlineSalesRep {
          _id
          fullName
          firstName
          lastName
        }
        process {
          name
          currentProcess
          steps {
            completed
            dueDate
            name
          }
        }
        source
        count {
          createdAt
        }
        realtorType
        emailUnsubscribed {
          type
          date
        }
        textUnsubscribed {
          type
          date
        }
        connections {
          date
          user {
            _id
            fullName
          }
        }
        createdAt
      }
    }
  }
`;

const GETALLREGISTRANTS = gql`
  query getRegistrantsFromList(
    $project: MongoID
    $listId: MongoID!
    $page: Float!
    $perPage: Float!
    $search: String
    $filter: RegistrantsFromListFilter
  ) {
    getRegistrantsFromList(project: $project, listId: $listId, page: $page, perPage: $perPage, search: $search, filter: $filter) {
      count
      registrants {
        _id
      }
    }
  }
`;

const GETPURCHASERS = gql`
  query getPurchasers($projects: [MongoID!], $page: Float!, $perPage: Float!, $search: String, $download: Boolean) {
    getPurchasers(projects: $projects, page: $page, perPage: $perPage, search: $search, download: $download) {
      purchasers {
        _id {
          purchaser {
            _id
            firstName
            lastName
            email
            primaryPhone
          }
        }
        realtor {
          fullName
        }
        firmDate
        registrant {
          _id
          comments {
            _id
          }
          connections {
            date
            user {
              _id
              fullName
            }
          }
        }
        suite
      }
      count
    }
  }
`;

const GETLIST = gql`
  query listById($_id: MongoID!) {
    listById(_id: $_id) {
      _id
      name
      registrantStart
      registrantEnd
    }
  }
`;

const UPDATEREGISTRANT = gql`
  mutation registrantUpdateById($_id: MongoID!, $record: UpdateByIdRegistrantInput!) {
    registrantUpdateById(_id: $_id, record: $record) {
      record {
        _id
        rating
        salesRep {
          _id
          firstName
          lastName
          fullName
        }
        onlineSalesRep {
          _id
          firstName
          lastName
          fullName
        }
      }
    }
  }
`;

const UPDATEREGISTRANTPROCESS = gql`
  mutation updateRegistrantProcess($user: MongoID, $registrant: MongoID!, $type: String!) {
    updateRegistrantProcess(user: $user, registrant: $registrant, type: $type) {
      registrant {
        _id
        rating
        salesRep {
          _id
          firstName
          lastName
          fullName
        }
        onlineSalesRep {
          _id
          firstName
          lastName
          fullName
        }
      }
      process {
        _id
        currentProcess
        default
        steps {
          _id
          completed
          default
          dueDate
          type
          emailTemplate {
            name
            _id
          }
          user {
            _id
            fullName
          }
          name
        }
        users {
          _id
          fullName
        }
        timeBetweenSteps
        processType
        name
      }
    }
  }
`;

export default ListTable;
