import { useState } from "react";
import { SettingContainer } from "../../commonStyles";
import { Box, Button, Typography, Select, FormControl, InputLabel, ListSubheader, MenuItem, SelectChangeEvent } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { getRegistrantsFromExcel, camelToNormal } from "../../utils/function";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { gql, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { selectProject } from "../../features/project/projectSlice";
import { IProcessTemplate } from "../../types/process";
import { selectProjectProcesses } from "../../features/projectSetting/projectSettingSlice";

const UploadRegistrant = () => {
  const project = useSelector(selectProject);
  const dispatch = useAppDispatch();
  const [process, setProcess] = useState<string>("");
  const processes = useSelector(selectProjectProcesses);

  const [uploadRegistrants] = useMutation(UPLOADREGISTRANTS, {
    onCompleted: (data) => {
      dispatch(showSuccessSnackbar("Registrants Uploaded"));
    },
    onError: (e) => {
      dispatch(
        showErrorSnackbar(
          "There was an error uploading the registrants. Possible errors include a network error or an invalid value being entered into a field."
        )
      );
    },
  });

  return (
    <SettingContainer>
      <Typography sx={{ fontSize: "1.5rem" }}>
        <strong>Upload Registrants</strong>
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Typography sx={{ mb: 1, fontSize: "1.25rem" }}>
          <strong>Instructions</strong>
        </Typography>
        <Typography>
          -You can upload an excel file with the <strong>headers as the top row</strong>, and information for new registrants in the
          following rows
        </Typography>
        <Typography>
          -The <strong>order of the headers doesn't matter</strong>, as long as they are on the first row
        </Typography>
        <Typography>
          -Any headers not in the list (mandatory + optional) below will have those <strong>columns filtered out automatically</strong>
        </Typography>
        <Typography>
          -The table must be <strong>aligned to the top left</strong> of the document
        </Typography>
        <Typography>
          -Only the <strong>first worksheet</strong> in the workbook will be extracted
        </Typography>
        <Typography>
          -<strong>Please REMOVE any duplicate emails in the excel sheet.</strong>
        </Typography>
        <Typography sx={{ mt: 2 }}>The following headers are mandatory:</Typography>
        <ul>
          <li>
            <em>firstName</em>
          </li>
          <li>
            <em>lastName</em>
          </li>
          <li>
            <em>email</em>
          </li>
        </ul>
        <Typography>
          The following headers are optional. If no value is provided, the default value listed beside the header is used:
        </Typography>
        <ul>
          <li>
            <em>rating</em> (default = "N", options: [A, B, C, NQ, N, R, P])
          </li>
          <li>
            <em>realtorType</em> (default = "noRealtor", options: [noRealtor, isRealtor, hasRealtor])
          </li>
          <li>
            <em>source</em> (default = "upload", options: [call in, walk in, online registration, livabl, booked, social media, email,
            agent, google, facebook, signage, other, sale, mls, broker bay, ])
          </li>
          <li>
            <em>primaryPhone</em> (default = "") <strong>Please format the phone as XXX-XXX-XXXX</strong>
          </li>
          <li>
            <em>postalCode</em> (default = "")
          </li>
          <li>
            <em>realtorName</em> (default = "")
          </li>
          <li>
            <em>realtorEmail</em> (default = "")
          </li>
          <li>
            <em>brokerage</em> (default = "")
          </li>
          <li>
            <em>tags</em> (default = "")
          </li>
        </ul>
      </Box>
      <FormControl sx={{ mb: 2, width: "400px" }}>
        <InputLabel id="demo-simple-select-label">Process</InputLabel>
        <Select
          labelId="demo-simple-select-process"
          id="demo-simple-select"
          required
          value={process}
          label="Process"
          onChange={(e: SelectChangeEvent<string>) => setProcess(e.target.value)}
        >
          {Object.entries(
            processes.reduce(
              (processGroup: any, process: IProcessTemplate) => {
                if (process) {
                  const { type } = process;
                  processGroup[type] = processGroup[type] ?? [];
                  processGroup[type].push(process);
                }
                return processGroup;
              },
              { shortTerm: [], event: [], handover: [], sales: [], longTerm: [] }
            )
          ).map((processGroup: any, i: number) => [
            <ListSubheader key={i}>
              <strong>{camelToNormal(processGroup[0])}</strong>
            </ListSubheader>,
            processGroup[1].map((process: IProcessTemplate, index: number) => {
              return (
                <MenuItem sx={{ pl: 4 }} key={index} value={process._id}>
                  {process.name}
                </MenuItem>
              );
            }),
          ])}
        </Select>
      </FormControl>
      <Box sx={{ mt: 2, mb: 2 }}>
        <label htmlFor="button-file">
          <input
            accept="*"
            id="button-file"
            style={{ display: "none" }}
            type="file"
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              const elem = e.target as HTMLInputElement;
              elem.value = "";
            }}
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              if (!e.target.files || !e.target.files[0]) {
                dispatch(showErrorSnackbar("A file was not selected."));
                return;
              }

              const file = e.target.files[0];

              e.target.files = null;
              let data = await getRegistrantsFromExcel(file);
              uploadRegistrants({ variables: { registrants: data, project: project?._id, process: process } });
            }}
          />
          <Button variant="contained" color="success" component="span" startIcon={<Upload />}>
            Upload Excel File
          </Button>
        </label>
      </Box>
    </SettingContainer>
  );
};

const UPLOADREGISTRANTS = gql`
  mutation uploadRegistrants($registrants: [createRegistrantsNoDuplicatesInput]!, $project: MongoID!, $process: MongoID!) {
    createRegistrantsNoDuplicates(registrants: $registrants, project: $project, process: $process) {
      email
      firstName
      lastName
    }
  }
`;

export default UploadRegistrant;
