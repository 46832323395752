import { format, formatDuration, intervalToDuration, subHours, addHours } from "date-fns";
import saveAs from "file-saver";
import ExcelJS from "exceljs";
import { PDFDocument, rgb, grayscale, PageSizes, StandardFonts } from "pdf-lib";
import { allTokenValues } from "./constant";
import { IUser } from "../types/user";
import { IRegistrant } from "../types/registrant";
import { IProject } from "../types/project";
import { addDays } from "date-fns";
import { IQuestion } from "../types/question";
import { el } from "date-fns/locale";

export const numToCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const convertAllDates = (timestamp: any, dateFormat: string) => {
  return format(new Date(timestamp), dateFormat);
};

export const humanDuration = (time: number) => {
  return time ? formatDuration(intervalToDuration({ start: 0, end: time * 1000 })) : 0;
};

export const urlName = (string: string) => {
  return string.replace(/\s+/g, "-").toLowerCase();
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeFirstLetterEachWord = (string: string) => {
  const words = string.split(" ");
  let ret = "";
  for (let word of words) {
    ret += word.charAt(0).toUpperCase();
    ret += word.slice(1);
    ret += " ";
  }
  if (words.length > 0) ret = ret.slice(0, ret.length - 1);
  return ret;
};

export const normalToHyphen = (string: string) => {
  if (string) {
    return string.replace(/\s+/g, "-").toLowerCase();
  } else return "";
};

export const camelToNormal = (string: string) => {
  const result = string.replace(/([A-Z])/g, " $1");
  const convert = result.charAt(0).toUpperCase() + result.slice(1);
  return convert;
};

export const hyphenToTitle = (string: string) => {
  const result = capitalizeFirstLetterEachWord(string.replace(/([A-Z])/g, " $1"));
  return result;
};

export const dashToTitle = (string: string) => {
  return capitalizeFirstLetterEachWord(string.replace(/-/g, ' '))
}

export const normalToCamel = (string: string) => {
  return string.toLocaleLowerCase().replace(/\s+(.)/g, function (match, group) {
    return group.toUpperCase();
  });
};

export const properCase = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return "";
  let num = value.replace(/[^\d]/g, "");
  if (num.length === 11 && num[0] === "1") {
    num = num.substring(1, 11);
  }
  return `${
    num.length > 3 && num.length < 11 ? `${num.slice(0, 3)}-${num.length > 6 ? `${num.slice(3, 6)}-${num.slice(6)}` : num.slice(3)}` : num
  }`;
};

export const humanDateAndTime = (val: Date) => {
  let hour = val.getHours() % 12;
  if (val.getHours() === 12) hour = 12;
  const amPm = val.getHours() >= 12 ? "pm" : "am";
  return `${hour}:${val.getMinutes() < 10 ? "0" : ""}${val.getMinutes()}${amPm} ${val.toDateString()}`;
};

export const validateEmail = (email: string) => {
  let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
};

export const validatePhoneNumber = (phone: string) => {
  // eslint-disable-next-line
  let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,}$/i;
  return re.test(phone);
};

export const validatePassword = (password: string) => {
  // eslint-disable-next-line
  let re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/i;
  return re.test(password);
};

export function download(file: any, filename: string, type: string) {
  const element = document.createElement("a");
  element.download = filename;
  let binaryData = [];
  binaryData.push(file);
  element.href = URL.createObjectURL(new Blob(binaryData, { type: type }));
  element.click();
}

const hexToPdfRgb = (hex: string | undefined) => {
  if (!hex || hex.length !== 7) return rgb(1, 1, 1);
  return rgb(parseInt(hex.substring(1, 3), 16) / 255, parseInt(hex.substring(3, 5), 16) / 255, parseInt(hex.substring(5, 7), 16) / 255);
};

export const downloadExcel = (
  data: any[],
  headers: any[],
  backgrounds: any[],
  columnWidths: any[],
  sheetName: string[],
  fileName: string
) => {
  const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const workbook = new ExcelJS.Workbook(); // Create Workbook

  data.forEach((values, index) => {
    const worksheet = workbook.addWorksheet(sheetName[index]); // Create Worksheet

    let headerIds = headers[index].map((header: any) => header.id);
    let headerLabels = headers[index].map((header: any) => header.label);

    worksheet.columns = headerIds.map((header: any, index: number) => ({
      header: headerLabels[index],
      key: header,
      width: columnWidths[0][0][header],
    })); //
    worksheet.columns.forEach((column) => {
      column.border = {
        top: { style: "thick" },
        left: { style: "thick" },
        bottom: { style: "thick" },
        right: { style: "thick" },
      };
    });
    // Headers
    const headerRow = worksheet.getRow(index + 1);
    headerIds.forEach((header: any) => {
      headerRow.getCell(header).fill = {
        // Add the background color if it is defined
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00142a" },
      };
      headerRow.getCell(header).font = {
        color: { argb: "ffffff" },
      };
    });

    values.forEach((value: any, index: any) => {
      const row = worksheet.getRow(index + 2);
      row.values = value;
      headerIds.forEach((header: any) => {
        row.getCell(header).fill = {
          // Add the background color if it is defined
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffffff" },
        };
      });
      row.height = 20;
      row.commit();
    });
  });

  workbook.xlsx.writeBuffer().then((file: any) => {
    const blob = new Blob([file], { type: EXCEL_TYPE });
    saveAs(blob, fileName);
  });
};

export const getRegistrantsFromExcel = async (file: File) => {
  const workbook = new ExcelJS.Workbook();
  const fileBuffer = await file.arrayBuffer();
  await workbook.xlsx.load(fileBuffer);
  const json = JSON.stringify(workbook.model);

  let workbook2 = new ExcelJS.Workbook();
  workbook2.model = JSON.parse(json);
  let worksheet: any = workbook2.model.worksheets[0];

  let headers = worksheet.rows[0].cells.map((header: any) => header.value);

  let rows = worksheet.rows.slice(1);

  rows = rows.map((row: any) => {
    let cells = row.cells.map((cell: any, index: number) => {
      if (cell.value) {
        return {
          [headers[index]]: cell.value,
        };
      } else if (cell.text) {
        return {
          [headers[index]]: cell.text,
        };
      }
    });
    return Object.assign({}, ...cells);
  });

  return rows;
};

export const downloadPdf = async (
  data: any[],
  headers: any[],
  backgrounds: any[],
  columnWidths: any,
  pdfTitles: string[],
  fileName: string,
  projectLogo: any = null
) => {
  if (!data.length) return;
  // Constants
  const padding = 20;
  const fontSize = 12;
  const realtorFontSize = 100;
  const textOffset = 5;
  const cellPadding = 5;
  const rowHeight = cellPadding * 2 + fontSize * 3 + textOffset;
  const headerHeight = cellPadding * 2 + fontSize + textOffset;

  // Get text width
  const context = document.createElement("canvas").getContext("2d");
  context && (context.font = fontSize + "px sans-serif");
  const realtorContext = document.createElement("canvas").getContext("2d");
  realtorContext && (realtorContext.font = realtorFontSize + "px sans-serif");

  // Get Page Dimensions

  const pdf = await PDFDocument.create();

  for (const [index, values] of data.entries()) {
    let headerIds = headers[index].map((header: any) => header.id);
    let headerLabels = headers[index].map((header: any) => header.label);

    const tableWidth = headerIds.reduce((prevValue: any, key: number) => prevValue + columnWidths[index][key] + cellPadding * 2, 0);
    const width = padding * 2 + tableWidth;
    const height = padding * 2 + rowHeight * values.length + headerHeight * 3;

    let page = pdf.addPage([width, height]); // Get page
    page.moveTo(padding, height - padding); // Add padding
    page.moveDown(headerHeight);

    let rdsLogoImage;
    const rdsLogoBytes = await fetch("https://rdsre.s3.ca-central-1.amazonaws.com/assets/logo.jpg").then((res) =>
      res.ok ? res.arrayBuffer() : null
    );
    if (rdsLogoBytes) rdsLogoImage = await pdf.embedJpg(rdsLogoBytes);

    let projectLogoImage;
    if (projectLogo) {
      const projectLogoBytes = await fetch(projectLogo).then((res) => res.arrayBuffer());
      projectLogoImage = await pdf.embedJpg(projectLogoBytes);
      page.drawImage(projectLogoImage, {
        x: page.getX() + cellPadding,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        height: 25,
        width: 40,
      });
    }

    if (rdsLogoImage) {
      page.drawImage(rdsLogoImage, {
        x: page.getWidth() - page.getX() - 25,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        height: 25,
        width: 25,
      });
      page.moveDown(headerHeight);
    }

    page.drawText(pdfTitles[index], {
      x: page.getX() + cellPadding,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: fontSize + 4,
      lineHeight: fontSize / 2,
    });

    page.drawText(convertAllDates(new Date(), "PPpp"), {
      x: page.getWidth() - page.getX() - 200,
      y: page.getY() + headerHeight - fontSize - cellPadding,
      size: fontSize + 4,
      lineHeight: fontSize / 2,
    });

    page.moveDown(headerHeight);

    for (const [i, header] of headerIds.entries()) {
      page.drawRectangle({ height: headerHeight, width: columnWidths[index][header] + cellPadding * 2, color: hexToPdfRgb("#00142a") });
      page.drawText(headerLabels[i], {
        x: page.getX() + cellPadding,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        size: fontSize,
        color: rgb(1, 1, 1),
        lineHeight: fontSize / 2,
      });
      page.moveRight(columnWidths[index][header] + cellPadding * 2);
    }
    page.moveLeft(tableWidth);

    // eslint-disable-next-line
    for (const [i, value] of values.entries()) {
      // Add rows
      page.moveDown(rowHeight);
      headerIds.forEach((header: any) => {
        page.drawRectangle({
          height: rowHeight,
          width: columnWidths[index][header] + cellPadding * 2,
          borderWidth: 1,
          borderColor: grayscale(0.5),
        });
        page.drawText(value[header] ? value[header].toString() : "0", {
          x: page.getX() + cellPadding,
          y: page.getY() + rowHeight - fontSize - cellPadding,
          size: fontSize,
          lineHeight: fontSize / 2,
        });
        page.moveRight(columnWidths[index][header] + cellPadding * 2);
      });
      page.moveLeft(tableWidth);
    }
    page.moveDown(headerHeight);
  }

  const pdfFile = await pdf.save();
  download(pdfFile, fileName, "application/pdf");
};

export const downloadReport = async (title: string, graphs: any[]) => {
  if (!graphs.length) return;

  let pageSettings = {
    padding: 20,
    fontSize: 12,
    pageNumber: 1,
  };

  const pdf = await PDFDocument.create();

  // let rdsLogoImage;
  // const rdsLogoBytes = await fetch("https://rdsre.s3.ca-central-1.amazonaws.com/assets/logo.jpg").then((res) => res.arrayBuffer());
  // rdsLogoImage = await pdf.embedJpg(rdsLogoBytes);

  for (let i = 0; i < graphs.length; i += 2) {
    // draw logo and title
    const width = 600;
    const height = 600;

    let page = pdf.addPage([width, height]);
    page.moveTo(pageSettings.padding, height - 1.5 * pageSettings.padding);

    drawCentredText(title, page.getWidth() - 2 * pageSettings.padding, 0, page, rgb(0, 0, 0), pageSettings.fontSize + 4);

    page.moveTo(pageSettings.padding, height - pageSettings.padding);

    // if (rdsLogoImage) {
    //   page.drawImage(rdsLogoImage, {
    //     x: page.getWidth() - pageSettings.padding - 25,
    //     y: page.getY() - pageSettings.padding,
    //     height: 25,
    //     width: 25,
    //   });
    // }
    page.moveTo(pageSettings.padding, height - pageSettings.padding);
    page.moveDown(pageSettings.padding);

    // draw four graphs / tables
    for (let j = i; j < i + 2 && j < graphs.length; j++) {
      const boxWidth = page.getWidth() - 4 * pageSettings.padding;
      const boxHeight = (page.getHeight() - 5.5 * pageSettings.padding - (4 / 3) * (pageSettings.fontSize + 4)) / 2;

      if (graphs[j].image) {
        await drawGraph(boxWidth, boxHeight, pdf, page, pageSettings, graphs[j]);
      } else if (graphs[j].table) {
        drawTable(boxWidth, boxHeight, page, pageSettings, graphs[j]);
      }

      page.moveDown(boxHeight + pageSettings.padding);
    }

    page.drawText(pageSettings.pageNumber.toString(), {
      x: (page.getWidth() - pageSettings.padding - getWidthOfText(pageSettings.pageNumber.toString(), pageSettings.fontSize - 4)) / 2,
      y: pageSettings.padding / 2,
      size: pageSettings.fontSize - 4,
    });
    ++pageSettings.pageNumber;
  }

  const pdfFile = await pdf.save();
  download(pdfFile, `${title}.pdf`, "application/pdf");
};

export const getWidthOfText = (text: string, fontSize: number = 12, font: string = "times new roman") => {
  const textElement = document.createElement("span");
  document.body.appendChild(textElement);

  textElement.style.font = font;
  textElement.style.fontSize = `${fontSize}px`;
  textElement.style.height = "auto";
  textElement.style.width = "auto";
  textElement.style.position = "absolute";
  textElement.style.whiteSpace = "no-wrap";
  textElement.innerHTML = text;

  const width = textElement.clientWidth;
  document.body.removeChild(textElement);
  return width;
};

export const blobToBase64 = (url: string) => {
  return new Promise(async (resolve, _) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileReader = new FileReader();
    fileReader.readAsDataURL(blob);
    fileReader.onloadend = function () {
      resolve(fileReader.result);
    };
  });
};

const drawGraph = async (boxWidth: number, boxHeight: number, pdf: any, page: any, pageSettings: any, graph: any) => {
  let graphImage;
  try {
    graphImage = await pdf.embedPng(graph.image);
  } catch (e) {}

  if (graph.label.length) {
    page.moveDown((4 / 3) * pageSettings.fontSize);
    drawCentredText(graph.label, boxWidth, 0, page, grayscale(0.45), pageSettings.fontSize);
    page.moveDown(pageSettings.padding / 3);
  }

  const graphHeight = !graph.label.length ? boxHeight : boxHeight - (4 / 3) * pageSettings.fontSize - pageSettings.padding / 3;

  page.moveDown(graphHeight);
  page.drawImage(graphImage, {
    x: page.getX(),
    y: page.getY(),
    width: boxWidth,
    height: graphHeight,
  });
  page.moveUp(boxHeight);
};

const drawTable = (boxWidth: number, boxHeight: number, page: any, pageSettings: any, tableConfig: any) => {
  if (tableConfig.label.length) {
    page.moveDown((4 / 3) * pageSettings.fontSize);
    drawCentredText(tableConfig.label, boxWidth, 0, page, grayscale(0.45), pageSettings.fontSize);
    page.moveDown(pageSettings.padding / 3);
  }

  const tableHeight = !tableConfig.label.length ? boxHeight : boxHeight - (4 / 3) * pageSettings.fontSize - pageSettings.padding / 3;

  const numberOfHeaders = tableConfig.table.headers.length;
  const numberOfRows = tableConfig.table.data.length;
  const widthOfEachCell = boxWidth / numberOfHeaders;
  const heightOfEachCell = tableHeight / (numberOfRows + 1);

  page.moveDown(heightOfEachCell);

  for (let header of tableConfig.table.headers) {
    page.drawRectangle({
      height: heightOfEachCell,
      width: widthOfEachCell,
      borderWidth: 1,
      color: rgb(0, 0, 0),
    });
    page.moveUp(heightOfEachCell);
    drawCentredText(header, widthOfEachCell, heightOfEachCell, page, rgb(1, 1, 1), pageSettings.fontSize);
    page.moveDown(heightOfEachCell);
    page.moveRight(widthOfEachCell);
  }
  page.moveDown(heightOfEachCell);
  page.moveLeft(widthOfEachCell * numberOfHeaders);

  for (let tableObj of tableConfig.table.data) {
    for (let header of tableConfig.table.headers) {
      page.drawRectangle({
        height: heightOfEachCell,
        width: widthOfEachCell,
        borderWidth: 1,
        borderColor: grayscale(0.5),
      });

      page.moveUp(heightOfEachCell);
      drawCentredText(
        tableObj[normalToCamel(header)].toString(),
        widthOfEachCell,
        heightOfEachCell,
        page,
        rgb(0, 0, 0),
        pageSettings.fontSize
      );
      page.moveDown(heightOfEachCell);

      page.moveRight(widthOfEachCell);
    }
    page.moveLeft(widthOfEachCell * numberOfHeaders);
    page.moveDown(heightOfEachCell);
  }
  page.moveUp(boxHeight);
};

const drawCentredText = (text: string, boxWidth: number, boxHeight: number, page: any, colour: any, fontSize: number) => {
  const heightOfText = boxHeight ? fontSize : 0;
  let centredSpaceX = (boxWidth - getWidthOfText(text, fontSize)) / 2;
  let centredSpaceY = boxHeight ? (boxHeight - heightOfText) / 2 : 0;

  page.moveRight(centredSpaceX);
  page.moveDown(centredSpaceY + heightOfText);
  page.drawText(text, {
    x: page.getX(),
    y: page.getY(),
    maxWidth: boxWidth,
    size: fontSize,
    color: colour,
    lineHeight: fontSize,
  });
  page.moveLeft(centredSpaceX);
  page.moveUp(centredSpaceY + heightOfText);
};

export function dataURItoBlob(dataURI: any) {
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {
    type: mimeString,
  });
}

export const eventColour = (id: number) => {
  if (id === 0) return "#eda63c";
  if (id === 1) return "#3ced72";
  if (id === 2) return "#3c83ed";
  if (id === 3) return "#ff2323";
  if (id === 4) return "#bd59c9";
  if (id === 5) return "#c2c959";
  if (id === 6) return "#7a59c9";
  if (id === 7) return "#3dc3c3";
  if (id === 8) return "#3dc385";
  if (id === 9) return "#654d4d";
  if (id === 10) return "#000000";
};

export const getLabels = (startDate: Date | null | undefined, endDate: Date | null | undefined) => {
  if (!startDate || !endDate) return [];
  const fourteenDays = 1209600000;
  const ninetyDays = 7776000000;
  const timeDifference = endDate.getTime() - startDate.getTime();

  let labels = [];
  let startDateIndex = new Date(startDate);

  if (timeDifference < fourteenDays) {
    while (startDateIndex.getTime() < endDate.getTime()) {
      labels.push(startDateIndex.toDateString());
      startDateIndex = new Date(startDateIndex.getFullYear(), startDateIndex.getMonth(), startDateIndex.getDate() + 1);
    }
  } else if (timeDifference < ninetyDays) {
    while (startDateIndex.getTime() < endDate.getTime()) {
      const eachPartOfDate = startDateIndex.toDateString().split(" ");
      const labelDate = `${eachPartOfDate[1]} ${eachPartOfDate[2]} ${eachPartOfDate[3]}`;
      labels.push(labelDate);
      startDateIndex = new Date(startDateIndex.getFullYear(), startDateIndex.getMonth(), startDateIndex.getDate() + 7);
    }
  } else {
    while (startDateIndex.getTime() < endDate.getTime()) {
      const eachPartOfDate = startDateIndex.toDateString().split(" ");
      const labelDate = `${eachPartOfDate[1]} ${eachPartOfDate[2]} ${eachPartOfDate[3]}`;
      labels.push(labelDate);
      startDateIndex = new Date(startDateIndex.getFullYear(), startDateIndex.getMonth() + 1, startDateIndex.getDate());
    }
  }

  return labels;
};

export const handleTextReplacement = (text: string, registrant: IRegistrant, user: IUser, project: IProject) => {
  let newText = text;
  for (const value of allTokenValues) {
    if (text.includes(value)) {
      if (value === "{{registrantFirstName}}") {
        newText = newText.replace(value, registrant.firstName);
      }
      if (value === "{{registrantLastName}}") {
        newText = newText.replace(value, registrant.lastName);
      }
      if (value === "{{registrantEmail}}") {
        newText = newText.replace(value, registrant.email);
      }
      if (value === "{{registrantPhone}}") {
        newText = newText.replace(value, registrant.primaryPhone);
      }
      if (value === "{{registrantProject}}") {
        newText = newText.replace(value, registrant.project.name);
      }
      if (value === "{{projectTagLine}}") {
        newText = newText.replace(value, registrant.project.tagLine);
      }
      if (value === "{{projectWebsite}}") {
        newText = newText.replace(
          value,
          `${
            process.env.REACT_APP_ENV === "local"
              ? `http://localhost:3001/${normalToHyphen(project.name)}`
              : `https://portal.rdsre.ca/${normalToHyphen(project.name)}`
          }`
        );
      }
      if (value === "{{appointment}}") {
        newText = newText.replace(
          value,
          `${
            process.env.REACT_APP_ENV === "local"
              ? `http://localhost:3001/${normalToHyphen(project.developerName)}/${normalToHyphen(project.name)}/public/book-an-appointment`
              : `https://portal.rdsre.ca/${normalToHyphen(project.developerName)}/${normalToHyphen(
                  project.name
                )}/public/book-an-appointment`
          }`
        );
      }
      if (value === "{{userFirstName}}") {
        newText = newText.replace(value, user.firstName);
      }
      if (value === "{{userLastName}}") {
        newText = newText.replace(value, user.lastName);
      }
      if (value === "{{userEmail}}}") {
        newText = newText.replace(value, user.email);
      }
      if (value === "{{userProject}}") {
        newText = newText.replace(value, project._id);
      }
    }
  }
  return newText;
};

export const getNumChars = (s: string, n: number) => {
  let retval = "";
  let count = 0;
  for (let i = 0; i < s.length; ++i) {
    if (count === n) return retval;
    if (s[i] === " ") {
      continue;
    }
    retval += s[i];
    ++count;
  }
  return retval;
};

export const timeZoneDate = (date: Date) => {
  if (new Date(date).getTimezoneOffset() <= 0) {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60 + 5;
    return new Date(subHours(new Date(date), hours));
  } else {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60;
    if (hours <= 5) {
      return new Date(subHours(new Date(date), 5 - hours));
    } else {
      return new Date(addHours(new Date(date), hours - 5));
    }
  }
};

export const addTimeZoneDate = (date: Date) => {
  if (new Date(date).getTimezoneOffset() <= 0) {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60 + 5;
    return new Date(subHours(new Date(date), hours));
  } else {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60;
    if (hours <= 5) {
      return new Date(addHours(new Date(date), 5 - hours));
    } else {
      return new Date(addHours(new Date(date), hours - 5));
    }
  }
};

export const timeZoneHour = (date: Date) => {
  if (new Date(date).getTimezoneOffset() <= 0) {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60 + 5;
    return hours;
  } else {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60;
    return 5 - Math.abs(hours);
  }
};

export const downloadCustomReportPdf = async (
  registrant: IRegistrant[],
  project: IProject,
  dateStart: Date,
  dateEnd: Date,
  allLeads: boolean,
  newLeads: boolean,
  connected: boolean,
  realtorTypesCheck: string[],
  ratingsCheck: string[],
  sourcesCheck: string[],
  ethnicitiesCheck: string[],
  callActivity: any,
  questionsCheck: string[],
  salesQuestionsCheck: string[],
  projectQuestions: any[],
  tablesState: any[]
) => {
  const pdf = await PDFDocument.create();
  const helveticaBold = await pdf.embedFont(StandardFonts.HelveticaBold);
  const helvetica = await pdf.embedFont(StandardFonts.Helvetica);

  const page = pdf.addPage(PageSizes.Legal);

  const rdsLogoBytes = await fetch("https://rdsappimages.s3.ca-central-1.amazonaws.com/assets/rds/logo.png", { cache: "no-cache" }).then(
    (res) => res.arrayBuffer()
  );

  let rdsLogoImage = await pdf.embedPng(rdsLogoBytes);
  let rdsRatio = (rdsLogoImage.width / rdsLogoImage.height) * 30;

  if (project.logoGetUrl) {
    let logoUrl = project.logoGetUrl.split("?X")[0];

    const projectLogoBytes = await fetch(logoUrl, { cache: "no-cache" }).then((res) => res.arrayBuffer());
    let projectLogoImage = await pdf.embedJpg(projectLogoBytes);

    let logoRatio = (projectLogoImage.width / projectLogoImage.height) * 30;

    page.drawImage(projectLogoImage, {
      x: 30,
      y: 960,
      height: 30,
      width: logoRatio,
    });
  }

  if (rdsLogoImage) {
    page.drawImage(rdsLogoImage, {
      x: 540,
      y: 960,
      height: 30,
      width: rdsRatio,
    });
  }

  page.moveTo(0, 910);

  const titleWidth = helveticaBold.widthOfTextAtSize(
    `${project.name} Report (${convertAllDates(dateStart, "MM/dd/yyyy")} - ${convertAllDates(
      new Date(addDays(dateEnd, 1)),
      "MM/dd/yyyy"
    )})`,
    20
  );
  page.drawText(
    `${project.name} Report (${convertAllDates(dateStart, "MM/dd/yyyy")} - ${convertAllDates(
      new Date(addDays(dateEnd, 1)),
      "MM/dd/yyyy"
    )})`,
    {
      x: page.getWidth() / 2 - titleWidth / 2,
      y: page.getY(),
      size: 20,
      lineHeight: 10,
    }
  );

  page.moveRight(30);
  page.moveDown(40);
  let countWidth = helvetica.widthOfTextAtSize(`Count`, 9);

  let registrantTotal = registrant.length;
  let allTotal = registrant.length;
  let registrants = registrant;
  let registrantConnectedCount = 0;

  if (newLeads) {
    registrants = registrant.filter(
      (registrant: IRegistrant) =>
        new Date(dateStart).valueOf() < new Date(registrant?.createdAt!).valueOf() &&
        new Date(registrant?.createdAt!).valueOf() < new Date(dateEnd).valueOf()
    );
    registrantTotal = registrants.length;
  }

  if (connected) {
    registrantConnectedCount = registrant.filter((registrant: IRegistrant) => registrant.connections.length).length;
  }

  // All Leads
  if (allLeads) {
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const titleWidth = helveticaBold.widthOfTextAtSize("Total Number of Leads", 9);
    page.drawText("Total Number of Leads", {
      x: 155 - titleWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(1, 1, 1),
    });
    let totalWidth = helvetica.widthOfTextAtSize(`${allTotal}`, 9);
    page.drawText(`${allTotal}`, {
      x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
      y: page.getY() + 4,
      size: 9,
      lineHeight: 2,
    });
    page.moveDown(30);
    page.moveTo(30, page.getY());
  }

  if (allLeads) {
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const titleWidth = helveticaBold.widthOfTextAtSize("Number of New Leads", 9);
    page.drawText("Number of New Leads", {
      x: 155 - titleWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(1, 1, 1),
    });
    const totalWidth = helvetica.widthOfTextAtSize(`${registrantTotal}`, 9);
    page.drawText(`${registrantTotal}`, {
      x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
      y: page.getY() + 4,
      size: 9,
      lineHeight: 2,
    });
    page.moveDown(30);
    page.moveTo(30, page.getY());
  }

  if (connected) {
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const titleWidth = helveticaBold.widthOfTextAtSize("Connected", 9);
    page.drawText("Connected", {
      x: 155 - titleWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(1, 1, 1),
    });
    const totalWidth = helvetica.widthOfTextAtSize(`${registrantConnectedCount}`, 9);
    page.drawText(`${registrantConnectedCount}`, {
      x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
      y: page.getY() + 4,
      size: 9,
      lineHeight: 2,
    });
    page.moveDown(30);
    page.moveTo(30, page.getY());
  }

  // Realtor Types
  if (realtorTypesCheck.length) {
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const titleWidth = helveticaBold.widthOfTextAtSize("Realtor Type", 9);
    page.drawText("Realtor Type", {
      x: 155 - titleWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const width = helveticaBold.widthOfTextAtSize("Count", 9);
    page.drawText(`Count`, {
      x: page.getX() + (countWidth + 20) / 2 - width / 2,
      y: page.getY() + 4,
      size: 9,
      font: helveticaBold,
      lineHeight: 2,
    });
    page.moveDown(15);
    page.moveTo(30, page.getY());
    for (const realtorType of realtorTypesCheck) {
      let total = registrants.reduce((acc, cur) => (cur.realtorType === realtorType ? ++acc : acc), 0);
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: 250,
        color: rgb(1, 1, 1),
      });
      let titleWidth = helvetica.widthOfTextAtSize(`${camelToNormal(realtorType)}`, 9);
      page.drawText(`${camelToNormal(realtorType)}`, {
        x: 155 - titleWidth / 2,
        y: page.getY() + 4,
        size: 9,
        lineHeight: 2,
      });
      page.moveRight(250);
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: countWidth + 20,
        color: rgb(1, 1, 1),
      });
      let totalWidth = helvetica.widthOfTextAtSize(`${total}`, 9);
      page.drawText(`${total}`, {
        x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
        y: page.getY() + 4,
        font: helvetica,
        size: 9,
        lineHeight: 2,
      });
      page.moveDown(15);
      page.moveTo(30, page.getY());
    }

    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    let grandWidth = helveticaBold.widthOfTextAtSize(`Grand Total`, 9);
    page.drawText("Grand Total", {
      x: 155 - grandWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    let totalWidth = helveticaBold.widthOfTextAtSize(`${registrantTotal}`, 9);
    page.drawText(`${registrantTotal}`, {
      x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
      y: page.getY() + 4,
      size: 9,
      font: helveticaBold,
      lineHeight: 2,
    });

    page.moveDown(30);
    page.moveTo(30, page.getY());
  }

  // Ratings
  if (ratingsCheck.length) {
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const titleWidth = helveticaBold.widthOfTextAtSize("Ratings", 9);
    page.drawText("Ratings", {
      x: 155 - titleWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const width = helveticaBold.widthOfTextAtSize("Count", 9);
    page.drawText(`Count`, {
      x: page.getX() + (countWidth + 20) / 2 - width / 2,
      y: page.getY() + 4,
      size: 9,
      font: helveticaBold,
      lineHeight: 2,
    });
    page.moveDown(15);
    page.moveTo(30, page.getY());
    for (const rating of ratingsCheck) {
      let total = registrants.reduce((acc, cur) => (cur.rating === rating ? ++acc : acc), 0);
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: 250,
        color: rgb(1, 1, 1),
      });
      let titleWidth = helvetica.widthOfTextAtSize(`${camelToNormal(rating)}`, 9);
      page.drawText(`${camelToNormal(rating)}`, {
        x: 155 - titleWidth / 2,
        y: page.getY() + 4,
        size: 9,
        lineHeight: 2,
      });
      page.moveRight(250);
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: countWidth + 20,
        color: rgb(1, 1, 1),
      });
      let totalWidth = helvetica.widthOfTextAtSize(`${total}`, 9);
      page.drawText(`${total}`, {
        x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
        y: page.getY() + 4,
        font: helvetica,
        size: 9,
        lineHeight: 2,
      });
      page.moveDown(15);
      page.moveTo(30, page.getY());
    }

    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    let grandWidth = helveticaBold.widthOfTextAtSize(`Grand Total`, 9);
    page.drawText("Grand Total", {
      x: 155 - grandWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    let totalWidth = helveticaBold.widthOfTextAtSize(`${registrantTotal}`, 9);
    page.drawText(`${registrantTotal}`, {
      x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
      y: page.getY() + 4,
      size: 9,
      font: helveticaBold,
      lineHeight: 2,
    });

    page.moveDown(30);
    page.moveTo(30, page.getY());
  }

  // Source
  if (sourcesCheck.length) {
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const titleWidth = helveticaBold.widthOfTextAtSize("Sources", 9);
    page.drawText("Sources", {
      x: 155 - titleWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const width = helveticaBold.widthOfTextAtSize("Count", 9);
    page.drawText(`Count`, {
      x: page.getX() + (countWidth + 20) / 2 - width / 2,
      y: page.getY() + 4,
      size: 9,
      font: helveticaBold,
      lineHeight: 2,
    });
    page.moveDown(15);
    page.moveTo(30, page.getY());
    let overallTotal = 0;
    for (const source of sourcesCheck) {
      let total = registrants.reduce((acc, cur) => (cur.source === source ? ++acc : acc), 0);
      overallTotal += total;
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: 250,
        color: rgb(1, 1, 1),
      });
      let titleWidth = helvetica.widthOfTextAtSize(`${camelToNormal(source)}`, 9);
      page.drawText(`${camelToNormal(source)}`, {
        x: 155 - titleWidth / 2,
        y: page.getY() + 4,
        size: 9,
        lineHeight: 2,
      });
      page.moveRight(250);
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: countWidth + 20,
        color: rgb(1, 1, 1),
      });
      let totalWidth = helvetica.widthOfTextAtSize(`${total}`, 9);
      page.drawText(`${total}`, {
        x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
        y: page.getY() + 4,
        font: helvetica,
        size: 9,
        lineHeight: 2,
      });
      page.moveDown(15);
      page.moveTo(30, page.getY());
    }

    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    let grandWidth = helveticaBold.widthOfTextAtSize(`Grand Total`, 9);
    page.drawText("Grand Total", {
      x: 155 - grandWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    let totalWidth = helveticaBold.widthOfTextAtSize(`${registrantTotal}`, 9);
    page.drawText(`${overallTotal}`, {
      x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
      y: page.getY() + 4,
      size: 9,
      font: helveticaBold,
      lineHeight: 2,
    });

    page.moveDown(30);
    page.moveTo(30, page.getY());
  }

  if (ethnicitiesCheck.length) {
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const titleWidth = helveticaBold.widthOfTextAtSize("Ethnicities", 9);
    page.drawText("Ethnicities", {
      x: 155 - titleWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const width = helveticaBold.widthOfTextAtSize("Count", 9);
    page.drawText(`Count`, {
      x: page.getX() + (countWidth + 20) / 2 - width / 2,
      y: page.getY() + 4,
      size: 9,
      font: helveticaBold,
      lineHeight: 2,
    });
    page.moveDown(15);
    page.moveTo(30, page.getY());
    for (const ethnicity of ethnicitiesCheck) {
      let total = registrants.reduce((acc, cur) => (cur.ethnicity === ethnicity ? ++acc : acc), 0);
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: 250,
        color: rgb(1, 1, 1),
      });
      let titleWidth = helvetica.widthOfTextAtSize(`${camelToNormal(ethnicity)}`, 9);
      page.drawText(`${camelToNormal(ethnicity)}`, {
        x: 155 - titleWidth / 2,
        y: page.getY() + 4,
        size: 9,
        lineHeight: 2,
      });
      page.moveRight(250);
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: countWidth + 20,
        color: rgb(1, 1, 1),
      });
      let totalWidth = helvetica.widthOfTextAtSize(`${total}`, 9);
      page.drawText(`${total}`, {
        x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
        y: page.getY() + 4,
        font: helvetica,
        size: 9,
        lineHeight: 2,
      });
      page.moveDown(15);
      page.moveTo(30, page.getY());
    }

    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 250,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    let grandWidth = helveticaBold.widthOfTextAtSize(`Grand Total`, 9);
    page.drawText("Grand Total", {
      x: 155 - grandWidth / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveRight(250);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    let totalWidth = helveticaBold.widthOfTextAtSize(`${registrantTotal}`, 9);
    page.drawText(`${registrantTotal}`, {
      x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
      y: page.getY() + 4,
      size: 9,
      font: helveticaBold,
      lineHeight: 2,
    });

    page.moveDown(30);
    page.moveTo(30, page.getY());
  }

  // Call Activity
  page.moveTo(350, 870);
  if (Object.keys(callActivity).length > 0) {
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: 200,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const titleWidth = helveticaBold.widthOfTextAtSize("Activity Statistics", 9);
    page.drawText("Activity Statistics", {
      x: 450 - titleWidth / 2,
      y: page.getY() + 4,
      size: 9,
      font: helveticaBold,
      lineHeight: 2,
    });
    page.moveRight(200);
    page.drawRectangle({
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      height: 15,
      width: countWidth + 20,
      color: rgb(158 / 255, 181 / 255, 250 / 255),
    });
    const width = helveticaBold.widthOfTextAtSize("Count", 9);
    page.drawText(`Count`, {
      x: page.getX() + (countWidth + 20) / 2 - width / 2,
      y: page.getY() + 4,
      font: helveticaBold,
      size: 9,
      lineHeight: 2,
    });
    page.moveDown(15);
    page.moveTo(350, page.getY());
    for (const [key, value] of Object.entries(callActivity)) {
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: 200,
        color: rgb(1, 1, 1),
      });
      let titleWidth = helvetica.widthOfTextAtSize(`${camelToNormal(key)}`, 9);
      page.drawText(`${camelToNormal(key)}`, {
        x: 450 - titleWidth / 2,
        y: page.getY() + 4,
        size: 9,
        lineHeight: 2,
      });
      page.moveRight(200);
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: countWidth + 20,
        color: rgb(1, 1, 1),
      });
      let totalWidth = helvetica.widthOfTextAtSize(`${value}`, 9);
      page.drawText(`${value}`, {
        x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
        y: page.getY() + 4,
        size: 9,
        lineHeight: 2,
      });
      page.moveDown(15);
      page.moveTo(350, page.getY());
    }

    page.moveDown(15);
    page.moveTo(350, page.getY());
  }

  if (questionsCheck.length && questionsCheck.some((string: string) => string.toLowerCase().includes("hear"))) {
    let selectedQuestion = projectQuestions.find((projectQuestion: IQuestion) => projectQuestion.question.toLowerCase().includes("hear"));
    if (selectedQuestion) {
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: 200,
        color: rgb(158 / 255, 181 / 255, 250 / 255),
      });
      let titleWidth = helveticaBold.widthOfTextAtSize(`${selectedQuestion.question}`, 9);
      page.drawText(`${selectedQuestion.question}`, {
        x: 450 - titleWidth / 2,
        y: page.getY() + 4,
        size: 9,
        font: helveticaBold,
        lineHeight: 2,
      });
      page.moveRight(200);
      page.drawRectangle({
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
        height: 15,
        width: countWidth + 20,
        color: rgb(158 / 255, 181 / 255, 250 / 255),
      });
      const width = helveticaBold.widthOfTextAtSize("Count", 9);
      page.drawText(`Count`, {
        x: page.getX() + (countWidth + 20) / 2 - width / 2,
        y: page.getY() + 4,
        font: helveticaBold,
        size: 9,
        lineHeight: 2,
      });
      page.moveDown(15);
      page.moveTo(350, page.getY());
      for (const choices of selectedQuestion.choices) {
        let total = registrants.reduce((acc, cur) => {
          let regQuestion = cur.questions.find(
            (registrantQuestion: any) => registrantQuestion.questionId.question === selectedQuestion.question
          );
          return regQuestion && regQuestion?.answer.includes(choices.choice) ? ++acc : acc;
        }, 0);

        page.drawRectangle({
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          height: 15,
          width: 200,
          color: rgb(1, 1, 1),
        });
        let titleWidth = helvetica.widthOfTextAtSize(`${choices.choice}`, 9);
        page.drawText(`${choices.choice}`, {
          x: 450 - titleWidth / 2,
          y: page.getY() + 4,
          size: 9,
          lineHeight: 2,
        });
        page.moveRight(200);
        page.drawRectangle({
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          height: 15,
          width: countWidth + 20,
          color: rgb(1, 1, 1),
        });
        const totalWidth = helveticaBold.widthOfTextAtSize(`${total}`, 9);
        page.drawText(`${total}`, {
          x: page.getX() + (countWidth + 20) / 2 - totalWidth / 2,
          y: page.getY() + 4,
          size: 9,
          lineHeight: 2,
        });
        page.moveDown(15);
        page.moveTo(350, page.getY());
      }
      page.moveDown(15);
    }
  }

  if (tablesState.length) {
    pdf.addPage(PageSizes.Legal);
    let currentPage = pdf.getPage(1);
    currentPage.moveTo(30, 980);
    for (const table of tablesState) {
      const fontSize = 12;
      const textOffset = 2;
      const cellPadding = 2;
      const headerHeight = cellPadding * 2 + fontSize + textOffset;
      let tableWidth = 0;

      let headers = table[0].header.split(",");

      tableWidth = headers.reduce((prevValue: any, key: any) => {
        let longestText = [...table[0].rows.map((item: any) => (item[key] ? key : "")), key].reduce(
          (a, b) => (a.toString().length > b.toString().length ? a.toString() : b.toString()),
          ""
        );
        return prevValue + helveticaBold.widthOfTextAtSize(longestText, 8) + 10;
      }, 0);

      let difference = 608 - tableWidth;

      // Add Headers

      headers.forEach((header: any, key: any) => {
        let longestText = [...table[0].rows.map((item: any) => (item[key] ? key : "")), key].reduce(
          (a, b) => (a.toString().length > b.toString().length ? a.toString() : b.toString()),
          ""
        );

        currentPage.drawRectangle({
          x: currentPage.getX(),
          y: currentPage.getY(),
          height: 15,
          width: helveticaBold.widthOfTextAtSize(longestText, 8) + 10 + difference / headers.length,
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          color: rgb(158 / 255, 181 / 255, 250 / 255),
        });
        currentPage.drawText(header, {
          font: helveticaBold,
          x:
            currentPage.getX() +
            5 +
            (helveticaBold.widthOfTextAtSize(longestText, 8) + difference / headers.length - helveticaBold.widthOfTextAtSize(header, 8)) /
              2,
          y: currentPage.getY() + headerHeight - fontSize - cellPadding,
          size: 8,
          lineHeight: fontSize / 2,
        });
        currentPage.moveRight(helveticaBold.widthOfTextAtSize(longestText, 8) + 10 + difference / headers.length);
      });
      currentPage.moveLeft(tableWidth + difference);

      for (const rowItem of table[0].rows) {
        let yValue = currentPage.getY();
        currentPage.moveTo(30, yValue);
        currentPage.moveDown(15);
        let row = rowItem.split(",");
        headers.forEach((header: any, index: number) => {
          let longestText = [...table[0].rows.map((item: any) => (item[index] ? index : "")), index].reduce(
            (a, b) => (a.toString().length > b.toString().length ? a.toString() : b.toString()),
            ""
          );
          currentPage.drawRectangle({
            x: currentPage.getX(),
            y: currentPage.getY(),
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
            width: helveticaBold.widthOfTextAtSize(longestText, 8) + 10 + difference / headers.length,
            height: 15,
            color: rgb(1, 1, 1),
          });

          currentPage.drawText(`${row[index]}`, {
            x:
              currentPage.getX() +
              5 +
              (helveticaBold.widthOfTextAtSize(longestText, 8) + difference / headers.length - helveticaBold.widthOfTextAtSize(header, 8)) /
                2,
            y: currentPage.getY() + headerHeight - fontSize - cellPadding,
            size: 8,
          });
          currentPage.moveRight(helveticaBold.widthOfTextAtSize(longestText, 8) + 10 + difference / headers.length);
        });
      }
      currentPage.moveDown(40);
      let yValue = pdf.getPage(pdf.getPages().length - 1).getY();
      currentPage.moveTo(30, yValue);
    }
  }

  if (questionsCheck.length && !questionsCheck.every((string: string) => string.toLowerCase().includes("hear"))) {
    if (!tablesState.length) {
      pdf.addPage(PageSizes.Legal);
      let currentPage = pdf.getPage(1);
      currentPage.moveTo(30, 980);
    }
    for (const question of questionsCheck) {
      let selectedQuestion = projectQuestions.find(
        (projectQuestion: IQuestion) => projectQuestion.question === question && !question.toLowerCase().includes("hear")
      );

      let currentPage = pdf.getPage(pdf.getPages().length - 1);
      let yValue = pdf.getPage(pdf.getPages().length - 1).getY();
      currentPage.moveTo(30, yValue);
      if (selectedQuestion) {
        if (yValue - selectedQuestion.choices.length * 20 < 0) {
          currentPage = pdf.addPage(PageSizes.Legal);
          currentPage.moveTo(30, 980);
          currentPage.moveDown(15);
        }
        currentPage.drawRectangle({
          x: currentPage.getX(),
          y: currentPage.getY(),
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          height: 15,
          width: 450,
          color: rgb(158 / 255, 181 / 255, 250 / 255),
        });
        let titleWidth = helveticaBold.widthOfTextAtSize(`Registrant - ${selectedQuestion.question}`, 9);
        currentPage.drawText(`Registrant - ${selectedQuestion.question}`, {
          x: 255 - titleWidth / 2,
          y: currentPage.getY() + 4,
          size: 9,
          font: helveticaBold,
          lineHeight: 2,
        });
        currentPage.moveRight(450);
        currentPage.drawRectangle({
          x: currentPage.getX(),
          y: currentPage.getY(),
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          height: 15,
          width: 100,
          color: rgb(158 / 255, 181 / 255, 250 / 255),
        });
        const width = helveticaBold.widthOfTextAtSize("Count", 9);
        currentPage.drawText(`Count`, {
          x: currentPage.getX() + 50 - width / 2,
          y: currentPage.getY() + 4,
          font: helveticaBold,
          size: 9,
          lineHeight: 2,
        });
        currentPage.moveDown(15);
        currentPage.moveTo(30, currentPage.getY());
        for (const choices of selectedQuestion.choices) {
          let total = registrants.reduce((acc, cur) => {
            let regQuestion = cur.questions.find((registrantQuestion: any) => registrantQuestion.questionId.question === question);
            return regQuestion && regQuestion?.answer.includes(choices.choice) ? ++acc : acc;
          }, 0);

          currentPage.drawRectangle({
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
            height: 15,
            width: 450,
            color: rgb(1, 1, 1),
          });
          let titleWidth = helvetica.widthOfTextAtSize(`${choices.choice}`, 9);
          currentPage.drawText(`${choices.choice}`, {
            x: 255 - titleWidth / 2,
            y: currentPage.getY() + 4,
            size: 9,
            lineHeight: 2,
          });
          currentPage.moveRight(450);
          currentPage.drawRectangle({
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
            height: 15,
            width: 100,
            color: rgb(1, 1, 1),
          });
          const totalWidth = helveticaBold.widthOfTextAtSize(`${total}`, 9);
          currentPage.drawText(`${total}`, {
            x: currentPage.getX() + 50 - totalWidth / 2,
            y: currentPage.getY() + 4,
            size: 9,
            lineHeight: 2,
          });
          currentPage.moveDown(15);
          currentPage.moveTo(30, currentPage.getY());
        }
        currentPage.moveDown(15);
      }
    }
  }

  if (salesQuestionsCheck.length && !salesQuestionsCheck.every((string: string) => string.toLowerCase().includes("hear"))) {
    for (const question of salesQuestionsCheck) {
      let selectedQuestion = projectQuestions.find(
        (projectQuestion: IQuestion) => projectQuestion.question === question && !question.toLowerCase().includes("hear")
      );

      let currentPage = pdf.getPage(pdf.getPages().length - 1);
      let yValue = pdf.getPage(pdf.getPages().length - 1).getY();
      currentPage.moveTo(30, yValue);
      if (selectedQuestion) {
        if (yValue - selectedQuestion.choices.length * 20 < 0) {
          currentPage = pdf.addPage(PageSizes.Legal);
          currentPage.moveTo(30, 980);
          currentPage.moveDown(15);
        }
        currentPage.drawRectangle({
          x: currentPage.getX(),
          y: currentPage.getY(),
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          height: 15,
          width: 450,
          color: rgb(158 / 255, 181 / 255, 250 / 255),
        });
        let titleWidth = helveticaBold.widthOfTextAtSize(`Sales - ${selectedQuestion.question}`, 9);
        currentPage.drawText(`Sales - ${selectedQuestion.question}`, {
          x: 255 - titleWidth / 2,
          y: currentPage.getY() + 4,
          size: 9,
          font: helveticaBold,
          lineHeight: 2,
        });
        currentPage.moveRight(450);
        currentPage.drawRectangle({
          x: currentPage.getX(),
          y: currentPage.getY(),
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          height: 15,
          width: 100,
          color: rgb(158 / 255, 181 / 255, 250 / 255),
        });
        const width = helveticaBold.widthOfTextAtSize("Count", 9);
        currentPage.drawText(`Count`, {
          x: currentPage.getX() + 50 - width / 2,
          y: currentPage.getY() + 4,
          font: helveticaBold,
          size: 9,
          lineHeight: 2,
        });
        currentPage.moveDown(15);
        currentPage.moveTo(30, currentPage.getY());
        for (const choices of selectedQuestion.choices) {
          let total = registrants.reduce((acc, cur) => {
            let regQuestion = cur.salesQuestions.find((salesQuestion: any) => salesQuestion.questionId.question === question);
            return regQuestion && regQuestion?.answer.includes(choices.choice) ? ++acc : acc;
          }, 0);

          currentPage.drawRectangle({
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
            height: 15,
            width: 450,
            color: rgb(1, 1, 1),
          });
          let titleWidth = helvetica.widthOfTextAtSize(`${choices.choice}`, 9);
          currentPage.drawText(`${choices.choice}`, {
            x: 255 - titleWidth / 2,
            y: currentPage.getY() + 4,
            size: 9,
            lineHeight: 2,
          });
          currentPage.moveRight(450);
          currentPage.drawRectangle({
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
            height: 15,
            width: 100,
            color: rgb(1, 1, 1),
          });
          const totalWidth = helveticaBold.widthOfTextAtSize(`${total}`, 9);
          currentPage.drawText(`${total}`, {
            x: currentPage.getX() + 50 - totalWidth / 2,
            y: currentPage.getY() + 4,
            size: 9,
            lineHeight: 2,
          });
          currentPage.moveDown(15);
          currentPage.moveTo(30, currentPage.getY());
        }
        currentPage.moveDown(15);
      }
    }
  }

  const pdfFile = await pdf.save();
  download(pdfFile, `${project.name} - Custom Report`, "application/pdf");
};

export const downloadCustomReportExcel = async (
  registrant: IRegistrant[],
  project: IProject,
  dateStart: Date,
  dateEnd: Date,
  allLeads: boolean,
  newLeads: boolean,
  connected: boolean,
  realtorTypesCheck: string[],
  ratingsCheck: string[],
  sourcesCheck: string[],
  ethnicitiesCheck: string[],
  callActivity: any,
  questionsCheck: string[],
  salesQuestionsCheck: string[],
  projectQuestions: any[],
  tablesState: any[]
) => {
  let registrantTotal = registrant.length;
  let allTotal = registrant.length;
  let registrants = registrant;
  let registrantConnectedCount = 0;

  const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const workbook = new ExcelJS.Workbook(); // Create Workbook
  const worksheet = workbook.addWorksheet("Custom Report");

  if (newLeads) {
    registrants = registrant.filter(
      (registrant: IRegistrant) =>
        new Date(dateStart).valueOf() < new Date(registrant?.createdAt!).valueOf() &&
        new Date(registrant?.createdAt!).valueOf() < new Date(dateEnd).valueOf()
    );
    registrantTotal = registrants.length;
  }

  if (connected) {
    registrantConnectedCount = registrant.filter((registrant: IRegistrant) => registrant.connections.length).length;
  }

  // All Leads
  if (allLeads) {
    worksheet.getCell("A1").value = "Total Number of Leads";
    worksheet.getCell("A2").value = `${allTotal}`;
  }

  if (allLeads) {
    worksheet.getCell("B1").value = "Number of New Leads";
    worksheet.getCell("B2").value = `${registrantTotal}`;
  }

  if (connected) {
    worksheet.getCell("C1").value = "Connected";
    worksheet.getCell("C2").value = `${registrantConnectedCount}`;
  }

  // Realtor Types
  if (realtorTypesCheck.length) {
    worksheet.getCell("A4").value = "Realtor Types";
    for (const [key, realtorType] of Object.entries(realtorTypesCheck)) {
      let total = registrants.reduce((acc, cur) => (cur.realtorType === realtorType ? ++acc : acc), 0);
      let cell = parseInt(key, 10) + 5;
      worksheet.getCell(`A${cell}`).value = camelToNormal(realtorType);
      worksheet.getCell(`B${cell}`).value = total;
    }
    worksheet.getCell(`A${realtorTypesCheck.length + 5}`).value = "Grand Total";
    worksheet.getCell(`B${realtorTypesCheck.length + 5}`).value = registrantTotal;
  }

  // Ratings
  if (ratingsCheck.length) {
    worksheet.getCell("D4").value = "Ratings";
    for (const [key, rating] of Object.entries(ratingsCheck)) {
      let total = registrants.reduce((acc, cur) => (cur.rating === rating ? ++acc : acc), 0);
      let cell = parseInt(key, 10) + 5;
      worksheet.getCell(`D${cell}`).value = rating;
      worksheet.getCell(`E${cell}`).value = total;
    }
    worksheet.getCell(`D${ratingsCheck.length + 5}`).value = "Grand Total";
    worksheet.getCell(`E${ratingsCheck.length + 5}`).value = registrantTotal;
  }

  // Source
  if (sourcesCheck.length) {
    let overallTotal = 0;
    worksheet.getCell("G4").value = "Source";
    for (const [key, source] of Object.entries(sourcesCheck)) {
      let total = registrants.reduce((acc, cur) => (cur.source === source ? ++acc : acc), 0);
      overallTotal += total;
      let cell = parseInt(key, 10) + 5;
      worksheet.getCell(`G${cell}`).value = source;
      worksheet.getCell(`H${cell}`).value = total;
    }
    worksheet.getCell(`G${sourcesCheck.length + 5}`).value = "Grand Total";
    worksheet.getCell(`H${sourcesCheck.length + 5}`).value = overallTotal;
  }

  if (ethnicitiesCheck.length) {
    worksheet.getCell("J4").value = "Ethnicities";
    for (const [key, ethnicity] of Object.entries(ethnicitiesCheck)) {
      let total = registrants.reduce((acc, cur) => (cur.ethnicity === ethnicity ? ++acc : acc), 0);
      let cell = parseInt(key, 10) + 5;
      worksheet.getCell(`J${cell}`).value = ethnicity;
      worksheet.getCell(`K${cell}`).value = total;
    }
    worksheet.getCell(`J${ethnicitiesCheck.length + 5}`).value = "Grand Total";
    worksheet.getCell(`K${ethnicitiesCheck.length + 5}`).value = registrantTotal;
  }

  // Call Activity
  if (Object.keys(callActivity).length > 0) {
    worksheet.getCell("M4").value = "Activity Statistics";
    for (const [index, [key, value]] of Object.entries(Object.entries(callActivity))) {
      let cell = parseInt(index, 10) + 5;
      worksheet.getCell(`M${cell}`).value = camelToNormal(key);
      worksheet.getCell(`N${cell}`).value = value as string;
    }
  }

  if (questionsCheck.length && questionsCheck.some((string: string) => string.toLowerCase().includes("hear"))) {
    worksheet.getCell("A30").value = "Registrant Questions";
    let selectedQuestion = projectQuestions.find((projectQuestion: IQuestion) => projectQuestion.question.toLowerCase().includes("hear"));
    if (selectedQuestion) {
      worksheet.getCell("D31").value = selectedQuestion.question;
      for (const [index, choices] of selectedQuestion.choices.entries()) {
        let total = registrants.reduce((acc, cur) => {
          let regQuestion = cur.questions.find(
            (registrantQuestion: any) => registrantQuestion.questionId.question === selectedQuestion.question
          );
          return regQuestion && regQuestion?.answer.includes(choices.choice) ? ++acc : acc;
        }, 0);

        let cell = parseInt(index, 10) + 32;

        worksheet.getCell(`D${cell}`).value = choices.choice;
        worksheet.getCell(`E${cell}`).value = total;
      }
    }
  }

  if (questionsCheck.length && !questionsCheck.every((string: string) => string.toLowerCase().includes("hear"))) {
    worksheet.getCell("A30").value = "Registrant Questions";
    let lineTotal = 31;
    for (const [index, question] of questionsCheck.entries()) {
      let selectedQuestion = projectQuestions.find(
        (projectQuestion: IQuestion) => projectQuestion.question === question && !question.toLowerCase().includes("hear")
      );

      if (!selectedQuestion) continue;

      worksheet.getCell(`A${lineTotal}`).value = selectedQuestion.question;

      if (selectedQuestion) {
        for (const [index, choices] of selectedQuestion.choices.entries()) {
          let total = registrants.reduce((acc, cur) => {
            let regQuestion = cur.questions.find((registrantQuestion: any) => registrantQuestion.questionId.question === question);
            return regQuestion && regQuestion?.answer.includes(choices.choice) ? ++acc : acc;
          }, 0);

          let cell = parseInt(index, 10) + lineTotal + 1;

          worksheet.getCell(`A${cell}`).value = choices.choice;
          worksheet.getCell(`B${cell}`).value = total;
        }
      }
      lineTotal += selectedQuestion.choices.length + 2;
    }
  }

  if (salesQuestionsCheck.length && !salesQuestionsCheck.every((string: string) => string.toLowerCase().includes("hear"))) {
    worksheet.getCell("G30").value = "Sales Questions";
    let lineTotal = 31;
    for (const [index, question] of salesQuestionsCheck.entries()) {
      let selectedQuestion = projectQuestions.find(
        (projectQuestion: IQuestion) => projectQuestion.question === question && !question.toLowerCase().includes("hear")
      );

      if (!selectedQuestion) continue;

      worksheet.getCell(`G${lineTotal}`).value = selectedQuestion.question;

      if (selectedQuestion) {
        for (const [index, choices] of selectedQuestion.choices.entries()) {
          let total = registrants.reduce((acc, cur) => {
            let regQuestion = cur.questions.find((registrantQuestion: any) => registrantQuestion.questionId.question === question);
            return regQuestion && regQuestion?.answer.includes(choices.choice) ? ++acc : acc;
          }, 0);

          let cell = parseInt(index, 10) + lineTotal + 1;

          worksheet.getCell(`G${cell}`).value = choices.choice;
          worksheet.getCell(`H${cell}`).value = total;
        }
      }
      lineTotal += selectedQuestion.choices.length + 2;
    }
  }

  workbook.xlsx.writeBuffer().then((file: any) => {
    const blob = new Blob([file], { type: EXCEL_TYPE });
    saveAs(blob, `${project.name} - Custom Report`);
  });
};

export const getScaledDim = (img: any, maxWidth: number, maxHeight: number) => {
  var scaled = {
    ratio: img.width / img.height,
    width: img.width,
    height: img.height,
  };
  if (scaled.width > maxWidth) {
    scaled.width = maxWidth;
    scaled.height = scaled.width / scaled.ratio;
  }
  if (scaled.height > maxHeight) {
    scaled.height = maxHeight;
    scaled.width = scaled.height / scaled.ratio;
  }
  return scaled;
};
