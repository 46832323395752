import { useState, useEffect, useMemo, useContext } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import * as xlsx from "xlsx";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Typography, Box, Button, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import postalCodes from "../../../assets/postalCodes.json";
import { IProject } from "../../../types/project";
import ClusterMap from "../../common/ClusterMap";
import { FlexBetween, FlexEnd, SettingContainer } from "../../../commonStyles";
import { capitalizeFirstLetterEachWord, getNumChars, downloadExcel, downloadPdf, convertAllDates } from "../../../utils/function";
import StandardTable from "../../tables/StandardTable";
import { useAppDispatch } from "../../../app/hooks";
import { showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { GlobalModal } from "../../../features/modal/Modal";
import { handleModal } from "../../../features/modal/modalSlice";
import RegistrantFilter from "../../common/RegistrantFilter";
import { RegistrantFilterContext } from "../../../context/RegistrantFilterContext";
const postalCodesObj = postalCodes as any;

const Mapping = ({ project }: MappingProps) => {
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const [mapImgUrl, setMapImgUrl] = useState<string>("");
  const [data, setData] = useState<any>([]);
  const [postalCodeData, setPostalCodeData] = useState<any>({
    locations: {
      markerLocations: [],
      postalCodeCounts: [],
    },
  });
  const [projects, setProjects] = useState<any>([]);
  const [group, setGroup] = useState("");
  const [dateStart, setDateStart] = useState<Date | null | undefined | number>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [dateEnd, setDateEnd] = useState<Date | null | undefined>(new Date(new Date().setHours(23, 59, 59, 59)));
  const {
    realtorTypeFilter,
    statusFilter,
    ratingFilter,
    salesRepFilter,
    sourceFilter,
    emailUnsubscribedFilter,
    textUnsubscribedFilter,
    connectedFilter,
    startDateFilter,
    endDateFilter,
  } = useContext(RegistrantFilterContext);

  const groups = ["purchasers", "worksheets", "registrants"];

  const [getPurchasers] = useLazyQuery(GETPURCHASERS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (!data.getPurchasers.purchasers.length) {
        return storeDispatch(showErrorSnackbar("No Results Found"));
      }
      setData(data.getPurchasers.purchasers.map((data: any) => data._id.purchaser));
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [getWorksheets] = useLazyQuery(GETWORKSHEETS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (!data.worksheetMany.length) {
        return storeDispatch(showErrorSnackbar("No Results Found"));
      }
      setData(data.worksheetMany.map((data: any) => data.purchasers.map((purchaser: any) => purchaser)).flat());
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const [getRegistrants] = useLazyQuery(GETREGISTRANTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (!data.registrantMany.length) {
        return storeDispatch(showErrorSnackbar("No Results Found"));
      }
      setData(data.registrantMany);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    if (project) {
      setProjects([project]);
    }
  }, [project]);

  useEffect(() => {
    getPostalCodes();
  }, [data]);

  const getPostalCodes = () => {
    let markerLocations: any[] = [];
    let postalCodeCounts: any = {};
    let results = {
      locations: {
        markerLocations,
        postalCodeCounts,
      },
    };
    let firstFourPostalCodeChars;
    let coordinates;
    let formattedData = data;
    for (let item of formattedData) {
      if (item.postalCode) {
        firstFourPostalCodeChars = getNumChars(item.postalCode, 4).toLocaleUpperCase();
        if (results.locations.postalCodeCounts[firstFourPostalCodeChars]) {
          ++results.locations.postalCodeCounts[firstFourPostalCodeChars];
        } else {
          results.locations.postalCodeCounts[firstFourPostalCodeChars] = 1;
        }
        const coordObj = postalCodesObj[firstFourPostalCodeChars];
        if (coordObj) {
          coordinates = {
            lat: parseFloat(coordObj.latitude),
            lng: parseFloat(coordObj.longitude),
            label: firstFourPostalCodeChars,
          };
        }
      }
      if (coordinates) {
        results.locations.markerLocations.push(coordinates);
      }
    }

    results.locations.postalCodeCounts = Object.entries(results.locations.postalCodeCounts)
      .map((entry: any) => {
        return { postalCode: entry[0], numRegistrants: entry[1] };
      })
      .sort((count1: any, count2: any) => count2.numRegistrants - count1.numRegistrants);
    setPostalCodeData(results);
  };

  const handleMap = async (e: any) => {
    e.preventDefault();
    if (group === "purchasers") {
      await getPurchasers({
        variables: {
          projects: projects.map((project: IProject) => project._id),
          page: 0,
          perPage: 50000,
          search: "",
          status: ["C", "F"],
          dateStart,
          dateEnd,
        },
      });
    } else if (group === "worksheets") {
      await getWorksheets({ variables: { filter: { projects: projects.map((project: IProject) => project._id) } } });
    } else if (group === "registrants") {
      await getRegistrants({
        variables: {
          filter: {
            filterValue: {
              realtorType: realtorTypeFilter === "any" ? null : realtorTypeFilter,
              status: statusFilter === "any" ? null : statusFilter,
              rating: ratingFilter === "any" ? null : ratingFilter,
              salesRep: salesRepFilter === "any" ? null : (salesRepFilter as any)._id,
              source: sourceFilter === "any" ? null : sourceFilter,
              emailUnsubscribed: emailUnsubscribedFilter === "any" ? null : emailUnsubscribedFilter,
              textUnsubscribed: textUnsubscribedFilter === "any" ? null : textUnsubscribedFilter,
              connected: connectedFilter === "any" ? null : connectedFilter,
              startDate: startDateFilter,
              endDate: endDateFilter,
            },
            projects: projects.map((project: IProject) => project._id),
          },
        },
      });
    }
  };

  const topPostalCodeColumns = useMemo(() => {
    return [
      {
        Header: "Postal Code",
        accessor: "postalCode",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
      {
        Header: "Number of Registrants",
        accessor: "numRegistrants",
        Cell: ({ cell: { value }, row: { original } }: any) => {
          return <Typography>{value}</Typography>;
        },
      },
    ];
  }, []);

  const download = (type: string) => {
    let headers = [
      {
        label: "Postal Code",
        id: "postalCode",
      },
      {
        label: "Count",
        id: "numRegistrants",
      },
    ];

    let widths = {
      postalCode: 15,
      numOfRegistrants: 15,
    };

    let pdfWidths = {
      postalCode: 200,
      numOfRegistrants: 200,
    };

    let sheetTitle = [`${convertAllDates(`${new Date()}`, "PP")} Postal Code List`];

    if (type === "excel") {
      downloadExcel([postalCodeData.locations.postalCodeCounts], [headers], [], [[widths]], sheetTitle, sheetTitle[0]);
    } else {
      downloadPdf([postalCodeData.locations.postalCodeCounts], [headers], [], [pdfWidths], sheetTitle, sheetTitle[0]);
    }
  };

  const uploadExcel = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json: any = xlsx.utils.sheet_to_json(worksheet);
        if (!json[0].hasOwnProperty("postalCode")) {
          storeDispatch(showErrorSnackbar("Incorrect Format"));
        }
        storeDispatch(handleModal(false));
        setData(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  return (
    <SettingContainer>
      <GlobalModal>
        <Box>
          <Box>
            <strong>The Excel document requires the header "postalCode".</strong>
          </Box>
          <FlexBetween sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                storeDispatch(handleModal(false));
              }}
            >
              Cancel
            </Button>
            <Box>
              <input onChange={uploadExcel} style={{ display: "none" }} id="raised-button-file" type="file" />
              <label htmlFor="raised-button-file">
                <Button component="span" startIcon={<FileOpenIcon />} variant="contained" color="success">
                  Upload Excel
                </Button>
              </label>
            </Box>
          </FlexBetween>
        </Box>
      </GlobalModal>
      <FlexBetween>
        <Typography variant="h2">
          <strong>Mapping</strong>
        </Typography>
        <Button startIcon={<FileOpenIcon />} onClick={() => storeDispatch(handleModal(true))} variant="contained">
          Upload Excel
        </Button>
      </FlexBetween>
      <Box sx={{ mt: 2 }}>
        <form onSubmit={handleMap}>
          <Autocomplete
            multiple
            options={user?.projectAccess ? [...user?.projectAccess.map((pa: any) => pa.project)] : []}
            value={projects}
            disabled={project ? true : false}
            renderInput={(params) => {
              return <TextField label="Projects" {...params} />;
            }}
            getOptionLabel={(option: any) => (option === "Any" ? "Any" : option.name)}
            onChange={(e, value: any) => {
              setProjects(value);
            }}
          />
          {projects.length ? (
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Group</InputLabel>
              <Select labelId="group" name="group" value={group} label="Groups" onChange={(e) => setGroup(e.target.value)}>
                {groups.map((group: string, index: number) => {
                  return (
                    <MenuItem key={index} value={group}>
                      {capitalizeFirstLetterEachWord(group)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : null}
          {group === "registrants" ? (
            <Box sx={{ mt: 2 }}>
              <RegistrantFilter />
            </Box>
          ) : null}
          {group === "purchasers" ? (
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Purchased Date Start"
                    value={dateStart ? new Date(dateStart!) : null}
                    onChange={(newValue) => {
                      setDateStart(newValue);
                    }}
                    maxDate={dateEnd ? new Date(dateEnd) : null}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Purchased Date End"
                    value={dateEnd ? new Date(dateEnd!) : null}
                    onChange={(newValue) => {
                      setDateEnd(newValue);
                    }}
                    minDate={dateStart ? new Date(dateStart) : null}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          ) : null}
          {group ? (
            <FlexEnd sx={{ mt: 2 }}>
              <Button color="success" variant="contained" type="submit">
                Create Map
              </Button>
            </FlexEnd>
          ) : null}
        </form>
      </Box>
      {postalCodeData.locations.postalCodeCounts.length ? (
        <>
          <Box sx={{ mt: 2 }}>
            <Box>
              Count: <strong>{data.length}</strong>
            </Box>
            <Box>
              <ClusterMap markerLocations={postalCodeData.locations.markerLocations} setMapUrl={setMapImgUrl} />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <StandardTable
              download={download}
              user={user}
              count={postalCodeData.locations.postalCodeCounts.length}
              data={postalCodeData.locations.postalCodeCounts}
              loading={false}
              columns={topPostalCodeColumns}
              maxHeight={"340px"}
            />
          </Box>
        </>
      ) : null}
    </SettingContainer>
  );
};

interface MappingProps {
  project?: IProject;
}

const GETPURCHASERS = gql`
  query getPurchasers(
    $projects: [MongoID!]
    $page: Float!
    $perPage: Float!
    $search: String
    $status: [String]
    $dateStart: Date
    $dateEnd: Date
  ) {
    getPurchasers(
      projects: $projects
      page: $page
      perPage: $perPage
      search: $search
      status: $status
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      purchasers {
        _id {
          purchaser {
            postalCode
          }
        }
      }
      count
    }
  }
`;

const GETWORKSHEETS = gql`
  query worksheetMany($filter: FilterFindManyWorksheetInput) {
    worksheetMany(filter: $filter, limit: 10000) {
      purchasers {
        _id
        postalCode
      }
    }
  }
`;

const GETREGISTRANTS = gql`
  query registrantMany($filter: FilterFindManyRegistrantInput) {
    registrantMany(filter: $filter, limit: 100000) {
      _id
      postalCode
    }
  }
`;

export default Mapping;
