import { useState, useEffect, Dispatch, SetStateAction, useContext } from "react";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import { TextField, Typography, Box, CircularProgress, Select, MenuItem, FormControl, InputLabel, Autocomplete } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";

import { selectUser } from "../../../features/auth/authSlice";
import { IRegistrant } from "../../../types/registrant";
import TextEditor from "../../common/textEditor/TextEditor";
import { userTokenValues, registrantTokenValues } from "../../../utils/constant";
import { AttachmentsDialog } from "../../common/textEditor/MenuDialogs";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { useAppDispatch } from "../../../app/hooks";
import { IEmailTemplate } from "../../../types/process";
import { selectProject } from "../../../features/project/projectSlice";
import { normalToHyphen } from "../../../utils/function";
import { IStepSchedule } from "../../../types/stepSchedule";
import { IUser } from "../../../types/user";
import { RegistrantNotificationContext } from "../../../context/RegistrantNotificationContext";
import { selectProcess } from "../../../features/registrants/registrantsSlice";

const ShowEmailView = (props: ChildProps) => {
  const {
    emailTemplate,
    registrant,
    setShowStep,
    handleCompleteStep,
    dateCompleted,
    step,
    handleProcess,
    stepSchedules,
    setStepSchedules,
  } = props;
  const { registrantsDispatch } = useContext(RegistrantNotificationContext);
  const project = useSelector(selectProject);
  const registrantProcess = useSelector(selectProcess);
  const [template, setTemplate] = useState<ITemplate | null>(null);
  const [newTemplate, setNewTemplate] = useState<string>(template?.html!);
  const [loading, setLoading] = useState<boolean>(true);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [attachmentsDialogOpen, setAttachmentsDialogOpen] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>("");
  const [senderName, setSenderName] = useState<string>("");
  const [senderEmail, setSenderEmail] = useState<string>("");
  const [cc, setCc] = useState<IUser[]>([]);
  const [date, setDate] = useState<Date | null>(null);
  const [users, setUsers] = useState<IUser[]>([]);

  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (template) {
      replaceHtml(template?.html!);
    }
    if (!emailTemplate) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [template]);

  useQuery(GETUSERS, {
    variables: { filter: { OR: [{ type: "Sales" }, { type: "Manager" }, { type: "Admin" }], locked: false } },
    onCompleted: (data) => {
      setUsers(data.userMany);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useQuery(GETEMAILTEMPLATE, {
    skip: !emailTemplate,
    variables: { _id: emailTemplate?._id },
    onCompleted: (data) => {
      setSubject(data.emailTemplateById.subject);
      setTemplate(data.emailTemplateById);
      setAttachments(data.emailTemplateById.attachments || []);
    },
    onError: (err) => {
      console.log(err, "err");
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [sendEmail] = useLazyQuery(SENDEMAIL, {
    onCompleted: () => {
      setLoading(false);
      handleCompleteStep(
        {
          user: {
            _id: user?._id,
          },
          completed: dateCompleted,
          _id: step,
        },
        registrantProcess
      );

      if (registrant) {
        registrantsDispatch({
          type: "DELETE",
          payload: {
            _id: registrant._id,
          },
        });
      }
      dispatch(showSuccessSnackbar("Email Sent!"));
      handleProcess("", null, false);
      setShowStep("");
    },
    onError: (e) => console.log(e),
  });

  const [scheduleEmail] = useMutation(SCHEDULEEMAIL, {
    onCompleted: (data) => {
      dispatch(showSuccessSnackbar("Email Scheduled!"));
      setShowStep("");
      handleProcess("", false);
      setStepSchedules([...stepSchedules, data.stepScheduleCreateOne.record]);
    },
    onError: (e) => console.log(e),
  });

  const replaceHtml = (template: string) => {
    let revisedHtml: any = template;
    let registrantHtml: any = registrant;
    let userHtml: any = user;
    for (let i = 0; i < registrantTokenValues.length; i++) {
      let key: any = Object.keys(registrantTokenValues[i]);
      let value = Object.values(registrantTokenValues[i]);

      if (registrantHtml[key[0]]) {
        if (value[0] === "{{registrantProject}}") {
          revisedHtml = revisedHtml.replaceAll(value[0], registrantHtml[key[0]].name);
        } else {
          revisedHtml = revisedHtml.replaceAll(value[0], registrantHtml[key[0]]);
        }
      }
      if (key[0] === "onlineRepFirstName") {
        revisedHtml = revisedHtml.replaceAll(value[0], registrantHtml.onlineSalesRep ? registrantHtml.onlineSalesRep.firstName : value[0]);
      }
      if (key[0] === "onlineRepLastName") {
        revisedHtml = revisedHtml.replaceAll(value[0], registrantHtml.onlineSalesRep ? registrantHtml.onlineSalesRep.lastName : value[0]);
      }
      if (key[0] === "salesRepFirstName") {
        revisedHtml = revisedHtml.replaceAll(value[0], registrantHtml.salesRep ? registrantHtml.salesRep.firstName : value[0]);
      }
      if (key[0] === "salesRepLastName") {
        revisedHtml = revisedHtml.replaceAll(value[0], registrantHtml.salesRep ? registrantHtml.salesRep.lastName : value[0]);
      }
      if (key[0] === "tagLine") {
        revisedHtml = revisedHtml.replaceAll(value[0], registrantHtml.project.tagLine);
      }
      if (key[0] === "developer") {
        revisedHtml = revisedHtml.replaceAll(value[0], registrantHtml.project.developerName);
      }
      if (key[0] === "logo") {
        revisedHtml = revisedHtml.replaceAll(
          value[0],
          `<img src=${registrantHtml.project.logoGetUrl} width="200" alt="Project Logo" contenteditable="false" draggable="true" />`
        );
      }
      if (key[0] === "appointment") {
        revisedHtml = revisedHtml.replaceAll(
          value[0],
          `<a style="text-decoration: none; color: #000;" href=${`${
            process.env.REACT_APP_ENV === "local"
              ? `http://localhost:3001/${normalToHyphen(registrantHtml.project.developerName)}/${normalToHyphen(
                  registrantHtml.project.name
                )}/public/book-an-appointment`
              : `https://portal.rdsre.ca/${normalToHyphen(registrantHtml.project.developerName)}/${normalToHyphen(
                  registrantHtml.project.name
                )}/public/book-an-appointment`
          }`}>Book Appointment Here</a>`
        );
      }
    }

    for (let i = 0; i < userTokenValues.length; i++) {
      let key: any = Object.keys(userTokenValues[i]);
      let value = Object.values(userTokenValues[i]);

      if (userHtml[key[0]]) {
        revisedHtml = revisedHtml.replaceAll(value[0], userHtml[key[0]]);
      }
    }

    setNewTemplate(revisedHtml);
    setLoading(false);
  };

  const handleSendEmail = async (content: string) => {
    if (!subject) return dispatch(showErrorSnackbar("Missing Subject"));
    if (!senderName) return dispatch(showErrorSnackbar("Missing Sender Name"));
    let attachmentsSaved = [];
    let attachmentsUnsaved = [];

    for (let i = 0; i < attachments.length; i++) {
      if (!attachments[i].file) {
        attachmentsSaved.push({ getUrl: attachments[i].getUrl, name: attachments[i].name });
      } else {
        attachmentsUnsaved.push(attachments[i].file);
      }
    }

    let ccEmails = "";

    if (cc.length) {
      ccEmails = cc.map((user: IUser) => user.email).join(", ");
    }

    if (date) {
      await scheduleEmail({
        variables: {
          record: {
            project: project?._id,
            registrant: registrant._id,
            emailTemplate: emailTemplate ? emailTemplate._id : null,
            user: user?._id,
            step: step,
            html: content,
            mailDate: date,
            subject: subject,
            senderName: senderName,
            senderEmail: senderEmail,
            cc: ccEmails,
          },
        },
      });
    } else {
      setLoading(true);
      await sendEmail({
        variables: {
          id: template?._id,
          to: registrant.email,
          subject: subject,
          senderName: senderName,
          senderEmail: senderEmail,
          cc: ccEmails,
          html: content,
          project: project?._id,
          attachmentsSaved: attachmentsSaved,
          attachmentsUnsaved: attachmentsUnsaved,
        },
      });
    }
  };

  const handleUserChange = (e: any) => {
    setSenderEmail(e.target.value);
    setSenderName(project?.name!);
  };

  const deleteFile = (id: number, title: string, numIndex: number) => {
    let removedAttachments = attachments.filter((id: any, index: number) => numIndex !== index);
    setAttachments(removedAttachments);
  };

  return (
    <div>
      {!loading ? (
        <>
          <Box sx={{ display: "flex" }}>
            <ArrowBackIcon onClick={() => setShowStep("")} sx={{ mr: 1, cursor: "pointer" }} />
            <Typography variant="h2" component="div" gutterBottom>
              <strong>
                Send To {registrant.firstName} {registrant.lastName}
              </strong>
            </Typography>
          </Box>
          <AttachmentsDialog
            attachmentsDialogOpen={attachmentsDialogOpen}
            setAttachmentsDialogOpen={setAttachmentsDialogOpen}
            attachments={attachments}
            setAttachments={setAttachments}
            deleteFile={deleteFile}
          />
          <TextField fullWidth required label="Subject" variant="outlined" value={subject} onChange={(e) => setSubject(e.target.value)} />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel required id="demo-simple-select-label">
              Sender Email
            </InputLabel>
            <Select fullWidth required label={"Sender Email"} value={senderEmail} onChange={(e) => handleUserChange(e)}>
              <MenuItem value={project?.email!}>
                {project?.name!} ({project?.email!})
              </MenuItem>
              <MenuItem value={user?.email}>
                {user?.fullName} ({user?.email})
              </MenuItem>
              {user?.emailAliases && user?.emailAliases.length
                ? user.emailAliases.map((email: string) => {
                    return <MenuItem value={email}>{email}</MenuItem>;
                  })
                : null}
            </Select>
          </FormControl>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            required
            label="Sender Name"
            variant="outlined"
            value={senderName}
            onChange={(e) => setSenderName(e.target.value)}
          />
          <Autocomplete
            id="cc"
            multiple
            options={users}
            freeSolo={false}
            value={cc}
            getOptionLabel={(option: any) => option.fullName}
            onChange={(e, value) => setCc(value)}
            renderInput={(params) => <TextField sx={{ mt: 2 }} {...params} variant="outlined" label="Cc" />}
          />
          <FormControl sx={{ mt: 2 }} required fullWidth variant="outlined">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Mail Date (Optional)"
                value={date}
                disablePast
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            <TextEditor
              renderButtons={[
                { name: `${date ? "Schedule Email" : "Send Email"}`, function: handleSendEmail },
                { name: "Attachments", function: () => setAttachmentsDialogOpen(true) },
              ]}
              initContent={newTemplate ? newTemplate : template?.html!}
            />
          </Box>
        </>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress sx={{ m: "0 auto" }} />
          <Box sx={{ mt: 2 }}>{date ? "Scheduling Email..." : "Sending Email..."}</Box>
        </Box>
      )}
    </div>
  );
};

interface ChildProps {
  registrant: IRegistrant;
  emailTemplate: IEmailTemplate | null;
  setShowStep: Dispatch<SetStateAction<string>>;
  stepSchedules: IStepSchedule[];
  setStepSchedules: Dispatch<SetStateAction<IStepSchedule[] | []>>;
  handleCompleteStep: any;
  dateCompleted: Date | null;
  handleProcess: any;
  step: string;
}

interface ITemplate {
  _id: string;
  name: string;
  subject: string;
  html: string;
}

const GETEMAILTEMPLATE = gql`
  query emailTemplateById($_id: MongoID!) {
    emailTemplateById(_id: $_id) {
      _id
      name
      subject
      html
      attachments {
        _id
        name
        getUrl
      }
    }
  }
`;

const SENDEMAIL = gql`
  query sendEmail(
    $id: MongoID
    $to: String!
    $subject: String!
    $senderName: String!
    $senderEmail: String!
    $cc: String
    $html: String!
    $project: MongoID!
    $attachmentsSaved: [AttachmentsSavedInput]!
    $attachmentsUnsaved: [Upload]!
  ) {
    sendPersonalizedStepEmail(
      id: $id
      to: $to
      subject: $subject
      senderName: $senderName
      senderEmail: $senderEmail
      cc: $cc
      html: $html
      project: $project
      attachmentsSaved: $attachmentsSaved
      attachmentsUnsaved: $attachmentsUnsaved
    ) {
      name
    }
  }
`;

const SCHEDULEEMAIL = gql`
  mutation stepScheduleCreateOne($record: CreateOneStepScheduleInput!) {
    stepScheduleCreateOne(record: $record) {
      record {
        _id
        mailDate
        step {
          _id
        }
      }
    }
  }
`;

const GETUSERS = gql`
  query userMany($filter: FilterFindManyUserInput!) {
    userMany(filter: $filter) {
      _id
      email
      fullName
    }
  }
`;

export default ShowEmailView;
