import { Dispatch, SetStateAction } from "react";
import {
  Typography,
  Box,
  Button,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  List,
  ListItem,
  IconButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { IProcessTemplate, IProcess } from "../../../types/process";
import { FlexBetween } from "../../../commonStyles";
import { IUser } from "../../../types/user";
import { camelToNormal } from "../../../utils/function";

const ProcessView = (props: ChildProps) => {
  const {
    selectedProcessTemplate,
    setSelectedProcessTemplate,
    processes,
    view,
    userCompleted,
    handleUser,
    selectedUsers,
    users,
    handleProcess,
    setSelectedUsers,
  } = props;
  
  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>{view === "newProcess" ? "Move To A New Process" : "Edit Process"}</strong>
      </Typography>
      {view === "newProcess" ? (
        <div>
          <p>Please select a process below to move the registrant to</p>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Process</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={selectedProcessTemplate ? selectedProcessTemplate._id : ''}
                label="Process"
                onChange={(e: SelectChangeEvent<string>) => {
                  let process = processes.find((process: IProcessTemplate) => process._id === e.target.value);
                  if (process) {
                    setSelectedProcessTemplate(process);
                  }
                }}
              >
                {Object.entries(
                  processes.reduce(
                    (processGroup: any, process: IProcessTemplate) => {
                      if (process) {
                        const { type } = process;
                        processGroup[type] = processGroup[type] ?? [];
                        processGroup[type].push(process);
                      }
                      return processGroup;
                    },
                    { shortTerm: [], event: [], handover: [], sales: [], longTerm: [] }
                  )
                ).map((processGroup: any, i: number) => [
                  <ListSubheader key={i}>
                    <strong>{camelToNormal(processGroup[0])}</strong>
                  </ListSubheader>,
                  processGroup[1].map((process: IProcessTemplate, index: number) => {
                    return (
                      <MenuItem sx={{ pl: 4 }} key={index} value={process._id}>
                        {process.name}
                      </MenuItem>
                    );
                  }),
                ])}
              </Select>
            </FormControl>
          </Box>
        </div>
      ) : null}
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel>Add Users To Process</InputLabel>
        <Select
          name={"user"}
          label={"Add Users To Process"}
          value={userCompleted?.fullName}
          onChange={(e) => handleUser(e.target.value, "add")}
        >
          {users.map((user: IUser, index: number) => (
            <MenuItem key={index} value={user.fullName}>
              {user.fullName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedUsers && selectedUsers.length ? (
        <div>
          <List sx={{ listStyleType: "ordered", pl: 2 }}>
            {selectedUsers.map((user: IUser, index: number) => {
              return (
                <ListItem
                  secondaryAction={
                    <IconButton
                      onClick={() => setSelectedUsers(selectedUsers.filter((deletedUser: IUser) => deletedUser._id !== user._id))}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon sx={{ color: "error.main" }} />
                    </IconButton>
                  }
                  sx={{ display: "list-item" }}
                  key={index}
                >
                  <ListItemText>{user.fullName}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </div>
      ) : null}
      <FlexBetween sx={{ mt: 2 }}>
        <Button type="submit" color="success" variant="contained">
          {view === "newProcess" ? "Go to New Process" : "Update Process"}
        </Button>
        <Button onClick={() => handleProcess("", null, false)} color="info" variant="contained">
          Cancel
        </Button>
      </FlexBetween>
    </div>
  );
};

interface ChildProps {
  view: string;
  selectedUsers: IUser[];
  setSelectedUsers: Dispatch<SetStateAction<IUser[]>>;
  processes: IProcessTemplate[];
  selectedProcessTemplate: IProcessTemplate | null;
  setSelectedProcessTemplate: Dispatch<SetStateAction<IProcessTemplate | null>>;
  user: IUser | null;
  userCompleted: IUser | null;
  handleUser: (fullName: string, type: string) => void;
  users: IUser[];
  handleProcess: (view: string, index: null, modalOpen: boolean) => void;
}

export default ProcessView;
