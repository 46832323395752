import { useState, Dispatch, SetStateAction } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { Typography, Box, Button, Autocomplete, TextField, CircularProgress } from "@mui/material";

import { FlexBetween } from "../../commonStyles";
import { useAppDispatch } from "../../app/hooks";
import { handleModal } from "../../features/modal/modalSlice";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { selectProjectTags } from "../../features/projectSetting/projectSettingSlice";
import { setTags } from "../../features/projectSetting/projectSettingSlice";

const TagRegistrants = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const projectTags = useSelector(selectProjectTags);
  const { selectedRegistrants, project, setModalType } = props;
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [tagRegistrants, { loading }] = useMutation(TAGREGISTRANTS, {
    onCompleted: (data) => {
      setModalType("");
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar("Selected Registrants Tagged"));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error assigning processes"));
      console.log(err, "err");
    },
  });

  const submitTags = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    tagRegistrants({
      variables: {
        projectId: project,
        registrantIds: selectedRegistrants,
        tags: selectedTags,
      },
    });
  };

  return (
    <form onSubmit={submitTags}>
      <Typography variant="h2" component="div" gutterBottom>
        <strong>Tag Registrants</strong>
      </Typography>
      <div>
        <p>
          Please select a tag for the selected <strong>{selectedRegistrants.length}</strong> registrants.
        </p>
        <p>Please note that this will only add unique tags that the registrant currently does not have.</p>
        <Box sx={{ mt: 2 }}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={projectTags}
            freeSolo={false}
            getOptionLabel={(option: any) => option}
            isOptionEqualToValue={(option, value) => option === value}
            value={selectedTags}
            onChange={(e, value) => setSelectedTags(value)}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Tags" />}
          />
        </Box>
        <FlexBetween sx={{ mt: 2 }}>
          <Button type="submit" color="success" variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : "Update all Registrant Tags"}
          </Button>
          <Button
            onClick={() => {
              setModalType("");
              storeDispatch(handleModal(false));
            }}
            color="info"
            variant="contained"
          >
            Cancel
          </Button>
        </FlexBetween>
      </div>
    </form>
  );
};

const TAGREGISTRANTS = gql`
  mutation tagRegistrants($projectId: MongoID!, $registrantIds: [MongoID!], $tags: [String!]) {
    tagRegistrants(projectId: $projectId, registrantIds: $registrantIds, tags: $tags)
  }
`;

interface ChildProps {
  selectedRegistrants: string[];
  project?: string | null;
  setModalType: Dispatch<SetStateAction<string>>;
}

export default TagRegistrants;
